import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedNumber, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./Payment.css";

class PaymentCheque extends Component {
  render() {
    return (
      <div>
        <Row className="payment">
          <Col xs={12} sm={12} md={12} lg={12}>
            <p>
              <b>
                <FormattedMessage id="pagamento.alterarTipoPagamento.cheque.valor" />{" "}
                <span className="colorPink">
                  <FormattedNumber
                    value={this.props.total}
                    style="currency"
                    currency={this.props.currency.toUpperCase()}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </span>
              </b>
            </p>
            <p>
              <b><FormattedMessage id="pagamento.alterarTipoPagamento.cheque.nomeAPassar" /></b>
              <br />
              <FormattedMessage id="pagamento.alterarTipoPagamento.cheque.nomeAPassar.nome" />
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currencies: state.currencies
  };
};

export default withRouter(connect(mapStateToProps)(PaymentCheque));
