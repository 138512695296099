import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import HeaderDesktop from "../../../components/Desktop/Header/Header";
import ProductGridDesktop from "../../../components/Desktop/ProductGrid/ProductGrid";
import Footer from "../../../components/Footer/Footer";

export default class ProductGrid extends Component {

  render() {
    return (
      <div className="App">
        <HeaderDesktop />
        <br />
        <Container
          style={{
            position: "relative",
            top: "60px",
            width: "90vw"
          }}
        >
          <Row>
            <Col xs="12" sm="12" md="12" lg="12">
              <ProductGridDesktop
                category={this.props.category}
                id={this.props.id}
              />
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}
