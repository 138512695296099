import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import HeaderDesktop from "../../../../components/Desktop/Header/Header";
import Cart from "../../../../components/Shop/Cart/Cart";

export default class CartView extends Component {

  render() {
    return (
      <div className="App">
        <HeaderDesktop />
        <Container className="mainContentContainer">
          <Row style={{ textAlign: "center" }} >
            <Col xs="12" sm="12" md="12" lg="12">
              <Cart />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}