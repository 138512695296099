import { Button } from "@material-ui/core";
import $ from "jquery";
import { instanceOf } from "prop-types";
import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Cookies, withCookies } from "react-cookie";
import { isMobile } from "react-device-detect";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import renderHTML from "react-render-html";
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { ToastContainer } from "react-toastr";
import { getProductDetails, incrementarPackCart, incrementarProdutoCart, loading } from "../../../../src/actions/miniCartActions";
import { getEmail } from "../../../../src/actions/userActions";
import { funnelStep } from "../../../Analytics/DataLayer";
import Loading from "../Loading/Loading";
import DialogComentarios from "./DialogComentarios";
import "./ProductDetail.css";
import ProductNotFound from "./ProductNotFound";
import ProductOutOfStock from "./ProductOutOfStock";
import RecomendarProduto from "./RecomendarProduto";
import moment from "moment-timezone";
import { isDate } from "../../../helpers/UsefulFunctions";

let container;
let myParam = null;
class ProductDetail extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) {
    super(props);
    funnelStep(4);
    this.state = {
      email: null
    };

    this.afterNotificationCreate = this.afterNotificationCreate.bind(this);
    this.afterNotificationRemove = this.afterNotificationRemove.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(loading(true));
    $("#observacoes *").removeAttr("style");
    const urlParams = new URLSearchParams(window.location.search);
    myParam = urlParams.get("tipo");
    this.props.dispatch(getProductDetails(this.props.id, myParam));
    this.getUserEmail();
  }

  // Método para ir buscar o email do utilizador para aparecer s
  getUserEmail() {
    getEmail().then(result => {
      this.setState({
        email: result.Email
      })
    }).catch(err => {
      this.setState({
        email: null
      })
    })
  }

  handleClickOpen = comentarios => {
    this.setState({
      open: true,
      Comentarios: comentarios
    });
  };

  handleClickOpenRecomendar = comentarios => {
    var id = this.props.product.ID_Produto
      ? this.props.product.ID_Produto
      : this.props.product.ID_Pack;
    this.setState({
      openRec: true,
      id: id
    });
  };

  handleClose = value => {
    this.setState({ selectedValue: value, open: false });
  };

  handleCloseRecomendar = value => {
    this.setState({ selectedValue: value, openRec: false });
  };

  addToCart = id => async e => {
    e.stopPropagation();
    const { cookies } = this.props;
    this.props.dispatch(incrementarProdutoCart(id, cookies.get("userID")));
    container.clear();
    container.success(`Item adicionado com sucesso`);
  };

  addToCartPack = id => async e => {
    e.stopPropagation();
    const { cookies } = this.props;
    this.props.dispatch(incrementarPackCart(id, cookies.get("userID")));
    container.clear();
    container.success(`Item adicionado com sucesso`);
  };

  goBack = () => async e => {
    e.stopPropagation();
    window.history.back();
  };

  //Método a ser chamado depois de criar a notificação
  afterNotificationCreate(result) {
    try {

      //Se conseguirmos criar com sucesso
      if (result.success === true) {
        container.clear();
        container.success(result.message);
        this.props.dispatch(getProductDetails(this.props.id, myParam));
        //Se não conseguirmos criar 
      } else {
        container.clear();
        container.error(result.message);
      }
    } catch (err) {
      container.clear();
      container.error("Erro ao criar notificação. Por favor contacte a equipa de Suporte");
    }
  }

  //Método a ser chamado depois de remover a notificação
  afterNotificationRemove(result) {
    try {

      //Se conseguirmos remover com sucesso
      if (result.success === true) {
        container.clear();
        container.success(result.message);
        this.props.dispatch(getProductDetails(this.props.id, myParam));
        //Se não conseguirmos remover 
      } else {
        container.clear();
        container.error(result.message);
      }
    } catch (err) {
      container.clear();
      container.error("Erro ao remover a notificação. Por favor contacte a equipa de Suporte");
    }
  }

  render() {
    const { product, currencies, user, loading } = this.props;
    const userRefer = user.userID ? user.userID : "1";

    var isUserLoggedIn = (typeof user.userID !== 'undefined' && user.userID != null);

    var dataInicio = null;
    var dataFim = null;

    //Se DataInicio for do tipo date
    if (isDate(product.DataInicio)) {
      var initDate = new Date(product.DataInicio).toLocaleDateString();
      var initHour = new Date(product.DataInicio).toLocaleTimeString({}, { hour: '2-digit', minute: '2-digit' });
      dataInicio = initDate + " " + initHour;
    } else {
      //Se não for uma data colocamos o valor que chegou da API
      dataInicio = product.DataInicio;
    }

    //Se a DataFim for do tipo date
    if (isDate(product.DataFim)) {
      var endDate = new Date(product.DataFim).toLocaleDateString();
      var endHour = new Date(product.DataFim).toLocaleTimeString({}, { hour: '2-digit', minute: '2-digit' });
      dataFim = endDate + " " + endHour;
    } else {
      //Se não for uma data colocamos o valor que chegou da API
      dataFim = product.DataFim;
    }

    //Vamos tentar adivinhar a Zona do Utilizador
    var zone = moment.tz.guess();
    var abrev = moment().tz(zone).zoneAbbr();


    /* Se estiver em processo de loading aparece o loading senão apresenta a página do produto */
    if (loading) {
      return <Loading />;
    } else {
      return (product && product.Descricao) ? (
        <Container>
          <DialogComentarios
            selectedValue={this.state.selectedValue}
            open={this.state.open}
            onClose={this.handleClose}
            comentarios={this.state.Comentarios}
            updateMoradasFunction={this.updateMoradas}
          />
          <RecomendarProduto
            selectedValue={this.state.selectedValue}
            open={this.state.openRec}
            onClose={this.handleCloseRecomendar}
            id={this.state.id}
          />
          <Row>
            <Col xs="12" sm="12" md="12" lg="12">
              <div style={{ textAlign: "left" }}>
                <ToastContainer
                  ref={ref => (container = ref)}
                  className="toast-bottom-right"
                />

                {/* Back button */}
                <Row>
                  <Col xs="1" sm="1" md="1" lg="1">
                    <div onClick={this.goBack()}>
                      <svg
                        width="25px"
                        height="25px"
                        viewBox="0 0 512 512"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          id="Symbols"
                          stroke="none"
                          stroke-width="1"
                          fill="#034275"
                          fill-rule="evenodd"
                        >
                          <g>
                            <g>
                              <path
                                d="M491.318,235.318H20.682C9.26,235.318,0,244.578,0,256c0,11.423,9.26,20.682,20.682,20.682h470.636
    c11.423,0,20.682-9.259,20.682-20.682C512,244.578,502.741,235.318,491.318,235.318z"
                              />
                            </g>
                          </g>
                          <g>
                            <g>
                              <path
                                d="M49.932,256L211.795,94.136c8.077-8.077,8.077-21.172,0-29.249c-8.077-8.076-21.172-8.076-29.249,0L6.058,241.375
    c-8.077,8.077-8.077,21.172,0,29.249l176.488,176.488c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.625-6.058
    c8.077-8.077,8.077-21.172,0-29.249L49.932,256z"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Row>

                      {/* Imagem do artigo */}
                      <Col xs="12" sm="12" md="3" lg="3" style={{ textAlign: "center" }} >
                        <img alt="Imagem do Artigo" src={"https://doremon.ihavethepower.net/assets/uploads/" + product.urlficheiro} style={{ width: "100%" }} />
                      </Col>

                      {/* Descricao do artigo */}
                      <Col xs="12" sm="12" md="8" lg="9">

                        {/* Descricao do artigo */}
                        <Row>
                          <br /><h4>{product.Descricao}</h4>
                        </Row>

                        {/* Data Inicio se aplicar */}
                        {typeof product.DataInicio !== 'undefined' && product.DataInicio != null && typeof product.DataFim !== 'undefined' && product.DataFim != null && (
                          <Row>
                            <Col className="productDetailItemDatesCol">
                              <div className="productDetailItemDates">
                                <div className="productDetailItemStartDate">
                                  {product.DataInicio ? `Início: ${dataInicio}` : null}
                                </div>
                                <div className="productDetailDatesSeparator">|</div>
                                <div className="productDetailItemEndDate">
                                  {product.DataFim ? `Fim: ${dataFim} (${abrev})` : null}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        )}
                        <br />

                        <Row>
                          <Col xs="9" sm="7" md="6" lg="2" style={{ right: "15px" }}>

                            {/* Login Sociais */}
                            <Row>
                              {/* Facebook */}
                              <Col xs="1" sm="1" md="1" lg="1" style={{ marginRight: "5px" }}>
                                {product.ID_Produto ? (
                                  <FacebookShareButton
                                    url={
                                      "https://www.ihavethepower.net/Loja/DetalheProduto/" +
                                      product.ID_Produto +
                                      "/" +
                                      product.titleUrl +
                                      "?refer=" +
                                      userRefer +
                                      "&tipo=produto"
                                    }
                                    quote={this.props.Descricao}
                                    className="Demo__some-network__share-button"
                                  >
                                    <FacebookIcon size={30} round={true} />
                                  </FacebookShareButton>
                                ) : product.ID_Pack ? (
                                  <FacebookShareButton
                                    url={
                                      "https://www.ihavethepower.net/Loja/DetalheProduto/" +
                                      product.ID_Pack +
                                      "/" +
                                      product.titleUrl +
                                      "?refer=" +
                                      userRefer +
                                      "&tipo=pack"
                                    }
                                    quote={this.props.Descricao}
                                    className="Demo__some-network__share-button"
                                  >
                                    <FacebookIcon size={30} round={true} />
                                  </FacebookShareButton>
                                ) : null}
                              </Col>

                              {/* LinkedIn */}
                              <Col xs="1" sm="1" md="1" lg="1" style={{ marginRight: "5px" }}>
                                {product.ID_Produto ? (
                                  <LinkedinShareButton
                                    url={
                                      "https://www.ihavethepower.net/Loja/DetalheProduto/" +
                                      product.ID_Produto +
                                      "/" +
                                      product.titleUrl +
                                      "?refer=" +
                                      userRefer +
                                      "&tipo=produto"
                                    }
                                    quote={this.props.Descricao}
                                    className="Demo__some-network__share-button"
                                  >
                                    <LinkedinIcon size={30} round={true} />
                                  </LinkedinShareButton>
                                ) : product.ID_Pack ? (
                                  <LinkedinShareButton
                                    url={
                                      "https://www.ihavethepower.net/Loja/DetalheProduto/" +
                                      product.ID_Pack +
                                      "/" +
                                      product.titleUrl +
                                      "?refer=" +
                                      userRefer +
                                      "&tipo=pack"
                                    }
                                    quote={this.props.Descricao}
                                    className="Demo__some-network__share-button"
                                  >
                                    <LinkedinIcon size={30} round={true} />
                                  </LinkedinShareButton>
                                ) : null}
                              </Col>

                              {/* Twitter */}
                              <Col xs="1" sm="1" md="1" lg="1" style={{ marginRight: "5px" }}>
                                {product.ID_Produto ? (
                                  <TwitterShareButton
                                    url={
                                      "https://www.ihavethepower.net/Loja/DetalheProduto/" +
                                      product.ID_Produto +
                                      "/" +
                                      product.titleUrl +
                                      "?refer=" +
                                      userRefer +
                                      "&tipo=produto"
                                    }
                                    quote={this.props.Descricao}
                                    className="Demo__some-network__share-button"
                                  >
                                    <TwitterIcon size={30} round={true} />
                                  </TwitterShareButton>
                                ) : product.ID_Pack ? (
                                  <TwitterShareButton
                                    url={
                                      "https://www.ihavethepower.net/Loja/DetalheProduto/" +
                                      product.ID_Pack +
                                      "/" +
                                      product.titleUrl +
                                      "?refer=" +
                                      userRefer +
                                      "&tipo=pack"
                                    }
                                    quote={this.props.Descricao}
                                    className="Demo__some-network__share-button"
                                  >
                                    <TwitterIcon size={30} round={true} />
                                  </TwitterShareButton>
                                ) : null}
                              </Col>

                              {/*WhatsApp */}
                              <Col xs="1" sm="1" md="1" lg="1" style={{ marginRight: "5px" }}>
                                {product.ID_Produto ? (
                                  <WhatsappShareButton
                                    url={
                                      "https://www.ihavethepower.net/Loja/DetalheProduto/" +
                                      product.ID_Produto +
                                      "/" +
                                      product.titleUrl +
                                      "?refer=" +
                                      userRefer +
                                      "&tipo=produto"
                                    }
                                    quote={this.props.Descricao}
                                    className="Demo__some-network__share-button"
                                  >
                                    <WhatsappIcon size={30} round={true} />
                                  </WhatsappShareButton>
                                ) : product.ID_Pack ? (
                                  <WhatsappShareButton
                                    url={
                                      "https://www.ihavethepower.net/Loja/DetalheProduto/" +
                                      product.ID_Pack +
                                      "/" +
                                      product.titleUrl +
                                      "?refer=" +
                                      userRefer +
                                      "&tipo=pack"
                                    }
                                    quote={this.props.Descricao}
                                    className="Demo__some-network__share-button"
                                  >
                                    <WhatsappIcon size={30} round={true} />
                                  </WhatsappShareButton>
                                ) : null}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <br />
                        <Row>

                          {/* NetPoints */}
                          <Col xs="12" sm="12" md="7" lg="8" style={{ right: "15px" }}>
                            <span
                              style={{
                                fontSize: isMobile ? "18px" : "16px",
                                fontFamily: "Montserrat",
                                fontWeight: "400"
                              }}
                            >
                              {this.props.user.userType !== "CS" && (
                                <span>
                                  Ganhe <span style={{ fontWeight: "600" }}> <FormattedNumber value={product.creditos}
                                    style="currency" currency={currencies.selected}
                                    minimumFractionDigits={2} maximumFractionDigits={2} /></span> ao comprar este artigo
                                </span>
                              )}

                              {this.props.user.userType === "CS" && (
                                <span>
                                  Acumule <span style={{ fontWeight: "600" }}>{product.npts}</span> NetPoints e fique mais próximo do seu sonho
                                </span>
                              )}
                            </span>
                            <br />
                            <br />
                            {/*SPAN Adicionado para o cliente poder recomendar o
                            produto*/}
                            <span
                              class="fake-link"
                              id="fake-link-1"
                              onClick={() => this.handleClickOpenRecomendar()}
                              style={{
                                fontSize: isMobile ? "18px" : "16px",
                                fontFamily: "Roboto Helvetica, Arial, serif",
                                color: "rgb(0, 123, 255)",
                                textDecoration: "underline"
                              }}
                            >
                              Recomendar produto a amigo
                            </span>
                            <br />
                            <br />
                            <span
                              class="fake-link"
                              id="fake-link-1"
                              onClick={() =>
                                this.handleClickOpen(product.Comentarios)
                              }
                              style={{
                                fontSize: isMobile ? "18px" : "16px",
                                fontFamily: "Roboto Helvetica, Arial, serif",
                                color: "rgb(0, 123, 255)",
                                textDecoration: "underline"
                              }}
                            >
                              {product.Comentarios
                                ? product.Comentarios.length > 0
                                  ? product.Comentarios.length
                                  : 0
                                : 0}{" "}
                              <FormattedMessage id="productdetails.comentarios" />
                            </span>
                          </Col>

                          {/* Preços Produtos */}
                          <Col xs="12" sm="12" md="5" lg="4" style={{ textAlign: "right", right: "-15px" }}>

                            {/* Design de comprar produto para Desktop */}
                            <Row style={{ display: isMobile ? "none" : "block" }}>
                              <Col xs="12" sm="12" md="12" lg="12"
                                style={{
                                  textAlign: "center",
                                  background: "white",
                                  position: isMobile ? "fixed" : null,
                                  zIndex: "50",
                                  right: "0px",
                                  bottom: "0px",
                                  minHeight: "95px",
                                  maxWidth: "300px",
                                  margin: "auto"
                                }}
                              >

                                {/* Se for consultor */}
                                {this.props.user.userType === "CS" ? (
                                  <span>

                                    {/* Se não tiver promoção */}
                                    {product.precoconsultorsempromocao === null ? (
                                      <div>
                                        <span>

                                          {/* Preço CF com IVA */}
                                          <b style={{ fontSize: isMobile ? "15px" : "15px" }}>
                                            {"("}
                                            <FormattedNumber value={product.precoclientefinalcomiva}
                                              style="currency" currency={currencies.selected}
                                              minimumFractionDigits={2} maximumFractionDigits={2} />
                                            {")"}
                                          </b>

                                          {/* Preço CS com IVA */}
                                          <b style={{ color: "red", fontSize: isMobile ? "26px" : "26px" }}>
                                            <FormattedNumber value={product.precoconsultorcomiva} style="currency"
                                              currency={currencies.selected} minimumFractionDigits={2} maximumFractionDigits={2} />
                                          </b>
                                        </span>
                                      </div>
                                    ) : (
                                      <div> {/* Se estiver com promoção*/}
                                        <span>

                                          {/* Preço Consultor sem promoção*/}
                                          <b style={{ textDecoration: "line-through", fontSize: isMobile ? "15px" : "15px" }}>
                                            <FormattedNumber
                                              value={product.precoconsultorsempromocao}
                                              style="currency"
                                              currency={currencies.selected}
                                              minimumFractionDigits={2}
                                              maximumFractionDigits={2}
                                            />
                                          </b>{" "}

                                          {/* Taxa de desconto */}
                                          <b
                                            style={{
                                              color: "green",
                                              fontSize: isMobile ? "21px" : "21px",
                                              transform: "rotate(44deg)",
                                              position: "absolute",
                                              top: "-17px",
                                              marginLeft: "-17px"
                                            }}
                                          >
                                            {" -"}
                                            {product
                                              ? product.taxaDesconto
                                                ? product.taxaDesconto
                                                : null
                                              : null}
                                            {"%"}
                                          </b>
                                          <br />

                                          {/* Preço Consultor Promoção com Iva */}
                                          <b
                                            style={{
                                              color: "red",
                                              fontSize: isMobile ? "26px" : "26px"
                                            }}>
                                            <FormattedNumber
                                              value={product.precoconsultorcomiva}
                                              style="currency"
                                              currency={currencies.selected}
                                              minimumFractionDigits={2}
                                              maximumFractionDigits={2}
                                            />
                                          </b>
                                        </span>
                                      </div>
                                    )}
                                  </span>
                                ) : (
                                  <div>
                                    <span>
                                      {/* Se cliente final não tem promoção */}
                                      {product.precoclientesempromocao === null ? (
                                        <span>

                                          {/* Preço cliente final com via sem promoção */}
                                          <b style={{ fontSize: isMobile ? "20px" : "20px" }}>
                                            <FormattedNumber value={product.precoclientefinalcomiva}
                                              style="currency"
                                              currency={currencies.selected}
                                              minimumFractionDigits={2}
                                              maximumFractionDigits={2}
                                            />
                                          </b>

                                        </span>
                                      ) : (
                                        <div> {/* Se cliente final não tem promoção */}
                                          <span>

                                            {/* Preco cliente final sem promoção*/}
                                            <b style={{ textDecoration: "line-through", fontSize: isMobile ? "15px" : "15px" }}>
                                              <FormattedNumber value={product.precoclientesempromocao}
                                                style="currency" currency={currencies.selected} minimumFractionDigits={2} maximumFractionDigits={2}
                                              />
                                            </b>

                                            {/* Taxa de Desconto */}
                                            <b
                                              style={{
                                                color: "green",
                                                fontSize: isMobile ? "21px" : "21px",
                                                transform: "rotate(44deg)",
                                                position: "absolute",
                                                top: "-17px",
                                                marginLeft: "-17px"
                                              }}>
                                              {" -"}
                                              {product
                                                ? product.taxaDesconto
                                                  ? product.taxaDesconto
                                                  : null
                                                : null}
                                              {"%"}
                                            </b>
                                            <br />

                                            {/* Preço cliente final com promoção*/}
                                            <b
                                              style={{
                                                color: "red",
                                                fontSize: isMobile ? "26px" : "26px",
                                              }}>
                                              <FormattedNumber value={product.precoclientefinalcomiva}
                                                style="currency"
                                                currency={currencies.selected}
                                                minimumFractionDigits={2}
                                                maximumFractionDigits={2}
                                              />
                                            </b>
                                          </span>
                                        </div>
                                      )}
                                    </span>
                                  </div>
                                )}


                                {product.Esgotado === true ? (

                                  <ProductOutOfStock defaultEmail={this.state.email}
                                    notification={product.Notificacao} notificationHash={product.NotificacaoHash}
                                    productId={product.ID_Produto ? product.ID_Produto : null}
                                    packId={product.ID_Pack ? product.ID_Pack : null}
                                    afterNotificationCreate={this.afterNotificationCreate}
                                    afterNotificationRemoved={this.afterNotificationRemove}
                                    isUserLoggedIn={isUserLoggedIn}
                                  />

                                ) : product.ID_Pack ? (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ minWidth: "100%", fontFamily: "Montserrat" }}
                                    onClick={this.addToCartPack(
                                      product.ID_Pack
                                    )}
                                  >
                                    <FormattedMessage id="productdetails.comprar" />
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ minWidth: "100%", fontFamily: "Montserrat" }}
                                    onClick={this.addToCart(this.props.id)}
                                  >
                                    <FormattedMessage id="productdetails.comprar" />
                                  </Button>
                                )}

                                <br />
                              </Col>
                            </Row>

                            {/* Design de comprar produto para Mobile */}
                            <Row style={{ display: isMobile ? "block" : "none" }}>
                              <Col xs="12" sm="12" md="12" lg="12"
                                style={{
                                  textAlign: "center",
                                  background: "white",
                                  position: isMobile ? "fixed" : null,
                                  zIndex: "50",
                                  right: "0px",
                                  bottom: "0px",
                                  minHeight: "95px"
                                }}>
                                <br />

                                {/* Se consultor */}
                                {this.props.user.userType === "CS" ? (
                                  <span>
                                    <br />
                                    {/* Se não tiver promoção */}
                                    {product.precoconsultorsempromocao === null ? (
                                      <div>
                                        <span>

                                          {/*Preço CF com IVA */}
                                          <b style={{ marginRight: "15px", fontSize: isMobile ? "15px" : "15px" }}>
                                            {"("}
                                            <FormattedNumber value={product.precoclientefinalcomiva} style="currency"
                                              currency={currencies.selected} minimumFractionDigits={2} maximumFractionDigits={2} />
                                            {")"}
                                          </b>

                                          {/*Preço CS com IVA */}
                                          <b style={{ color: "red", fontSize: isMobile ? "20px" : "20px" }}>
                                            <FormattedNumber value={product.precoconsultorcomiva} style="currency"
                                              currency={currencies.selected} minimumFractionDigits={2} maximumFractionDigits={2} />
                                          </b>
                                        </span>
                                      </div>
                                    ) : (
                                      <div> {/* Preço Consultor Com Promoção */}
                                        <span>

                                          {/* Preço Consultor Sem Promoção */}
                                          <b
                                            style={{
                                              marginRight: "15px",
                                              textDecoration: "line-through",
                                              fontSize: isMobile ? "15px" : "15px"
                                            }}>
                                            <FormattedNumber value={product.precoconsultorsempromocao}
                                              style="currency" currency={currencies.selected}
                                              minimumFractionDigits={2} maximumFractionDigits={2} />
                                          </b>

                                          {/* Taxa de Desconto*/}
                                          <b style={{ color: "green", fontSize: isMobile ? "15px" : "15px" }}>
                                            {" -"}
                                            {product
                                              ? product.taxaDesconto
                                                ? product.taxaDesconto
                                                : null
                                              : null}
                                            {"%"}
                                          </b>
                                          <br />

                                          {/* Preço consultor com iva */}
                                          <b style={{ color: "red", fontSize: isMobile ? "20px" : "20px" }}>
                                            <FormattedNumber value={product.precoconsultorcomiva}
                                              style="currency"
                                              currency={currencies.selected}
                                              minimumFractionDigits={2}
                                              maximumFractionDigits={2}
                                            />
                                          </b>
                                        </span>
                                      </div>
                                    )}
                                  </span>
                                ) : (
                                  <div>
                                    <span>
                                      {/* CF sem promoção */}
                                      {product.precoclientesempromocao === null ? (
                                        <span>

                                          {/* CF com iva */}
                                          <b style={{ fontSize: isMobile ? "20px" : "20px" }}>
                                            <FormattedNumber
                                              value={
                                                product.precoclientefinalcomiva
                                              }
                                              style="currency"
                                              currency={currencies.selected}
                                              minimumFractionDigits={2}
                                              maximumFractionDigits={2}
                                            />
                                          </b>
                                        </span>
                                      ) : (
                                        <div>
                                          <span>

                                            {/* Preço cliente sem promoção*/}
                                            <b
                                              style={{
                                                marginRight: "15px",
                                                textDecoration: "line-through",
                                                fontSize: isMobile ? "15px" : "15px"
                                              }}>
                                              <FormattedNumber value={product.precoclientesempromocao}
                                                style="currency"
                                                currency={currencies.selected}
                                                minimumFractionDigits={2}
                                                maximumFractionDigits={2}
                                              />
                                            </b>

                                            {/* % de desconto */}
                                            <b style={{ color: "green", fontSize: isMobile ? "15px" : "15px" }}>
                                              {" -"}
                                              {product
                                                ? product.taxaDesconto
                                                  ? product.taxaDesconto
                                                  : null
                                                : null}
                                              {"%"}
                                            </b>
                                            <br />

                                            {/* Preço cliente final com IVA */}
                                            <b style={{ color: "red", fontSize: isMobile ? "20px" : "20px" }}>
                                              <FormattedNumber value={product.precoclientefinalcomiva}
                                                style="currency" currency={currencies.selected}
                                                minimumFractionDigits={2} maximumFractionDigits={2} />
                                            </b>
                                          </span>
                                        </div>
                                      )}
                                    </span>
                                  </div>
                                )}

                                {product.Esgotado === true ? (
                                  <ProductOutOfStock defaultEmail={this.state.email}
                                    notification={product.Notificacao} notificationHash={product.NotificacaoHash}
                                    productId={product.ID_Produto ? product.ID_Produto : null}
                                    packId={product.ID_Pack ? product.ID_Pack : null}
                                    afterNotificationCreate={this.afterNotificationCreate}
                                    afterNotificationRemoved={this.afterNotificationRemove}
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                ) : product.ID_Pack ? (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ minWidth: "100%", height: "100%", marginBottom: "10px", fontFamily: "MontSerrat" }}
                                    onClick={this.addToCartPack(this.props.id)}>
                                    <FormattedMessage id="productgrid.butaoComprar" />
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ minWidth: "100%", height: "100%", marginBottom: "10px", fontFamily: "MontSerrat" }}
                                    onClick={this.addToCart(product.ID_Produto)}>
                                    <FormattedMessage id="productgrid.butaoComprar" />
                                  </Button>
                                )}
                                <br />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <br />
                <br />
                <Row style={{ maxWidth: "10% !important" }}>
                  <Col style={{ maxWidth: "10% !important" }}>
                    <span style={{ maxWidth: "10% !important" }}>
                      {product.Observacoes
                        ? renderHTML(product.Observacoes)
                        : null}
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </span>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row >
        </Container >
      ) : (
        <ProductNotFound />
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    product: state.product,
    currencies: state.currencies,
    user: state.user,
    loading: state.loading
  };
};

export default withCookies(connect(mapStateToProps)(ProductDetail));
