import { Button } from "@material-ui/core";
import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import { createNoStockNotification, removePersonalStockNotification } from "../../../actions/miniCartActions";
import Loading from "../Loading/Loading";

export default class ProductOutOfStock extends Component {

    constructor(props) {
        super(props)

        this.state = {
            email: this.props.defaultEmail,
            loading: false
        }

        this.createNotification = this.createNotification.bind(this);
        this.removeStockNotification = this.removeStockNotification.bind(this);
        this.setLoading = this.setLoading.bind(this);
    }

    setLoading(value) {
        this.setState(
            prevState => ({
                ...prevState,
                loading: value
            })
        )
    }

    //Método para criar a notificação de stock
    createNotification() {

        this.setLoading(true);
        createNoStockNotification(this.props.productId, this.props.packId, this.state.email).then(result => {
            this.setLoading(false);
            if (result.success === true) {
                result.obj = {
                    ...result.obj,
                    productId: this.props.productId,
                    packId: this.props.packId
                }
            }
            this.props.afterNotificationCreate(result);
        }).catch(err => {
            this.setLoading(false);
            this.props.afterNotificationCreate({
                success: false,
                message: "Ocorreu um erro ao criar a sua notificação. Por favor tente mais tarde."
            });
        })
    }

    //Método para remover notificação de stock
    removeStockNotification(hash) {

        this.setLoading(true);
        removePersonalStockNotification(hash).then(result => {
            this.setLoading(false);
            result.obj = {
                productId: this.props.productId,
                packId: this.props.packId
            }
            this.props.afterNotificationRemoved(result);
        }).catch(err => {
            this.setLoading(false);
            this.props.afterNotificationRemoved({
                success: false,
                message: "Ocorreu um erro ao remover a notificação. Por favor tente mais tarde."
            });
        })
    }

    render() {

        const { notification, notificationHash } = this.props;
        return (
            <React.Fragment>

                <Button
                    variant="contained"
                    color="secondary"
                    disabled="true"
                    style={{ minWidth: "100%", fontFamily: "Montserrat" }}>
                    Esgotado
                </Button>

                {this.props.isUserLoggedIn ? (
                    <div>
                        { notification && notification === true ? (
                            <div style={{ marginTop: "20px" }}>

                                {this.state.loading === true ? (
                                    <Loading />
                                ) : (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            style={{ minWidth: "100%", fontFamily: "Montserrat" }}
                                            onClick={() => this.removeStockNotification(notificationHash)}>
                                            <FormattedMessage id="productdetails.cancelarNotificarStock" />
                                        </Button>
                                    )}
                            </div>
                        ) : (
                                <div style={{ marginTop: "20px" }}>

                                    {this.state.loading === true ? (
                                        <Loading />
                                    ) : (
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ minWidth: "100%", fontFamily: "Montserrat" }}
                                                    onClick={this.createNotification}>
                                                    <FormattedMessage id="productdetails.notificarStock" />
                                                </Button>
                                            </div>
                                        )}
                                </div>
                            )}
                    </div>
                ) : null}

            </React.Fragment>
        )
    }
}
