import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastr";
import { Col, Row } from "reactstrap";
import { recoverPassword } from "../../../actions/miniCartActions";
import Routes from "../../../helpers/Routes";
import "./RecoverPassword.css";

let container;

class RecoverPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    };
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleFormSubmit = async e => {

    //Se a pessoa tiver verificado o captcha
    if (this.props.isVerified) {
      e.stopPropagation();

      this.props.dispatch(recoverPassword(this.state.email))
        .then(data => {

          if (data.success === true) {
            container.clear();
            container.success(`${data.message}`);
          } else {
            container.clear();
            container.error(`${data.message}`);
          }
        });

    } else {
      container.clear();
      container.info(<FormattedMessage id="recuperarPassword.dadosNãoPreenchidos" />);
    }
  };


  handleChangeEmail(event) {
    this.setState({
      email: event.target.value
    });
  }

  handleKeyPress(target) {
    if (target.charCode == 13) {
      if (this.props.isVerified) {
        this.props.dispatch(recoverPassword(this.state.email))
          .then(data => {
            if (data.success === true) {
              container.clear();
              container.success(`${data.message}`);
            } else {
              container.clear();
              container.error(`${data.message}`);
            }
          });
      } else {
        container.clear();
        container.info(<FormattedMessage id="recuperarPassword.dadosNãoPreenchidos" />);
      }
    }
  }

  render() {
    return (
      <div>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />

        {/* Título */}
        <Row className="recoverPasswordTitleParent">
          <Col>
            <div className="recoverPasswordTitle">
              <FormattedMessage id="recuperarPassword.titulo" />
            </div>
          </Col>
        </Row>

        {/* Inserir Email */}
        <Row className="recoverPasswordEmailParent">
          <Col xl={5} lg={7} md={8}>
            <TextField id="email" label={<FormattedMessage id="recuperarPassword.emailPlaceholder" />}
              className="email textFieldFontStyle" value={this.state.email} onChange={this.handleChangeEmail}
              onKeyPress={this.handleKeyPress} type="email" fullWidth={true}
            />
          </Col>
        </Row>

        {/* Botão de Recuperar Password*/}
        <Row className="recoverPasswordButtonParent">
          <Col xl={5} lg={7} md={8}>
            <Button className="recoverPasswordButton" fullWidth={true} disabled={this.props.loading}
              onClick={this.handleFormSubmit}>

              {this.props.loading === true ? (
                <CircularProgress size={24} className="buttonProgress" />
              ) : (
                <FormattedMessage id="recuperarPassword.recuperarPasswordButton" />
              )}
            </Button>
          </Col>
        </Row>

        {/* Registar */}
        <Row className="recoverPasswordRegisterHereParent">
          <Col xl={5} lg={7} md={8}>
            <NavLink class="recoverPasswordRegisterHere" to={Routes.account.registo}>
              <FormattedMessage id="recuperarPassword.registoButton" />
            </NavLink>
          </Col>
        </Row>

        {/* Login */}
        <Row className="recoverPasswordLoginParent">
          <Col xl={5} lg={7} md={8}>
            <NavLink class="recoverPasswordLogin" to={Routes.account.login}>
              <FormattedMessage id="recuperarPassword.loginButton" />
            </NavLink>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    loading: state.loading
  };
};

export default connect(mapStateToProps)(RecoverPassword);
