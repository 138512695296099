// window.dataLayer = window.dataLayer || [];

const _ = require("lodash");

const datalayer = {};

// Função para transformar produtos em produtos de analytics
var productsToproductsAnalytics = function (products) {
  var productAux = {},
    productsAnalytics = [];

  products.map(function (x, index) {
    productAux = {
      // Id de produto
      id: !_.isUndefined(x.ID_Produto) ? "P" + x.ID_Produto : "K" + x.ID_Pack,
      // Nome de produto
      name: x.Descricao,
      // Categoria do produto
      category: !_.isUndefined(x.ID_TipoProduto)
        ? "P-" + x.ID_TipoProduto
        : "K-" + x.ID_TipoPack,
      price: x.price,
      quantity: x.quantity
    };
    // Adicionar ao array de produtos
    productsAnalytics.push(productAux);
  });
  return productsAnalytics;
};

// Adicionar ao carrinho
export function addToCart(product) {
  datalayer.addToCart = {
    event: "eec.addToCart",
    ecommerce: {
      add: {
        actionField: {
          list: product.ID_TipoProduto
        },
        products: [
          {
            id: !_.isUndefined(product.ID_Produto)
              ? "P" + product.ID_Produto
              : "K" + product.ID_Pack,
            name: product.Descricao,
            category: !_.isUndefined(product.ID_TipoProduto)
              ? "P-" + product.ID_TipoProduto
              : "K-" + product.ID_TipoPack,
            quantity: product.quantity
          }
        ]
      }
    }
  };

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(datalayer.addToCart);
}

// Remover do carrinho
export function removeToCart(product) {
  datalayer.removeToCart = {
    event: "eec.remove",
    ecommerce: {
      remove: {
        actionField: {
          list: product.ID_TipoProduto
        },
        products: [
          {
            id:
              product.ID_Produto !== null
                ? "P" + product.ID_Produto
                : "K" + product.ID_Pack,
            name: product.Descricao,
            category:
              product.ID_TipoProduto !== null
                ? "P-" + product.ID_TipoProduto
                : "K-" + product.ID_TipoPack,
            quantity: product.quantity
          }
        ]
      }
    }
  };
}

// Adicionar morada de envio
export function funnelStep(step) {
  datalayer.checkout = {
    event: "eec.checkout",
    ecommerce: {
      checkout: {
        actionField: {
          step: step
        }
        /*products: [
          {
            id:
              product.ID_Produto !== null
                ? "P" + product.ID_Produto
                : "K" + product.ID_Pack,
            name: product.Descricao,
            category:
              product.ID_TipoProduto !== null
                ? "P-" + product.ID_TipoProduto
                : "K-" + product.ID_TipoPack,
            quantity: product.quantity
          }
        ]*/
      }
    }
  };


  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(datalayer.checkout);
}

// Adicionar morada de envio
export function checkout_MoradaEnvio(product) {
  datalayer.checkout_MoradaEnvio = {
    event: "eec.checkout",
    ecommerce: {
      checkout: {
        actionField: {
          step: 2
        },
        products: [
          {
            id:
              product.ID_Produto !== null
                ? "P" + product.ID_Produto
                : "K" + product.ID_Pack,
            name: product.Descricao,
            category:
              product.ID_TipoProduto !== null
                ? "P-" + product.ID_TipoProduto
                : "K-" + product.ID_TipoPack,
            quantity: product.quantity
          }
        ]
      }
    }
  };
}

// Adicionar morada de faturação
export function checkout_MoradaFaturacao(product) {
  datalayer.checkout_MoradaFaturacao = {
    event: "eec.checkout",
    ecommerce: {
      checkout: {
        actionField: {
          step: 3
        },
        products: [
          {
            id:
              product.ID_Produto !== null
                ? "P" + product.ID_Produto
                : "K" + product.ID_Pack,
            name: product.Descricao,
            category:
              product.ID_TipoProduto !== null
                ? "P-" + product.ID_TipoProduto
                : "K-" + product.ID_TipoPack,
            quantity: product.quantity
          }
        ]
      }
    }
  };
}

// Adicionar meio de envio
export function checkout_MeioEnvio(product) {
  datalayer.checkout_MeioEnvio = {
    event: "eec.checkout",
    ecommerce: {
      checkout: {
        actionField: {
          step: 4
        },
        products: [
          {
            id:
              product.ID_Produto !== null
                ? "P" + product.ID_Produto
                : "K" + product.ID_Pack,
            name: product.Descricao,
            category:
              product.ID_TipoProduto !== null
                ? "P-" + product.ID_TipoProduto
                : "K-" + product.ID_TipoPack,
            quantity: product.quantity
          }
        ]
      }
    }
  };
}

// Adicionar metodo de pagamento
export function checkout_MetodoPagamento(product) {
  datalayer.checkout_MetodoPagamento = {
    event: "eec.checkout",
    ecommerce: {
      checkout: {
        actionField: {
          step: 5
        },
        products: [
          {
            id:
              product.ID_Produto !== null
                ? "P" + product.ID_Produto
                : "K" + product.ID_Pack,
            name: product.Descricao,
            category:
              product.ID_TipoProduto !== null
                ? "P-" + product.ID_TipoProduto
                : "K-" + product.ID_TipoPack,
            quantity: product.quantity
          }
        ]
      }
    }
  };
}

// Adicionar metodo de pagamento
export function checkout_Encomendar(order, products) {
  // Obter os produtos formatados para analytics
  var productsAnalytics = productsToproductsAnalytics(products);
  // Criação de dataLayer de compra
  datalayer.checkout_Encomendar = {
    event: "eec.purchase",
    ecommerce: {
      currencyCode: "EUR",
      purchase: {
        actionField: {
          id: order.IDEncomenda,
          affiliation: "Loja Online",
          revenue: order.precoFinal,
          tax: order.precoTaxa,
          shipping: order.precoEnvio,
          coupon: order.CodigoPromocional
        },
        products: productsAnalytics
      }
    }
  };

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(datalayer.checkout_Encomendar);
}
