import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import React, { Component } from "react";
import { ReCaptcha } from "react-recaptcha-google";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastr";
import { verifyEmail } from "../../actions/miniCartActions";
import HeaderDesktop from "../../components/Desktop/Header/Header";
import Routes from "../../helpers/Routes";
import "./VerifyEmail.css";

let container;

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userVerify: {
        link: window.location.href,
        password: "",
        confirmPassword: ""
      },
      isVerified: false
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  componentDidMount() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  }

  handleInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        userVerify: {
          ...prevState.userVerify,
          [name]: value
        }
      })
    );
  }

  handleFormSubmit(e) {
    e.preventDefault();
    if (this.state.isVerified) {
      this.props.dispatch(verifyEmail(this.state.userVerify))
        .then(data => {

          if (data.success === true) {
            container.clear();
            container.success(`${data.message}`);
            setTimeout(function () {
              window.location.href = Routes.account.login;
            }, 2900);
          } else {
            container.clear();
            container.warning(`${data.message}`);
          }
        })
        .catch(err => {
          container.clear();
          container.error("Erro ao confirmar a conta, por favor tente dentro de segundos");
        });
    } else {
      container.clear();
      container.info(`Tem a certeza que preencheu todos os dados?`);
    }
  }

  verifyCallback(response) {
    if (response) {
      this.setState({
        isVerified: true
      });
    } else {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  }

  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  }

  render() {
    return (
      <div className="App">
        <HeaderDesktop />
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        {/* PAGINA DESKTOP */}
        <div class="md-verifyemail">
          <div>
            <div className="login-pageTitle">
              <div class="iniciarsess343o">Definir Palavra-Passe</div>
            </div>
            <div class="textfield">
              <TextField type={"password"} title={"Password"} name={"password"} className="email" value={this.state.userVerify.password}
                placeholder={"Insira uma Palavra-Passe"} onChange={this.handleInput} />
            </div>

            <div class="textfield1">
              <TextField type={"password"} title={"Confirmar Password"} name={"confirmPassword"} className="password"
                value={this.state.userVerify.confirmPassword}
                placeholder={"Confirme a Palavra-Passe"} onChange={this.handleInput}
              />
            </div>

            <div class="button">
              {this.props.loading === true ? (
                <Button disabled={true}
                  style={{
                    backgroundColor: "#CA006A",
                    color: "white",
                    width: "25vw"
                  }}
                  onClick={this.handleFormSubmit}
                >
                  <CircularProgress size={24} className="buttonProgress" />
                </Button>
              ) : (
                  <Button
                    style={{
                      backgroundColor: "#CA006A",
                      color: "white",
                      width: "25vw"
                    }}
                    onClick={this.handleFormSubmit}
                  >
                    Confirmar
                  </Button>
                )}
            </div>
          </div>
        </div>

        {/* PAGINA TABLET */}
        <div class="sm-verifyemail">
          <div>
            <div className="login-pageTitle">
              <div class="iniciarsess343o">Definir Password</div>
            </div>
            <div class="textfield">
              <TextField
                type={"password"}
                title={"Password"}
                name={"password"}
                className="email"
                value={this.state.userVerify.password}
                placeholder={"Insira uma password"}
                onChange={this.handleInput}
              />
            </div>
            <div class="textfield1">
              <TextField
                type={"password"}
                title={"Confirmar Password"}
                name={"confirmPassword"}
                className="password"
                value={this.state.userVerify.confirmPassword}
                placeholder={"Confirme a password"}
                onChange={this.handleInput}
              />
            </div>
            <div class="button">
              {this.props.loading === true ? (
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#CA006A",
                    color: "white",
                    width: "70vw"
                  }}
                  onClick={this.handleFormSubmit}
                >
                  <CircularProgress size={24} className="buttonProgress" />
                </Button>
              ) : (
                  <Button
                    style={{
                      backgroundColor: "#CA006A",
                      color: "white",
                      width: "70vw"
                    }}
                    onClick={this.handleFormSubmit}
                  >
                    Confirmar
                  </Button>
                )}
            </div>
          </div>
        </div>
        {/* PAGINA MOBILE */}
        <div class="xs-verifyemail">
          <div>
            <div className="login-pageTitle">
              <div class="iniciarsess343o">Definir Password</div>
            </div>
            <div class="textfield">
              <TextField
                type={"password"}
                title={"Password"}
                name={"password"}
                className="email"
                value={this.state.userVerify.password}
                placeholder={"Insira uma password"}
                onChange={this.handleInput}
              />
            </div>
            <div class="textfield1">
              <TextField
                type={"password"}
                title={"Confirmar Password"}
                name={"confirmPassword"}
                className="password"
                value={this.state.userVerify.confirmPassword}
                placeholder={"Confirme a password"}
                onChange={this.handleInput}
              />
            </div>
            <div class="button">
              {this.props.loading === true ? (
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#CA006A",
                    color: "white",
                    width: "90vw"
                  }}
                  onClick={this.handleFormSubmit}
                >
                  <CircularProgress size={24} className="buttonProgress" />
                </Button>
              ) : (
                  <Button
                    style={{
                      backgroundColor: "#CA006A",
                      color: "white",
                      width: "90vw"
                    }}
                    onClick={this.handleFormSubmit}
                  >
                    Confirmar
                  </Button>
                )}
            </div>
          </div>
        </div>
        <ReCaptcha
          ref={el => {
            this.captchaDemo = el;
          }}
          size="invisible"
          render="explicit"
          sitekey="6LcjwKcUAAAAAB0AMiECn6QGrAvSi3VVhGjcGHNH"
          onloadCallback={this.onLoadRecaptcha}
          verifyCallback={this.verifyCallback}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart,
    loading: state.loading
  };
};

export default withRouter(connect(mapStateToProps)(VerifyEmail));
