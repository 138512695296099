import Fab from "@material-ui/core/Fab";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import TextField from "@material-ui/core/TextField";
import $ from "jquery";
import FlatButton from "material-ui/FlatButton";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import postalCodes from 'postal-codes-js';
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastr";
import { addNewShippingAddress, addShippingAddressCheckout, getAddress, getPaises, getShippingAddresses, modifyShippingAddress, removeShippingAddress } from "../../../actions/miniCartActions";
import Button from "../../Forms/Button";

const styles = {
  customWidth: {
    "font-weight": "bold",
    color: "black"
  }
};

let container;

class DadosEnvio extends Component {

  constructor(props) {
    super(props);
    this.state = {
      novaMorada: {
        nome: "",
        morada: "",
        localidade: "",
        codigoPostal: "",
        principal: "S",
        id_pais: "",
        ID_Tipo_Morada: ""
      },
      alterarMorada: {
        id_cliente_morada: "",
        nome: "",
        morada: "",
        localidade: "",
        codigoPostal: "",
        principal: "S",
        id_pais: "",
        ID_Tipo_Morada: ""
      },
      moradas: {
        moradaEnvio: ""
      },
      adicionar: true,
      remover: false,
      alterar: false,
      paisesOptions: []
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleInputAlterarMorada = this.handleInputAlterarMorada.bind(this);

    this.handleMoradas = this.handleMoradas.bind(this);
    this.handleRemoveShippingAddress = this.handleRemoveShippingAddress.bind(
      this
    );
    this.handleModifyShippingAddress = this.handleModifyShippingAddress.bind(
      this
    );
    this.handleFormModifyShippingAddressSubmit = this.handleFormModifyShippingAddressSubmit.bind(
      this
    );

    this.handleAddShippingAddress = this.handleAddShippingAddress.bind(this);

    this.handleCleanModifyShippingAddress = this.handleCleanModifyShippingAddress.bind(
      this
    );

    this.handleCleanAddShippingAddress = this.handleCleanAddShippingAddress.bind(
      this
    );
  }
  componentDidMount() {
    this.props.dispatch(getShippingAddresses())
      .then(() => {

        var numberOfAddresses = this.props.deliveryAddresses.length;
        if (numberOfAddresses > 0) {
          this.props.dispatch(addShippingAddressCheckout(this.props.deliveryAddresses[0].ID_Cliente_Morada))
            .then(data => {
              this.setState(
                prevState => ({
                  ...prevState,
                  alterar: true,
                  remover: true,
                  moradas: {
                    ...prevState.moradas,
                    moradaEnvio: this.props.deliveryAddresses[0].ID_Cliente_Morada
                  }
                })
              );
            });
        }
      });

    this.props.dispatch(getPaises()).then(data => {
      const paises = data.data.paises.map(item => {
        item.id = item.alpha3;
        item.title = item.name;
        return item;
      });

      this.setState({ paisesOptions: paises });
    });
  }

  handleAddShippingAddress = e => {
    this.setState({
      alterar: false,
      remover: false,
      adicionar: false
    });

    this.showOrHideAddShippingAddress(1);
    this.showOrHideShippingAddresses(0);

    this.blockOrNotOtherControls(1);

  };

  blockOrNotOtherControls(value) {

    switch (value) {
      case 1:
        /*$(".breadCrumbRow").addClass("disabledBox");
        $(".dadosFaturacaoCol").addClass("disabledBox");
        $(".formasEnvioCol").addClass("disabledBox");
        $(".tiposPagamentoCol").addClass("disabledBox");
        $(".codigosPromocionaisCol").addClass("disabledBox");
        $(".valesCol").addClass("disabledBox");
        $(".creditosCol").addClass("disabledBox");
        $(".observacaoCol").addClass("disabledBox");
        $(".totalCol").addClass("disabledBox");*/
        break;

      default:
        /*$(".breadCrumbRow").removeClass("disabledBox");
        $(".dadosFaturacaoCol").removeClass("disabledBox");
        $(".formasEnvioCol").removeClass("disabledBox");
        $(".tiposPagamentoCol").removeClass("disabledBox");
        $(".codigosPromocionaisCol").removeClass("disabledBox");
        $(".valesCol").removeClass("disabledBox");
        $(".creditosCol").removeClass("disabledBox");
        $(".observacaoCol").removeClass("disabledBox");
        $(".totalCol").removeClass("disabledBox");*/
        break;
    }
  }

  handleCleanAddShippingAddress = e => {

    this.showOrHideAddShippingAddress(0);
    this.showOrHideShippingAddresses(1);


    this.setState({ adicionar: true });
    if (this.state.moradas.moradaEnvio) {
      this.setState({ alterar: true });
      this.setState({ remover: true });
    }

    this.blockOrNotOtherControls(0);
    this.setState({
      novaMorada: {
        nome: "",
        morada: "",
        localidade: "",
        codigoPostal: "",
        principal: "S",
        id_pais: ""
      }
    });
  };

  handleCleanModifyShippingAddress = e => {
    var item = $(".shippingModifyAddress");
    var list = $(".shippingAddresses");
    item.removeClass("is-open");

    item.one("transitionend", function (e) {
      item.removeClass("is-visible");
      list.removeClass("is-not-visible");
    });

    this.blockOrNotOtherControls(0);

    this.setState({
      adicionar: true,
      remover: true,
      alterar: true,
      alterarMorada: {
        nome: "",
        morada: "",
        localidade: "",
        codigoPostal: "",
        principal: "S",
        id_pais: ""
      }
    });
  };

  handleAddShippingChangePais = e => {
    let value = e.target.value;
    this.setState(
      prevState => ({
        novaMorada: {
          ...prevState.novaMorada,
          id_pais: value
        }
      }),
      () => { }
    );
  };

  handleModifyShippingChangePais = e => {
    let value = e.target.value;
    this.setState(
      prevState => ({
        alterarMorada: {
          ...prevState.alterarMorada,
          id_pais: value
        }
      })
    );
  };

  handleChangeShippingAddress = (event, index, value) => {
    if (!this.state.remover) {
      this.setState({ remover: true });
    }
    if (!this.state.alterar) {
      this.setState({ alterar: true });
    }
    this.setState(
      prevState => ({
        moradas: {
          ...prevState.moradas,
          moradaEnvio: value
        }
      })
    );
    this.props.dispatch(addShippingAddressCheckout(value)).then(data => { });
  };

  handleInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        novaMorada: {
          ...prevState.novaMorada,
          [name]: value
        }
      })
    );
  }

  handleInputAlterarMorada(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        alterarMorada: {
          ...prevState.alterarMorada,
          [name]: value
        }
      })
    );
  }

  handleMoradas(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        moradas: {
          ...prevState.moradas,
          [name]: value
        }
      })
    );
  }

  showOrHideAddShippingAddress(value) {

    var item = document.querySelector(".shippingAddAddress");
    switch (value) {
      case 1: //Show

        if (!item.classList.contains("is-visible")) {
          item.classList.add("is-visible");
        }

        if (!item.classList.contains("is-open")) {

          setTimeout(function () {
            item.classList.add("is-open");
          }, 50);
        }

        break;
      default: //Hide

        if (item.classList.contains("is-open")) {
          item.classList.remove("is-open");
        }

        if (item.classList.contains("is-visible")) {
          setTimeout(function () {
            item.classList.remove("is-visible");
          }, 250);
        }


        break;
    }
  }

  showOrHideShippingAddresses(value) {
    var list = document.querySelector(".shippingAddresses");
    switch (value) {
      case 1: //Show

        if (list.classList.contains("is-not-visible")) {
          list.classList.remove("is-not-visible");
          list.classList.add("is-visible");
        }
        break;
      default: //Hide
        if (!list.classList.contains("is-not-visible")) {
          list.classList.add("is-not-visible");
        }
        break;
    }
  }

  handleFormSubmit(e) {
    e.stopPropagation();
    let regex = new RegExp(/^\d{4}([-]\d{3})$/g);
    if (this.state.novaMorada.nome) {
      if (this.state.novaMorada.id_pais) {
        if (this.state.novaMorada.morada) {
          if (this.state.novaMorada.localidade) {
            if (this.state.novaMorada.codigoPostal) {
              if (this.state.novaMorada.id_pais !== 'PRT' || (this.state.novaMorada.id_pais === 'PRT' && regex.test(this.state.novaMorada.codigoPostal) === true)) {
                if (postalCodes.validate(this.state.novaMorada.id_pais, this.state.novaMorada.codigoPostal) === true) {
                  this.props
                    .dispatch(addNewShippingAddress(this.state.novaMorada))
                    .then(data => {
                      this.setState({ adicionar: true });

                      this.showOrHideShippingAddresses(1);
                      this.showOrHideAddShippingAddress(0);

                      this.blockOrNotOtherControls(0);

                      if (data.data.messageError) {
                        container.clear();
                        container.error(`${data.data.messageError}`);
                      } else if (data.data.message) {
                        container.clear();
                        container.success(`${data.data.message}`);
                      }

                      this.setState({
                        novaMorada: {
                          nome: "",
                          morada: "",
                          localidade: "",
                          codigoPostal: "",
                          principal: "S",
                          id_pais: ""
                        }
                      });

                      this.props.dispatch(getShippingAddresses()).then((data) => {

                        var numberOfAddresses = this.props.deliveryAddresses.length;
                        if (numberOfAddresses >= 0 && this.props.deliveryAddresses[numberOfAddresses - 1]) {
                          this.props.dispatch(addShippingAddressCheckout(this.props.deliveryAddresses[numberOfAddresses - 1].ID_Cliente_Morada))
                            .then(data => {
                              this.setState(
                                prevState => ({
                                  ...prevState,
                                  remover: true,
                                  alterar: true,
                                  moradas: {
                                    ...prevState.moradas,
                                    moradaEnvio: this.props.deliveryAddresses[numberOfAddresses - 1].ID_Cliente_Morada
                                  }
                                })
                              );
                            });
                        }
                      });
                    });
                } else {
                  container.clear();
                  container.error(<FormattedMessage id="billingAddress.erro.codPostalInvalido" />);
                }
              } else {
                container.clear();
                container.error(<FormattedMessage id="billingAddress.erro.codPostalInvalidoPT" />);
              }
            } else {
              container.clear();
              container.error(<FormattedMessage id="billingAddress.erro.codPostalVazio" />);
            }
          } else {
            container.clear();
            container.error(<FormattedMessage id="billingAddress.erro.localidadeVazia" />);
          }
        } else {
          container.clear();
          container.error(<FormattedMessage id="billingAddress.erro.ruaVazia" />);
        }
      } else {
        container.clear();
        container.error(<FormattedMessage id="billingAddress.erro.paisVazio" />);
      }
    } else {
      container.clear();
      container.error(<FormattedMessage id="billingAddress.erro.nomeMoradaVazio" />);
    }
  }

  handleRemoveShippingAddress(e) {
    e.stopPropagation();
    this.props
      .dispatch(removeShippingAddress(this.state.moradas.moradaEnvio))
      .then(data => {

        if (data.data.messageError) {
          container.clear();
          container.error(`${data.data.messageError}`);
        } else if (data.data.message) {

          var moradas = data.moradas;
          var moradaEnvio = "";

          //Se tivermos alguma morada, selecionamos essa morada
          if (moradas.length > 0) {
            moradaEnvio = moradas[moradas.length - 1].ID_Cliente_Morada;
          }

          this.setState(prevState => ({
            ...prevState,
            moradas: {
              ...prevState.moradas,
              moradaEnvio: moradaEnvio
            }
          }));

          this.props.dispatch(addShippingAddressCheckout(moradaEnvio)).then(data => { });
          container.clear();
          container.success(`${data.data.message}`);
        }
      });
  }

  handleModifyShippingAddress(e) {
    e.stopPropagation();

    this.setState({ alterar: false });
    this.setState({ remover: false });
    this.setState({ adicionar: false });

    this.props
      .dispatch(getAddress(this.state.moradas.moradaEnvio))
      .then(data => {

        this.setState(
          prevState => ({
            alterarMorada: {
              ...prevState.alterarMorada,
              id_cliente_morada: data.data.address[0].ID_Cliente_Morada,
              nome: data.data.address[0].Nome,
              morada: data.data.address[0].Morada,
              localidade: data.data.address[0].Localidade,
              codigoPostal: data.data.address[0].CodigoPostal,
              principal: data.data.address[0].Principal,
              id_pais: data.data.address[0].Pais,
              ID_Tipo_Morada: data.data.address[0].ID_Tipo_Morada
            }
          })
        );
      });

    var item = $(".shippingModifyAddress");
    var list = $(".shippingAddresses");
    item.addClass("is-visible");
    list.addClass("is-not-visible");

    this.blockOrNotOtherControls(1);

    setTimeout(function () {
      item.addClass("is-open");
    }, 20);
  }

  handleFormModifyShippingAddressSubmit(e) {
    e.stopPropagation();
    let regex = new RegExp(/^\d{4}([-]\d{3})$/g);
    if (this.state.alterarMorada.nome) {
      if (this.state.alterarMorada.id_pais) {
        if (this.state.alterarMorada.morada) {
          if (this.state.alterarMorada.localidade) {
            if (this.state.alterarMorada.codigoPostal) {
              if (this.state.alterarMorada.id_pais !== 'PRT' || (this.state.alterarMorada.id_pais === 'PRT' && regex.test(this.state.alterarMorada.codigoPostal) === true)) {
                if (postalCodes.validate(this.state.alterarMorada.id_pais, this.state.alterarMorada.codigoPostal) === true) {
                  this.props
                    .dispatch(modifyShippingAddress(this.state.alterarMorada))
                    .then(data => {
                      this.setState({ adicionar: true });
                      this.setState({ alterar: true });
                      this.setState({ remover: true });
                      var item = $(".shippingModifyAddress");
                      var list = $(".shippingAddresses");
                      item.removeClass("is-open");

                      item.one("transitionend", function (e) {
                        item.removeClass("is-visible");
                        list.removeClass("is-not-visible");
                      });

                      this.blockOrNotOtherControls(0);

                      if (data.data.messageError) {
                        container.clear();
                        container.error(`${data.data.messageError}`);
                      } else if (data.data.message) {
                        container.clear();
                        container.success(`${data.data.message}`);
                      }
                    });
                } else {
                  container.clear();
                  container.error(<FormattedMessage id="billingAddress.erro.codPostalInvalido" />);
                }
              } else {
                container.clear();
                container.error(<FormattedMessage id="billingAddress.erro.codPostalInvalidoPT" />);
              }
            } else {
              container.clear();
              container.error(<FormattedMessage id="billingAddress.erro.codPostalVazio" />);
            }
          } else {
            container.clear();
            container.error(<FormattedMessage id="billingAddress.erro.localidadeVazia" />);
          }
        } else {
          container.clear();
          container.error(<FormattedMessage id="billingAddress.erro.ruaVazia" />);
        }
      } else {
        container.clear();
        container.error(<FormattedMessage id="billingAddress.erro.paisVazio" />);
      }
    } else {
      container.clear();
      container.error(<FormattedMessage id="billingAddress.erro.nomeMoradaVazio" />);
    }
  }

  render() {
    const { deliveryAddresses, checkoutData } = this.props;

    return checkoutData.ProdutosOnline !== 0 && (checkoutData.TipoTransporteExterno == null || checkoutData.TipoTransporteExterno === 'S') ? (
      <div
        className={
          !checkoutData.confirmOrderClicked && checkoutData.shippingAddress
            ? "checkoutBox doneSelected"
            : checkoutData.confirmOrderClicked
              ? checkoutData.shippingAddress
                ? "checkoutBox doneSelected"
                : "checkoutBox discountSelected"
              : "checkoutBox"
        }
      >
        <Row>
          <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />
          <Col xs={12} sm={12} md={12} lg={12}>

            {/* Informação na parte de cima, número, titulo e icon*/}
            <Row style={{ textAlign: "center" }}>
              <Col xs={3} sm={3} md={3} lg={2} style={{ textAlign: "left" }}>
                <h4>
                  <svg
                    width="35px"
                    height="34px"
                    viewBox="0 0 612 612"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path
                        d="M256,0C114.609,0,0,114.609,0,256c0,141.391,114.609,256,256,256c141.391,0,256-114.609,256-256
		C512,114.609,397.391,0,256,0z M256,472c-119.297,0-216-96.703-216-216S136.703,40,256,40s216,96.703,216,216S375.297,472,256,472z
		"
                      />
                      <g>
                        <path
                          d="M249.703,201.25H188v-25h19.312c6.859,0,13.422-1.219,19.5-3.594c6.172-2.375,11.438-5.641,15.797-9.797
			c4.358-4.203,7.922-9.25,10.547-15.234c2.734-5.906,4.047-12.5,4.047-19.625H284v256h-34.297V201.25z"
                        />
                      </g>
                    </g>
                  </svg>
                </h4>
              </Col>
              <Col xs={6} sm={6} md={6} lg={8} style={{ textAlign: "center" }}>
                <h4>
                  <FormattedMessage id="billingAddress.dadosEnvioTitle" />
                </h4>
              </Col>
              <Col xs={3} sm={3} md={3} lg={2} style={{ textAlign: "right" }}>
                <h4>
                  <svg
                    width="35px"
                    height="34px"
                    viewBox="0 0 612 612"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m204.5 458.605469v51.855469l-12.539062-10.128907c-1.9375-1.566406-48.035157-38.992187-94.78125-92.660156-64.484376-74.035156-97.179688-140.492187-97.179688-197.519531v-5.652344c0-112.761719 91.738281-204.5 204.5-204.5s204.5 91.738281 204.5 204.5v5.652344c0 4.789062-.253906 9.652344-.714844 14.574218l-39.992187-36.484374c-8.191407-83.15625-78.519531-148.339844-163.792969-148.339844-90.757812 0-164.597656 73.839844-164.597656 164.597656v5.652344c0 96.367187 124.164062 213.027344 164.597656 248.453125zm122.699219-28.660157h59.851562v-59.851562h-59.851562zm-122.699219-310.238281c46.753906 0 84.792969 38.039063 84.792969 84.792969s-38.039063 84.792969-84.792969 84.792969-84.792969-38.039063-84.792969-84.792969 38.039063-84.792969 84.792969-84.792969zm0 39.902344c-24.753906 0-44.890625 20.136719-44.890625 44.890625 0 24.75 20.136719 44.890625 44.890625 44.890625 24.75 0 44.890625-20.140625 44.890625-44.890625 0-24.753906-20.140625-44.890625-44.890625-44.890625zm280.609375 243.222656-11.21875-10.234375v64.058594c0 29.828125-24.269531 54.09375-54.097656 54.09375h-126.332031c-29.828126 0-54.097657-24.265625-54.097657-54.09375v-64.058594l-11.21875 10.234375-26.890625-29.476562 155.371094-141.746094 155.375 141.746094zm-51.121094-46.636719-77.363281-70.574218-77.359375 70.574218v100.457032c0 7.828125 6.367187 14.195312 14.195313 14.195312h126.332031c7.828125 0 14.195312-6.367187 14.195312-14.195312zm0 0" />
                  </svg>
                </h4>
              </Col>
            </Row>

            {/* Só mostrar os botões de add, alterar e remover se existir no minimo 1 morada*/}
            {deliveryAddresses.length !== 0 ? (
              <Row style={{ textAlign: "center" }}>
                <Col xs={4} sm={4} md={4} lg={4} className="addButtonShippingAddress">
                  <FlatButton
                    label={
                      <svg
                        height="25px"
                        viewBox="0 0 512 512"
                        width="25px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0" />
                        <path d="m256 365.714844c-10.097656 0-18.285156-8.1875-18.285156-18.285156v-182.859376c0-10.097656 8.1875-18.285156 18.285156-18.285156s18.285156 8.1875 18.285156 18.285156v182.859376c0 10.097656-8.1875 18.285156-18.285156 18.285156zm0 0" />
                        <path d="m347.429688 274.285156h-182.859376c-10.097656 0-18.285156-8.1875-18.285156-18.285156s8.1875-18.285156 18.285156-18.285156h182.859376c10.097656 0 18.285156 8.1875 18.285156 18.285156s-8.1875 18.285156-18.285156 18.285156zm0 0" />
                      </svg>
                    }
                    className="addShippingAddress"
                    secondary={true}
                    disabled={!this.state.adicionar}
                    onClick={this.handleAddShippingAddress}
                  />
                </Col>
                <Col xs={4} sm={4} md={4} lg={4} className="changeButtonShippingAddress" >
                  <FlatButton
                    label={
                      <svg
                        height="25px"
                        viewBox="0 0 512 512.00115"
                        width="25px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m485.191406 26.8125c-35.738281-35.742188-93.929687-35.757812-129.683594 0l-324.195312 324.199219c-.082031.085937-.105469.203125-.191406.289062-1.476563 1.53125-2.632813 3.316407-3.503906 5.28125-.226563.511719-.355469 1.015625-.535157 1.539063-.257812.757812-.625 1.460937-.78125 2.257812l-25.941406 129.679688c-1.207031 6.019531.679687 12.234375 5.015625 16.570312 3.476562 3.472656 8.148438 5.371094 12.964844 5.371094 1.199218 0 2.402344-.117188 3.601562-.355469l129.683594-25.941406c.792969-.160156 1.5-.527344 2.253906-.78125.523438-.179687 1.03125-.308594 1.539063-.535156 1.964843-.875 3.75-2.03125 5.28125-3.503907.085937-.085937.203125-.109374.289062-.195312l324.199219-324.191406c35.75-35.757813 35.75-93.9375.003906-129.683594zm-337.167968 414.972656-77.808594-77.808594 272.320312-272.328124 77.816406 77.816406zm-106.304688 28.5 13.824219-69.105468 55.28125 55.277343zm417.539062-339.726562-12.972656 12.972656-77.816406-77.816406 12.972656-12.972656c21.453125-21.472657 56.359375-21.4375 77.816406 0 21.445313 21.457031 21.445313 56.363281 0 77.816406zm0 0" />
                        <path d="m148.023438 382.320312c-4.691407 0-9.382813-1.789062-12.964844-5.375-7.164063-7.164062-7.164063-18.769531 0-25.929687l194.515625-194.519531c7.164062-7.164063 18.773437-7.164063 25.933593 0 7.160157 7.164062 7.164063 18.769531 0 25.933594l-194.519531 194.515624c-3.582031 3.582032-8.273437 5.375-12.964843 5.375zm0 0" />
                      </svg>
                    }
                    className=""
                    secondary={true}
                    disabled={!this.state.alterar}
                    onClick={this.handleModifyShippingAddress}
                  />
                </Col>
                <Col xs={4} sm={4} md={4} lg={4} className="removeButtonShippingAddress">
                  <FlatButton
                    label={
                      <svg
                        height="25px"
                        viewBox="-26 0 512 512"
                        width="25px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m441.378906 70.621094h-105.929687v-34.691406c0-19.808594-16.121094-35.929688-35.929688-35.929688h-140.003906c-19.808594 0-35.929687 16.121094-35.929687 35.929688v34.691406h-105.929688c-9.75 0-17.65625 7.894531-17.65625 17.65625 0 9.757812 7.90625 17.652344 17.65625 17.652344h18.40625l14.652344 336.96875c1.679687 38.757812 33.386718 69.101562 72.175781 69.101562h213.257813c38.785156 0 70.488281-30.34375 72.171874-69.101562l14.652344-336.96875h18.40625c9.75 0 17.65625-7.894532 17.65625-17.652344 0-9.761719-7.90625-17.65625-17.65625-17.65625zm-281.863281-35.3125h140.003906l.617188 35.3125h-141.03125zm213.527344 406.070312c-.863281 19.792969-17.066407 35.3125-36.898438 35.3125h-213.257812c-19.828125 0-36.035157-15.519531-36.898438-35.3125l-14.585937-335.449218h316.226562zm0 0" />
                        <path d="m229.515625 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
                        <path d="m300.136719 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
                        <path d="m158.898438 406.070312c-9.753907 0-17.65625-7.898437-17.65625-17.65625v-211.863281c0-9.757812 7.902343-17.652343 17.65625-17.652343 9.75 0 17.652343 7.894531 17.652343 17.652343v211.863281c0 9.757813-7.902343 17.65625-17.652343 17.65625zm0 0" />
                      </svg>
                    }
                    className=""
                    secondary={true}
                    disabled={!this.state.remover}
                    onClick={this.handleRemoveShippingAddress}
                  />
                </Col>
              </Row>
            ) : null}


            {deliveryAddresses.length === 0 ? (

              <div className="newShippingAddressStyle">
                <Row style={{ textAlign: "center" }}>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Row className="shippingRowTitleAddress">
                      <span className="titleShippingAddAddress">
                        <FormattedMessage id="billingAddress.novaMorada" />
                      </span>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          placeholder={this.props.intl.formatMessage({ id: "billingAddress.novaMorada.nome" })}
                          name={"nome"}
                          fullWidth={true}
                          value={this.state.novaMorada.nome}
                          onChange={this.handleInput}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          placeholder={this.props.intl.formatMessage({ id: "billingAddress.novaMorada.morada" })}
                          name={"morada"}
                          fullWidth={true}
                          value={this.state.novaMorada.morada}
                          onChange={this.handleInput}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          placeholder={this.props.intl.formatMessage({ id: "billingAddress.novaMorada.localidade" })}
                          name={"localidade"}
                          fullWidth={true}
                          value={this.state.novaMorada.localidade}
                          onChange={this.handleInput}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          placeholder={this.props.intl.formatMessage({ id: "billingAddress.novaMorada.codigoPostal" })}
                          name={"codigoPostal"}
                          fullWidth={true}
                          value={this.state.novaMorada.codigoPostal}
                          onChange={this.handleInput}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        {this.state.paisesOptions.length > 0 ? (
                          <FormControl style={{ width: "100%", "margin-top": "10px" }}>
                            <NativeSelect
                              name={"id_pais"}
                              defaultValue={"PRT"}
                              value={this.state.novaMorada.id_pais}
                              onChange={this.handleAddShippingChangePais}>
                              <option value="" disabled>
                                {this.props.intl.formatMessage({ id: "billingAddress.novaMorada.id_pais" })}
                              </option>
                              {this.state.paisesOptions.map((address, j) => {
                                return (
                                  <option value={address.id}>{address.title}</option>
                                );
                              })}
                            </NativeSelect>
                          </FormControl>
                        ) : null}
                      </Col>
                    </Row>
                    <Row style={{ "text-align": "center" }}>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <Button
                          className="addMoradasButton"
                          action={this.handleFormSubmit}
                          title={this.props.intl.formatMessage({ id: "billingAddress.novaMorada.addMoradasButton" })}
                          style={{ "margin-top": "10px" }}
                        />
                      </Col>
                    </Row>
                  </Col>{" "}
                </Row>
              </div>
            ) : null}

            {/* Criar nova morada */}
            <Row style={{ textAlign: "center" }}>
              <div className="shippingAddAddress">
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Row className="shippingRowTitleAddress">
                    <span className="titleShippingAddAddress">
                      <FormattedMessage id="billingAddress.novaMorada" />
                    </span>

                    {deliveryAddresses.length !== 0 ? (
                      <Fab className="closeAddShippingAddress" variant="extended" size="small" onClick={this.handleCleanAddShippingAddress}>
                        <FaTimes />
                      </Fab>
                    ) : null}

                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        placeholder={this.props.intl.formatMessage({ id: "billingAddress.novaMorada.nome" })}
                        name={"nome"}
                        fullWidth={true}
                        value={this.state.novaMorada.nome}
                        onChange={this.handleInput}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        placeholder={this.props.intl.formatMessage({ id: "billingAddress.novaMorada.morada" })}
                        name={"morada"}
                        fullWidth={true}
                        value={this.state.novaMorada.morada}
                        onChange={this.handleInput}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        placeholder={this.props.intl.formatMessage({ id: "billingAddress.novaMorada.localidade" })}
                        name={"localidade"}
                        fullWidth={true}
                        value={this.state.novaMorada.localidade}
                        onChange={this.handleInput}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        placeholder={this.props.intl.formatMessage({ id: "billingAddress.novaMorada.codigoPostal" })}
                        name={"codigoPostal"}
                        fullWidth={true}
                        value={this.state.novaMorada.codigoPostal}
                        onChange={this.handleInput}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      {this.state.paisesOptions.length > 0 ? (
                        <FormControl style={{ width: "100%", "margin-top": "10px" }}>
                          <NativeSelect
                            name={"id_pais"}
                            defaultValue={"PRT"}
                            value={this.state.novaMorada.id_pais}
                            onChange={this.handleAddShippingChangePais}>
                            <option value="" disabled>
                              {this.props.intl.formatMessage({ id: "billingAddress.novaMorada.id_pais" })}
                            </option>
                            {this.state.paisesOptions.map((address, j) => {
                              return (
                                <option value={address.id}>{address.title}</option>
                              );
                            })}
                          </NativeSelect>
                        </FormControl>
                      ) : null}
                    </Col>
                  </Row>
                  <Row style={{ "text-align": "center" }}>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Button
                        className="addMoradasButton"
                        action={this.handleFormSubmit}
                        title={this.props.intl.formatMessage({ id: "billingAddress.novaMorada.addMoradasButton" })}
                        style={{ "margin-top": "10px" }}
                      />
                    </Col>
                  </Row>
                </Col>{" "}
              </div>
            </Row>

            {/* Modificar morada */}
            <Row style={{ textAlign: "center" }}>
              <div className="shippingModifyAddress">
                <Col xs={12} sm={12} md={12} lg={12}>

                  {/*Linha com titulo e botão de cancelar alteração*/}
                  <Row className="shippingRowTitleAddress">
                    <span className="titleShippingModifyAddress">
                      <FormattedMessage id="billingAddress.modificarMoradaTitle" />
                    </span>
                    <Fab
                      className="closeModifyShippingAddress"
                      variant="extended"
                      size="small"
                      onClick={this.handleCleanModifyShippingAddress}
                    >
                      <FaTimes />
                    </Fab>
                  </Row>

                  {/* Nome */}
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        placeholder={this.props.intl.formatMessage({
                          id: "billingAddress.modificarMorada.nome"
                        })}
                        name={"nome"}
                        fullWidth={true}
                        value={this.state.alterarMorada.nome}
                        onChange={this.handleInputAlterarMorada}
                      />
                    </Col>
                  </Row>

                  {/* Morada */}
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        placeholder={this.props.intl.formatMessage({
                          id: "billingAddress.modificarMorada.morada"
                        })}
                        name={"morada"}
                        fullWidth={true}
                        value={this.state.alterarMorada.morada}
                        onChange={this.handleInputAlterarMorada}
                      />
                    </Col>
                  </Row>

                  {/* Localidade */}
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        placeholder={this.props.intl.formatMessage({
                          id: "billingAddress.modificarMorada.localidade"
                        })}
                        name={"localidade"}
                        fullWidth={true}
                        value={this.state.alterarMorada.localidade}
                        onChange={this.handleInputAlterarMorada}
                      />
                    </Col>
                  </Row>

                  {/* Codigo Postal */}
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        placeholder={this.props.intl.formatMessage({
                          id: "billingAddress.modificarMorada.codigoPostal"
                        })}
                        name={"codigoPostal"}
                        fullWidth={true}
                        value={this.state.alterarMorada.codigoPostal}
                        onChange={this.handleInputAlterarMorada}
                      />
                    </Col>
                  </Row>

                  {/* Paises */}
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      {this.state.paisesOptions.length > 0 ? (
                        <FormControl
                          style={{ width: "100%", "margin-top": "10px" }}
                        >
                          {this.state.paisesOptions.length > 0 ? (
                            <NativeSelect
                              name={"id_pais"}
                              value={this.state.alterarMorada.id_pais}
                              onChange={this.handleModifyShippingChangePais}
                            >
                              <option value="" disabled>
                                {this.props.intl.formatMessage({
                                  id: "billingAddress.modificarMorada.id_pais"
                                })}
                              </option>
                              {this.state.paisesOptions.map((address, j) => {
                                return (
                                  <option value={address.id}>
                                    {address.title}
                                  </option>
                                );
                              })}
                            </NativeSelect>
                          ) : null}
                        </FormControl>
                      ) : null}
                    </Col>
                  </Row>

                  {/* Botão editar morada */}
                  <Row style={{ "text-align": "center" }}>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Button
                        className="addMoradasButton"
                        action={this.handleFormModifyShippingAddressSubmit}
                        title={this.props.intl.formatMessage({
                          id:
                            "billingAddress.modificarMoradaTitle.addMoradasButton"
                        })}
                        style={{ "margin-top": "10px" }}
                      />
                    </Col>
                  </Row>

                </Col>{" "}
              </div>
            </Row>

            {/* Escolher moradas */}
            <Row style={{ textAlign: "center" }}>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="shippingAddresses">
                  {deliveryAddresses.length > 0 ? (
                    <SelectField
                      floatingLabelText={this.props.intl.formatMessage({
                        id: "billingAddress.moradaEnvioLabel"
                      })}
                      value={this.state.moradas.moradaEnvio}
                      onChange={this.handleChangeShippingAddress}
                      fullWidth={true}
                      style={styles.customWidth}
                      className="SelectField"
                      labelStyle={{ paddingRight: "0px" }}
                    >
                      {deliveryAddresses.map((address, j) => {
                        return (
                          <MenuItem
                            value={address.ID_Cliente_Morada}
                            primaryText={address.Nome + " - " + address.Morada}
                          />
                        );
                      })}
                    </SelectField>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Col>
        </Row >
      </div >
    ) : (checkoutData.TipoTransporteExterno === 'N' ? //Se o tipo de transporte não for externo, não precisa de morada de envio
      (
        <div className={"checkoutBox doneSelected"}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} style={{ "text-align": "center", top: "22px", height: "112px" }}>
              <h4>
                <FormattedMessage id="billingAddress.dadosNaoAplicaveis" />
              </h4>
              <h6>
                <FormattedMessage id="billingAddress.moradaNaoNecessita" />
              </h6>
            </Col>
          </Row>
        </div>

      ) : ( //Se não for externo significa que tem produtos apenas online
        <div className={"checkoutBox doneSelected"}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} style={{ "text-align": "center", top: "22px", height: "112px" }}>
              <h4>
                <FormattedMessage id="billingAddress.dadosNaoAplicaveis" />
              </h4>
              <h6>
                <FormattedMessage id="billingAddress.dadosNaoAplicaveis1" />
              </h6>
            </Col>
          </Row>
        </div>
      )
      )
  }
}

const mapStateToProps = state => {
  return {
    deliveryAddresses: state.deliveryAddresses,
    cart: state.cart,
    checkoutData: state.checkoutData
  };
};

export default injectIntl(connect(mapStateToProps)(DadosEnvio));
