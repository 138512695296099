import { FlatButton } from "material-ui";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/plain.css';
import { connect } from "react-redux";
import { ToastContainer } from "react-toastr";
import { getPaymentTypes, selectPaymentType, selectPaymentPhoneNumber } from "../../../actions/miniCartActions";
import { addPhoneNumber, getPhoneNumbers } from "../../../actions/userActions";
import Button from "../../Forms/Button";

let container;

const styleLabel = {
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Roboto",
  color: "rgba(0, 0, 0, 0.3)",
  textAlign: "center"
}

const styleLabel2 = {
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Roboto",
  color: "rgba(0, 0, 0, 0.3)",
  fontWeight: "bold"
}

class TiposPagamento extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentType: {
        type: null
      },
      pagamentoEmParcelas: false,
      parcelas: [],
      parcelaEscolhida: 1,
      pagamentoPorTelemovel: false,
      numeroTelemovelSelecionado: null,
      telemoveis: [],
      criarTelemovel: false,
      novoTelemovelNumero: null,
      novoTelemovelIndicativo: null
    };

    this.clickToAddPhoneNumberForm = this.clickToAddPhoneNumberForm.bind(this);
    this.clickToHidePhoneNumberForm = this.clickToHidePhoneNumberForm.bind(this);
    this.handleChangeNewPhoneNumber = this.handleChangeNewPhoneNumber.bind(this);
    this.addNewPhoneNumber = this.addNewPhoneNumber.bind(this);
    this.handleChangePhoneNumber = this.handleChangePhoneNumber.bind(this);
    this.loadPhoneNumbers = this.loadPhoneNumbers.bind(this);
  }
  componentDidMount() {
    this.props.dispatch(getPaymentTypes());
  }

  //Guardar o valor do novo telemovel
  handleChangeNewPhoneNumber(value, data, event, formattedValue) {

    var dialCode = data.dialCode;
    var rawPhone = value.slice(data.dialCode.length)
    this.setState(prevState => ({
      ...prevState,
      novoTelemovelIndicativo: dialCode,
      novoTelemovelNumero: rawPhone
    }));
  }

  handleChangeInstallments = (evento, index, value) => {

    var paymentType = { ...this.state.paymentType };
    var phoneNumber = { ...this.state.numeroTelemovelSelecionado };

    this.props.dispatch(selectPaymentType(paymentType.type, value, phoneNumber))
      .then(data => {
        if (typeof data !== 'undefined') {
          container.clear();
          container.error(`${data.error}`);
        }
      });

    this.setState(prevState => ({
      ...prevState,
      parcelaEscolhida: value,
    }));

  }

  //Método para permitir escolher tlm perante os valores existentes
  handleChangePhoneNumber(value, index, value2) {

    var paymentType = { ...this.state.paymentType };

    var numero = null;
    var tmpNumero = this.state.telemoveis.find(t => t.Id == value2);
    if (tmpNumero != null) {
      numero = tmpNumero.Descricao;
    }

    //Se numero estiver a null, significa que não encontramos o id do numero selecionado, 
    //nos numeros que vieram do servidor, por isso os dados não terão sido carregados corretamente
    //algo que nunca deve ocorrer, mas só para precaver
    if (numero == null) {
      container.clear();
      container.error(<FormattedMessage id="pagamento.erro.servidor" />);
      return;
    }

    var numeroComIndicativo = numero.trimStart().trimEnd().split(" ");

    //Verificamos se é um número válido
    //Se não tiver length de 2 significa que não está no formato: {indicativoPais} {numero}
    if (numeroComIndicativo.length !== 2) {
      container.clear();
      container.error(<div><span> Número de Telemóvel inválido. O indicativo de país deve estar separado do número.</span><p style={{ color: "white" }}> Formáto válido: 351 912345678</p></div>);

      this.setState(prevState => ({
        ...prevState,
        numeroTelemovelSelecionado: null
      }));

      this.props.dispatch(selectPaymentPhoneNumber(null));
    } else {

      this.props.dispatch(selectPaymentType(paymentType.type, this.state.parcelaEscolhida, numero))
        .then(data => {
          if (typeof data !== 'undefined') {
            container.clear();
            container.error(`${data.error}`);
          }
        });


      this.setState(prevState => ({
        ...prevState,
        numeroTelemovelSelecionado: value2
      }))
    }
  }

  //Método para adicionar novo número de telemóvel
  addNewPhoneNumber() {

    if (this.state.novoTelemovelIndicativo === "351" && this.state.novoTelemovelNumero.length !== 9) {
      container.clear();
      container.error(<FormattedMessage id="pagamento.erro.telemovelInvalido" />);
      return;
    }

    if (this.state.novoTelemovelIndicativo == null || this.state.novoTelemovelNumero == null) {
      container.clear();
      container.error(<FormattedMessage id="pagamento.erro.telemovelInvalido" />);
      return;
    }

    var phoneNumber = this.state.novoTelemovelIndicativo + " " + this.state.novoTelemovelNumero;
    addPhoneNumber(phoneNumber)
      .then(result => {
        if (result.success === true) {

          this.loadPhoneNumbers();

          this.setState(prevState => ({
            ...prevState,
            criarTelemovel: false,
            numeroTelemovelSelecionado: result.obj
          }));

          this.props.dispatch(selectPaymentPhoneNumber(phoneNumber));

          container.clear();
          container.success(<FormattedMessage id="pagamento.sucesso.adicionarTelemovel" />);
        } else {
          container.clear();
          container.error(result.message);
        }
      })
      .catch(err => {
        container.clear();
        container.error(<FormattedMessage id="pagamento.erro.erroAoAdicionarTelemovel" />);
      });
  }

  loadPhoneNumbers() {
    getPhoneNumbers()
      .then(result => {
        var telemoveis = [];
        if (result.success === true) {
          telemoveis = result.obj
        }

        this.setState(prevState => ({
          ...prevState,
          telemoveis
        }));
      })
      .catch(err => {
        this.setState(prevState => ({
          ...prevState,
          telemoveis: []
        }));
      })
  }


  //Método para abrir o formulário de adicionar telemóvel
  clickToAddPhoneNumberForm() {
    this.setState(prevState => ({
      ...prevState,
      criarTelemovel: true,
      numeroTelemovelSelecionado: null
    }))

    this.props.dispatch(selectPaymentPhoneNumber(null));

  }

  //Método para esconder o formulário de adicionar telemóvel
  clickToHidePhoneNumberForm() {
    this.setState(prevState => ({
      ...prevState,
      criarTelemovel: false
    }))
  }

  //Método para alterar o tipo de pagamento
  handleChangePaymentType = (event, index, value) => {

    //Procuramos o pagamento atual selecionado
    var currentPayment = this.props.paymentTypes.find(p => p.ID_TipoPagamento === value);

    //Verificamos se é um pagamento em parcelas
    var pagamentoEmParcelas = (currentPayment.EmParcelas === true);

    //Verificamos se é um pagamento por telemovel
    var numeroTelemovel = null;
    var pagamentoPorTelemovel = (currentPayment.PagamentoPorTelemovel === true);

    //Adicionamos o número de parcelas
    var parcelas = [];
    for (let i = 1; i <= currentPayment.NMaxParcelas; i++) {
      parcelas.push({ value: i });
    }

    //Se tivermos pagamento por telemovel definido, vamos buscar os numeros
    if (pagamentoPorTelemovel) {
      this.loadPhoneNumbers();
    }

    this.props.dispatch(selectPaymentType(value, 1, null))
      .then(data => {
        if (typeof data !== 'undefined') {
          container.clear();
          container.error(`${data.error}`);
        }
      });

    this.setState(prevState => ({
      ...prevState,
      paymentType: {
        ...prevState.paymentType,
        type: value
      },
      pagamentoEmParcelas,
      parcelas,
      parcelaEscolhida: 1,
      pagamentoPorTelemovel,
      numeroTelemovelSelecionado: numeroTelemovel
    }));
  };

  render() {
    const { checkoutData, deliveryAddresses, billingAddresses } = this.props;
    let { paymentTypes } = this.props;

    var deliveryAddressCountry = "";
    var billingAddressCountry = "";

    //Ficamos o address de entrega e de faturação
    var selectedDeliveryAddress = deliveryAddresses.find(add => add.id == checkoutData.shippingAddress);
    var selectedBillingAddress = billingAddresses.find(add => add.id == checkoutData.billingAddress);

    //Ficamos com o país desses address
    if (selectedDeliveryAddress != null) deliveryAddressCountry = selectedDeliveryAddress.ID_Pais;
    if (selectedBillingAddress != null) billingAddressCountry = selectedBillingAddress.ID_Pais;

    //Só ficamos com os tipos de pagamento que estejam disponiveis para o pais
    paymentTypes = paymentTypes.filter(p => {
      if (p.PaisesDisponiveis == null) return true;

      if (p.PaisesDisponiveis != null) {
        if (p.PaisesDisponiveis.includes(deliveryAddressCountry)) return true;
        if (p.PaisesDisponiveis.includes(billingAddressCountry)) return true;
      }
      return false;
    });

    //Procuramos o tipo de pagamento selecionado
    var selectedPayment = paymentTypes.find(p => p.Selected === true);

    //Significa que já se tinha selecionado um método de pagamento que agora não está disponível    
    if (selectedPayment == null && (typeof checkoutData.selectPaymentTypes !== 'undefined' && checkoutData.selectPaymentTypes != null)) {

      this.props.dispatch(selectPaymentType(null, 0, null));
      this.setState(prevState => ({
        ...prevState,
        paymentType: {
          ...prevState.paymentType,
          type: null
        },
      }));
    }

    return checkoutData.Total !== 0 ? (
      <div>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <div
          className={
            !checkoutData.confirmOrderClicked && checkoutData.selectPaymentTypes
              ? "checkoutBox doneSelected"
              : checkoutData.confirmOrderClicked
                ? checkoutData.selectPaymentTypes
                  ? "checkoutBox doneSelected"
                  : "checkoutBox discountSelected"
                : "checkoutBox"
          }
        >
          <Row>
            <Col xs={3} sm={3} md={3} lg={2} style={{ textAlign: "left" }}>
              <h4>
                <svg
                  width="35px"
                  height="34px"
                  viewBox="0 0 612 612"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path
                      d="M256,0C114.609,0,0,114.609,0,256c0,141.391,114.609,256,256,256c141.391,0,256-114.609,256-256
		C512,114.609,397.391,0,256,0z M256,472c-119.297,0-216-96.703-216-216S136.703,40,256,40s216,96.703,216,216S375.297,472,256,472z
		"
                    />
                    <g>
                      <path
                        d="M274.203,384v-62.5H176v-37.203L274.203,128H303.5v164.484H336V321.5h-32.5V384H274.203z M202.438,292.484h71.766V176.969
			L202.438,292.484z"
                      />
                    </g>
                  </g>
                </svg>
              </h4>
            </Col>
            <Col xs={6} sm={6} md={6} lg={8} style={{ textAlign: "center" }}>
              <h5>
                <FormattedMessage id="pagamento.titulo" />
              </h5>
            </Col>
            <Col xs={3} sm={3} md={3} lg={2} style={{ textAlign: "right" }}>
              <h4>
                <svg
                  width="30px"
                  height="29px"
                  viewBox="0 0 354 354"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Layer_5_58_">
                    <g>
                      <g>
                        <path
                          d="M265.442,109.092c-10.602-4.25-13.665-6.82-13.665-11.461c0-3.714,2.813-8.053,10.744-8.053
					c7.015,0,12.395,2.766,12.443,2.79c0.566,0.302,1.201,0.463,1.83,0.463c1.535,0,2.893-0.929,3.456-2.367l1.927-4.926
					c0.671-1.795-0.347-3.359-1.645-3.92c-4.319-1.88-12.76-3.335-12.846-3.35c-0.136-0.024-0.609-0.125-0.609-0.678l-0.027-7.146
					c0-2.152-1.797-3.904-4.003-3.904h-3.457c-2.204,0-4,1.751-4,3.905l0.009,7.513c0,0.576-0.624,0.826-0.852,0.879
					c-10.655,2.538-17.314,10.343-17.314,20.188c0,12.273,10.145,17.819,21.099,21.982c8.757,3.438,12.329,6.924,12.329,12.037
					c0,5.564-5.059,9.45-12.307,9.45c-6.189,0-14.565-3.923-14.648-3.963c-0.536-0.254-1.104-0.382-1.688-0.382
					c-1.594,0-2.982,0.964-3.537,2.457l-1.84,4.982c-0.654,1.86,0.353,3.37,1.642,4.042c5.144,2.679,15.098,4.249,15.541,4.318
					c0.119,0.017,0.725,0.23,0.725,0.784v7.48c0,2.152,1.797,3.904,4.004,3.904h3.572c2.208,0,4.005-1.751,4.005-3.904v-7.872
					c0-0.736,0.543-0.801,0.655-0.828c11.351-2.55,18.343-10.855,18.343-21.283C285.325,121.518,279.377,114.597,265.442,109.092z"
                        />
                        <path
                          d="M260.979,22.509c-51.816,0-93.818,42.005-93.818,93.818c0,51.814,42.002,93.82,93.818,93.82
					c51.814,0,93.817-42.006,93.817-93.82C354.796,64.514,312.793,22.509,260.979,22.509z M260.979,188.404
					c-39.808,0-72.076-32.271-72.076-72.076s32.268-72.075,72.076-72.075c39.806,0,72.073,32.27,72.073,72.075
					S300.785,188.404,260.979,188.404z"
                        />
                      </g>
                      <g>
                        <path
                          d="M335.733,255.61c-19.95,11.011-47.389,21.192-74.753,25.484c-24.346,3.818-70.148-5.39-70.148-16.265
					c0-4.121,40.17,10.154,64.469,3.671c18.633-4.971,15.988-22.401,5.853-24.7c-10.076-2.287-69.108-23.913-94.323-24.659
					c-11.878-0.351-41.203,4.131-55.393,6.442c-4.861,0.791-7.909,0.704-8.213,5.356c-1.412,21.62-4.195,65.832-5.712,88.926
					c-0.032,0.488,0.646,7.05,6.061,2.432c5.927-5.054,14.24-10.656,21.929-8.912c12.063,2.737,116.424,21.856,130.819,18.51
					c20.593-4.787,78.888-39.334,90.065-50.072C363.711,265.176,350.244,247.601,335.733,255.61z"
                        />
                        <path
                          d="M74.426,224.74l-54.672-2.694c-4.221-0.208-8.532,2.973-9.581,7.066l-9.941,90.255c-1.048,4.094,1.55,7.578,5.773,7.741
					l60.59-0.006c4.224,0.163,7.942-3.151,8.266-7.365l6.654-86.958C81.837,228.566,78.647,224.948,74.426,224.74z M42.24,315.145
					c-8.349,0-15.116-6.768-15.116-15.116c0-8.349,6.768-15.116,15.116-15.116s15.116,6.768,15.116,15.116
					C57.356,308.378,50.589,315.145,42.24,315.145z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </h4>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className="paymentType">
              <SelectField
                floatingLabelText={this.props.intl.formatMessage({
                  id: "pagamento.acao"
                })}
                value={this.state.paymentType.type}
                onChange={this.handleChangePaymentType}
                fullWidth={true}
                labelStyle={{ paddingRight: "0px" }}
                className="SelectField"
              >
                {paymentTypes.map((paymentType, j) => {
                  return paymentType.ID_TipoPagamento !== "NA" ? (
                    <MenuItem
                      value={paymentType.ID_TipoPagamento}
                      primaryText={paymentType.Descricao}
                    />
                  ) : null;
                })}
              </SelectField>

              <SelectField hidden={!this.state.pagamentoEmParcelas}
                floatingLabelText={
                  this.props.intl.formatMessage({
                    id: "pagamento.parcelas"
                  })
                } fullWidth={true}
                className="SelectField" onChange={this.handleChangeInstallments} value={this.state.parcelaEscolhida}
              >
                {this.state.parcelas.length > 0 ?
                  (this.state.parcelas.map(item => {
                    return <MenuItem
                      value={item.value}
                      primaryText={item.value + "" + ((item.value < 2) ? " Mês" : " Meses")}
                    />
                  }))
                  : (null)
                }
              </SelectField>


              {/* Adicionar telemóvel MBWAY*/}
              <div hidden={!this.state.pagamentoPorTelemovel} style={{ margin: "10px", padding: "10px", backgroundColor: "white", border: "1px solid grey" }}>
                <span style={styleLabel}>
                  {this.props.intl.formatMessage({
                    id: "pagamento.telemovel"
                  })}
                </span>

                {/* Botão para adicionar um novo numero de telemovel*/}
                <Row>
                  <Col>
                    <FlatButton
                      label={
                        <svg
                          height="25px"
                          viewBox="0 0 512 512"
                          width="25px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0" />
                          <path d="m256 365.714844c-10.097656 0-18.285156-8.1875-18.285156-18.285156v-182.859376c0-10.097656 8.1875-18.285156 18.285156-18.285156s18.285156 8.1875 18.285156 18.285156v182.859376c0 10.097656-8.1875 18.285156-18.285156 18.285156zm0 0" />
                          <path d="m347.429688 274.285156h-182.859376c-10.097656 0-18.285156-8.1875-18.285156-18.285156s8.1875-18.285156 18.285156-18.285156h182.859376c10.097656 0 18.285156 8.1875 18.285156 18.285156s-8.1875 18.285156-18.285156 18.285156zm0 0" />
                        </svg>
                      }
                      secondary={true}
                      onClick={this.clickToAddPhoneNumberForm}
                    />
                  </Col>
                </Row>

                <br />
                {this.state.criarTelemovel !== true ? (
                  <Row>
                    <Col>
                      {this.state.telemoveis.length > 0 ? (
                        <SelectField
                          floatingLabelText={this.props.intl.formatMessage({
                            id: "pagamento.selecionarTelemoveis"
                          })}
                          value={this.state.numeroTelemovelSelecionado}
                          onChange={this.handleChangePhoneNumber}
                          fullWidth={true}
                          style={{ fontWeight: "bold", textAlign: "center", paddingRight: "0px" }}
                          className="SelectField"
                          labelStyle={{ paddingRight: "0px" }}
                        >
                          <MenuItem
                            value={null} primaryText={this.props.intl.formatMessage({
                              id: "pagamento.selecioneUmTelemovel"
                            })}
                          />
                          {this.state.telemoveis.map((tlm, j) => {
                            return (
                              <MenuItem
                                value={tlm.Id}
                                primaryText={tlm.Descricao}
                              />
                            );
                          })}
                        </SelectField>
                      ) : null}
                    </Col>
                  </Row>
                ) : (
                    <div>
                      <label><FormattedMessage id="pagamento.adicionarNumeroTelemovel" /></label>
                      <Row>
                        <Col>
                          <PhoneInput searchPlaceholder={this.props.intl.formatMessage({
                            id: "pagamento.procurarTelemovel"
                          })}
                            placeholder={this.props.intl.formatMessage({
                              id: "pagamento.introduzirNumeroTelemovelPlaceholder"
                            })}
                            country="pt"
                            copyNumbersOnly={false}
                            enableSearch={true} onChange={this.handleChangeNewPhoneNumber}
                          />
                        </Col>
                      </Row>
                      <Button
                        action={this.addNewPhoneNumber}
                        type="primary"
                        title={this.props.intl.formatMessage({
                          id: "pagamento.guardarNumeroTelemovelButton"
                        })}
                        style={{ "margin-top": "10px", maxWidth: "100%", whiteSpace: "normal" }}
                      />
                      <Button
                        action={this.clickToHidePhoneNumberForm}
                        title={this.props.intl.formatMessage({
                          id: "pagamento.cancelarButton"
                        })}
                        style={{ "margin-top": "10px", marginLeft: "5px" }}
                      />
                    </div>
                  )}


              </div>

            </Col>
          </Row>
        </div>
      </div >
    ) : (
        <div className={"checkoutBox doneSelected"}>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ "text-align": "center", top: "7px", height: "112px" }}
            >
              <h5>
                <FormattedMessage id="pagamento.naoaplicavel" />
              </h5>
              <h6>
                <FormattedMessage id="pagamento.naoaplicavel1" />
              </h6>
            </Col>
          </Row>
        </div>
      );
  }
}

const mapStateToProps = state => {
  return {
    paymentTypes: state.paymentTypes,
    checkoutData: state.checkoutData,
    deliveryAddresses: state.deliveryAddresses,
    billingAddresses: state.billingAddresses,
  };
};

export default injectIntl(connect(mapStateToProps)(TiposPagamento));
