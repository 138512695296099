import React, { Component } from "react";
// import Footer from "../../../../components/Footer/Footer";
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import HeaderDesktop from "../../../../components/Desktop/Header/Header";
import ProductDetailDesktop from "../../../../components/Desktop/ProductDetail/ProductDetail";
import Footer from "../../../../components/Footer/Footer";

class DetailView extends Component {
  render() {
    return (
      <div className="App">
        <HeaderDesktop />
        <br />
        <Container
          style={{
            position: "relative",
            top: "60px",
            width: "90vw"
          }}
        >
          <Row
            style={{
              textAlign: "center"
            }}
          >
            <Col xs="12" sm="12" md="12" lg="12">
              <ProductDetailDesktop id={this.props.id} />
            </Col>
          </Row>
        </Container>
        <br />
        {isMobile ? null : <Footer />}
      </div>
    );
  }
}

export default DetailView;

