import React, { Component } from "react";

import { connect } from "react-redux";
import HeaderDesktop from "../../../components/Desktop/Header/Header";

import DesktopRegister from "../../../components/Desktop/Register/Register";

import { ReCaptcha } from "react-recaptcha-google";
import { isVerifiedRecaptcha } from "../../../actions/miniCartActions";
import Footer from "../../../components/Footer/Footer";
import _ from 'lodash';
import { Container, Col, Row } from "react-bootstrap";

// https://www.codementor.io/blizzerand/building-forms-using-react-everything-you-need-to-know-iz3eyoq4y

class Register extends Component {
  constructor(props) {
    super(props);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.props.dispatch(isVerifiedRecaptcha(false));
  }

  componentDidMount() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }

  }

  verifyCallback(response) {
    this.props.dispatch(isVerifiedRecaptcha(true));
    if (response) {
      this.setState({
        isVerified: true
      });
    } else {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  }

  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  }

  isFacebookDateAvailable() {

    if (!_.isUndefined(this.props.location) || !_.isNull(this.props.location)) {
      return false;
    }

    if (!_.isUndefined(this.props.location.state) || !_.isNull(this.props.location.state)) {
      return false;
    }

    if (!_.isUndefined(this.props.location.state.facebookData) || !_.isNull(this.props.location.state.facebookData)) {
      return false;
    }

    return true;
  }

  isGoogleDataAvailable() {
    if (!_.isUndefined(this.props.location) || !_.isNull(this.props.location)) {
      return false;
    }

    if (!_.isUndefined(this.props.location.state) || !_.isNull(this.props.location.state)) {
      return false;
    }

    if (!_.isUndefined(this.props.location.state.googleData) || !_.isNull(this.props.location.state.googleData)) {
      return false;
    }
  }
  render() {

    var facebookData = null;
    var googleData = null;

    if (this.isFacebookDateAvailable() === true) {
      facebookData = this.props.location.state.facebookData;
    }

    if (this.isGoogleDataAvailable() === true) {
      googleData = this.props.location.state.googleData
    }

    return (
      <div className="App">
        <HeaderDesktop />
        <Container className="mainContentContainer">
          <Row style={{ textAlign: "center" }} >
            <Col xs="12" sm="12" md="12" lg="12">
              <DesktopRegister email="Endereço de Email" password="Password" facebookData={facebookData} googleData={googleData} />
              <ReCaptcha
                ref={el => {
                  this.captchaDemo = el;
                }}
                size="invisible"
                render="explicit"
                sitekey="6LcjwKcUAAAAAB0AMiECn6QGrAvSi3VVhGjcGHNH"
                onloadCallback={this.onLoadRecaptcha}
                verifyCallback={this.verifyCallback}
              />
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(Register);
