import ouibounce from "ouibounce";
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Col, Modal, Row } from "reactstrap";

class DetectLeave extends Component {

    constructor(props) {
        super(props);
        this.state = {
            handler: null,
            open: false
        }

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        const handler = this.setupOuibounce()
        this.setState({ handler })
    }


    setupOuibounce() {
        return ouibounce(false, {
            sensitivity: 100,
            cookieName: "userExitIntentAction",
            cookieExpire: 0.5,
            sitewide: true,
            precondition: () => {

                //Caso tenhamos produtos no carrinho
                if (typeof this.props.cartNumberItems !== 'undefined' && this.props.cartNumberItems > 0) {
                    return true;
                }
                return false;
            },
            callback: () => {

                //Mostramos o modal a afirmar que tem produtos no carrinho
                this.openModal();
            }
        })

    }

    openModal() {
        this.setState(prevState => ({
            ...prevState,
            open: true
        }));
    }

    closeModal() {
        this.setState(prevState => ({
            ...prevState,
            open: false
        }));
    }


    render() {
        var userIsLoggedIn = (typeof this.props.user.userID !== 'undefined' && this.props.user.userID != null);
        return (
            <div>
                <Modal isOpen={this.state.open} toggle={this.closeModal} centered={true}>
                    <div style={{ padding: "20px", fontWeight: "500" }}>
                        <Row style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                            <Col md={8}>
                                <label style={{ fontSize: "25px", fontWeight: "500" }}>
                                    {userIsLoggedIn ? ("Olá " + this.props.user.userName) : ("Viva")}
                                    < span role="img" aria-label="Smile"> 😊</span>
                                </label>
                            </Col>
                            <Col md={4} style={{ textAlign: "right", fontWeight: "500", fontSize: "25px", color: "darkgray" }}>
                                <label style={{ cursor: "pointer" }} onClick={this.closeModal}>x</label>
                            </Col>
                        </Row>
                        <label>Esqueceu-se de produtos no carrinho sem finalizar a encomenda que vai potenciar as suas capacidades.</label>
                        <Row style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <Col style={{ textAlign: "center" }}>
                                <Button color="primary" onClick={() => {
                                    window.location = "/Carrinho"
                                }}>Quero saber quais</Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        cartNumberItems: state.cartNumberItems,
        user: state.user
    };
};

export default connect(mapStateToProps)(DetectLeave);
