import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import FacebookLogin from 'react-facebook-login';
import ReactGA from "react-ga";
import GoogleLogin from 'react-google-login';
import { FaFacebookF } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { FormattedMessage } from "react-intl";
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-google";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastr";
import { getFacebookAppId, getGoogleAppId, getGoogleRecaptchaKey } from "../../../actions/geralActions";
import { isVerifiedRecaptcha, login } from "../../../actions/miniCartActions";
import { loginWithFacebook, loginWithGoogle } from "../../../actions/userActions";
import { funnelStep } from "../../../Analytics/DataLayer";
import Routes from "../../../helpers/Routes";
import "./Login.css";
import CrossDomainEventHandler from "../../../helpers/CrossDomainEventHandler";
import { NavLink } from "react-router-dom";

let container;

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      successFacebookCallback: false,
      facebookError: false,
      facebookData: {
        id: null,
        name: null,
        email: null,
        accessToken: null
      },
      facebookAppId: null,
      successGoogleCallback: false,
      googleError: false,
      googleData: {
        code: null
      },
      googleSetupErrorMessage: "",
      googleButtonDisabled: false,
      googleAppId: null,
      recaptchaKey: null
    };

    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.props.dispatch(isVerifiedRecaptcha(false));
    this.loginFacebookResponse = this.loginFacebookResponse.bind(this);
    this.loginFacebookFailure = this.loginFacebookFailure.bind(this);
    this.loginFacebook = this.loginFacebook.bind(this);
    this.loginGoogleSuccess = this.loginGoogleSuccess.bind(this);
    this.loginGoogleFailure = this.loginGoogleFailure.bind(this);
    this.loginGoogle = this.loginGoogle.bind(this);
    this.loadGoogleAppId = this.loadGoogleAppId.bind(this);

    this.loadRecaptchaKey();
    this.loadGoogleAppId();
    this.loadFacebookAppId();
  }

  //Método para obter o id de App da Google 
  loadGoogleAppId() {
    getGoogleAppId()
      .then(result => {
        if (result.success === true) {
          this.setState(prevState => ({
            ...prevState,
            googleAppId: result.obj
          }))
        }
      }).catch(err => {
      })
  }

  //Método para obter o id de App do Facebook
  loadFacebookAppId() {
    getFacebookAppId()
      .then(result => {
        if (result.success === true) {
          this.setState(prevState => ({
            ...prevState,
            facebookAppId: result.obj
          }))
        }
      }).catch(err => {
      })
  }

  loadRecaptchaKey() {
    getGoogleRecaptchaKey()
      .then(result => {
        if (result.success === true) {
          this.setState(prevState => ({
            ...prevState,
            recaptchaKey: result.obj
          }), () => {
            loadReCaptcha()
          })
        }
      }).catch(err => {

      })
  }
  //Método de fazer login
  loginAccount = async e => {

    //Se a pessoa tiver verificado o captch
    if (this.state.isVerified) {
      e.stopPropagation();
      this.props.dispatch(login(this.state.email, this.state.password))
        .then(data => {

          //Se conseguirmos fazer login com sucesso
          if (data.success === true) {

            funnelStep(2);
            // Enviar mensagem para GTM
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "Login",
              loginMessage: "Sucesso",
              userType: this.props.user.userType,
              userID: this.props.user.userID
            });

            CrossDomainEventHandler.sendEvent();

            //Se antes estavamos no checkout e viemos para aqui por causa do login
            //mandamos de volta para o checkout apos fazer login
            if (this.props.previousInCheckout === true) {
              window.location.href = "/shop/checkout";
            } else {
              window.location.href = "/Loja";
            }

          } else {
            container.clear();
            container.error(`${data.message}`);

            // Enviar mensagem para GTM
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "Login",
              loginMessage: `Erro - ${data.message}`
            });
          }
        });

      //Se a pessoa não tiver selecionado o captcha, fizemos restart ao captcha
    } else {
      // Enviar mensagem para GTM
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "Login",
        loginMessage: "Tem a certeza que preencheu todos os dados?"
      });

      loadReCaptcha();
    }
  };

  //Método para verificarmos o callback
  verifyCallback(response) {

    if (response) {
      this.props.dispatch(isVerifiedRecaptcha(true));
      this.setState({
        isVerified: true
      });
    } else {
      this.props.dispatch(isVerifiedRecaptcha(false));
      this.setState({
        isVerified: false
      });
    }
  }

  //Quando o recaptcha expirar, colocamos que a pessoa ainda não fez login
  recaptchaExpired() {
    this.props.dispatch(isVerifiedRecaptcha(false));
    this.setState({
      isVerified: false
    });
  }

  //Método para fazer load ao recaptch
  onLoadRecaptcha() {
    loadReCaptcha();
  }

  //Lidar com as alterçaões no campo email
  handleChangeEmail(event) {
    this.setState({
      email: event.target.value
    });
  }

  //Lidar com as alterçaões no campo password
  handleChangePassword(event) {
    this.setState({
      password: event.target.value
    });
  }

  handleKeyPress(target) {
    //Se a pessoa der enter
    if (target.charCode === 13) {

      //Se o captch estiver verificada
      if (this.props.isVerified) {
        ReactGA.event({
          category: "Login",
          action: "Click no Botão"
        });
        this.props
          .dispatch(login(this.state.email, this.state.password))
          .then(data => {
            if (data.message) {
              ReactGA.event({
                category: "Login",
                action: "Mensagem de erro",
                label: data.message
              });
              container.clear();
              container.warning(`${data.message}`);
            } else {
              ReactGA.event({
                category: "Login",
                action: "Sucesso"
              });

              CrossDomainEventHandler.sendEvent();

              //Se antes estavamos no checkout e viemos para aqui por causa do login
              //mandamos de volta para o checkout apos fazer login
              if (this.props.previousInCheckout === true) {
                window.location.href = "/shop/checkout";
              } else {
                window.location.href = "/Loja";
              }

            }
          });

        //Se o captch não estiver verificada
      } else {
        loadReCaptcha();
      }
    }
  }

  //Método de fazer login facebook
  loginFacebook() {
    loginWithFacebook(this.state.facebookData.email, this.state.facebookData.id, this.state.facebookData.accessToken)
      .then(result => {

        //Se o pedido tiver sido efetuado com erro
        if (result.success !== true) {
          container.clear();
          container.warning(`${result.message}`);

          // Enviar mensagem para GTM
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "Login",
            loginMessage: `Erro - ${result.message}`
          });

          //Se pedido efetuado com sucesso 
        } else {

          //Se utilizador está registado
          if (result.obj.isUserRegistered === true) {

            funnelStep(2);
            // Enviar mensagem para GTM
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "Login",
              loginMessage: "Sucesso",
              userType: this.props.user.userType,
              userID: this.props.user.userID
            });

            CrossDomainEventHandler.sendEvent();

            //Se antes estavamos no checkout e viemos para aqui por causa do login
            //mandamos de volta para o checkout apos fazer login
            if (this.props.previousInCheckout === true) {
              window.location.href = "/shop/checkout";
            } else {
              window.location.href = "/Loja";
            }

            //Se utilizador não registado
          } else {

            //Mandamos as props do facebook para a página de registar
            var pageType = {
              pathname: '/myihtp/create',
              state: {
                facebookData: {
                  id: this.state.facebookData.id,
                  name: this.state.facebookData.name,
                  email: this.state.facebookData.email,
                  accessToken: this.state.facebookData.accessToken
                }
              }
            }
            this.props.history.push(pageType);
          }
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  //Método de resposta de entrar com facebook
  loginFacebookResponse(response) {
    try {
      if (response) {

        this.setState(prevState => ({
          ...prevState,
          successFacebookCallback: true,
          facebookData: {
            id: response.id,
            name: response.name,
            email: response.email,
            accessToken: response.accessToken
          }
        }), () => {
          this.loginFacebook();
        });
      } else {
        this.setState(prevState => ({
          ...prevState,
          successFacebookCallback: false,
        }));
        container.clear();
        container.error(<FormattedMessage id="login.erro.dadosFacebook" />);
      }
    } catch (err) {
      container.clear();
      this.setState(prevState => ({
        ...prevState,
        successFacebookCallback: false,
      }));
      container.error(<FormattedMessage id="login.erro.dadosFacebook" />);
    }

  }

  //Método de erro ao tentar entrar com facebook
  loginFacebookFailure(error) {

    this.setState(prevState => ({
      ...prevState,
      successFacebookCallback: false,
    }));

    if (error.status !== 'unknown') {
      container.clear();
      container.error(<FormattedMessage id="login.erro.dadosFacebook" />);
    }
  }

  //Método de quando foi feito login com sucesso pelo google
  loginGoogleSuccess(response) {
    try {
      var obj = response;
      this.setState(prevState => ({
        ...prevState,
        googleData: {
          code: obj.code
        },
        successGoogleCallback: true,
      }), () => {
        this.loginGoogle();
      });
    } catch (err) {
      container.clear();
      container.error(<FormattedMessage id="login.erro.google" />);
    }

  }

  //Método de quando existe um erro ao fazer login com Google
  loginGoogleFailure(response) {

    this.setState(prevState => ({
      ...prevState,
      googleError: true
    }));

    if (response.error === "idpiframe_initialization_failed") {
      this.setState(prevState => ({
        ...prevState,
        googleButtonDisabled: true,
        googleSetupErrorMessage: <FormattedMessage id="login.ativarOpcaoCookies" />
      }));
    }

    if (response.error === "access_denied") {
      container.clear();
      container.error(<FormattedMessage id="login.erro.dadosGoogle" />);
    }
  }

  //Método de fazer login com Google
  loginGoogle() {
    loginWithGoogle(this.state.googleData.code)
      .then(result => {

        //Se o pedido tiver sido efetuado com erro
        if (result.success !== true) {
          container.clear();
          container.warning(`${result.message}`);

          // Enviar mensagem para GTM
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "Login",
            loginMessage: `Erro - ${result.message}`
          });

          //Se pedido efetuado com sucesso 
        } else {

          //Se utilizador está registado
          if (result.obj.isUserRegistered === true) {

            funnelStep(2);
            // Enviar mensagem para GTM
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "Login",
              loginMessage: "Sucesso",
              userType: this.props.user.userType,
              userID: this.props.user.userID
            });

            CrossDomainEventHandler.sendEvent();

            //Se antes estavamos no checkout e viemos para aqui por causa do login
            //mandamos de volta para o checkout apos fazer login
            if (this.props.previousInCheckout === true) {
              window.location.href = "/shop/checkout";
            } else {
              window.location.href = "/Loja";
            }

            //Se utilizador não registado
          } else {

            //Mandamos as props do Google para a página de registar
            var pageType = {
              pathname: '/myihtp/create',
              state: {
                googleData: {
                  email: result.obj.googleInfo.email,
                  name: result.obj.googleInfo.name,
                  accessToken: result.obj.googleInfo.accessToken,
                  refreshToken: result.obj.googleInfo.refreshToken,
                  idToken: result.obj.googleInfo.idToken,
                }
              }
            }
            this.props.history.push(pageType);

          }
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  render() {
    return (
      <Row>
        <Col xs="12" sm="12" md="12" lg="12">
          <ToastContainer
            ref={ref => (container = ref)}
            className="toast-bottom-right"
          />

          <Row className="loginTitleParent">
            <Col>
              <div className="loginTitle">
                <FormattedMessage id="login.titulo" />
              </div>
            </Col>
          </Row>

          {/* Input do email*/}
          <Row style={{ marginBottom: "8px" }}>
            <Col md={3} lg={4} />
            <Col xs={12} sm={12} md={6} lg={4}>
              <TextField
                id="email"
                label={<FormattedMessage id="login.enderecoEmail" />}
                className="email textFieldFontStyle"
                value={this.state.email}
                onChange={this.handleChangeEmail}
                type="email"
                onKeyPress={this.handleKeyPress}
                style={{ maxWidth: isMobile ? "100%" : "100%" }}
                fullWidth={true}
              />
            </Col>
          </Row>

          {/* Input de colocar password*/}
          <Row>
            <Col md={3} lg={4} />
            <Col xs={12} sm={12} md={6} lg={4}>
              <TextField
                id="password"
                label={<FormattedMessage id="login.palavraPasse" />}
                className="password textFieldFontStyle"
                value={this.state.password}
                onChange={this.handleChangePassword}
                type="password"
                onKeyPress={this.handleKeyPress}
                style={{ maxWidth: isMobile ? "100%" : "100%" }}
                fullWidth={true}
              />
            </Col>
          </Row>
          <br />

          {/* Captcha */}
          <Row>
            <Col md={3} lg={4} />
            <Col xs={12} sm={12} md={6} lg={4}>

              {this.state.recaptchaKey != null && (
                <ReCaptcha
                  ref={el => {
                    this.captchaDemo = el;
                  }}
                  // size="invisible"
                  render="explicit"
                  sitekey={this.state.recaptchaKey}
                  onloadCallback={this.onLoadRecaptcha}
                  verifyCallback={this.verifyCallback}
                  expiredCallback={this.recaptchaExpired}
                />
              )}
            </Col>
          </Row>
          <br />

          {/* Botão de entrar*/}
          <Row>
            <Col md={3} lg={4} />
            <Col xs={12} sm={12} md={6} lg={4}>
              {this.props.loading ? (
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#CA006A",
                    color: "white",
                    width: isMobile ? "100%" : "100%",
                    fontFamily: "Montserrat"
                  }}
                  onClick={this.loginAccount}
                >
                  <CircularProgress size={24} className="buttonProgress" />
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor: "#CA006A",
                    color: "white",
                    width: isMobile ? "100%" : "100%",
                    fontFamily: "Montserrat",
                    fontWeight: "500"
                  }}
                  onClick={this.loginAccount}
                >
                  <FormattedMessage id="login.entrar" />
                </Button>
              )}
            </Col>
          </Row>

          <br />

          {/* Recuperar Palavra Passe */}
          <Row>
            <Col md={3} lg={4} />
            <Col xs={12} sm={12} md={6} lg={4} style={{ textAlign: "right " }}>
              <NavLink to={Routes.account.recuperar}>
                <FormattedMessage id="login.recuperarPassword" />
              </NavLink>
            </Col>
          </Row>

          {/* Por favor registe-se*/}
          <Row>
            <Col md={3} lg={4} />
            <Col xs={12} sm={12} md={6} lg={4} style={{ textAlign: "right" }}>
              <NavLink to={Routes.account.registo}>
                <FormattedMessage id="login.naoTemConta" />
              </NavLink>
            </Col>
          </Row>

          <hr />
          <br />

          {/* Botão de entrar com facebook*/}
          <Row style={{ display: "flex", alignItems: "center", alignContent: "center" }}>
            <Col md={6} className="colsFBButton">
              {this.state.facebookAppId != null ? (
                <FacebookLogin
                  autoLoad={false}
                  appId={this.state.facebookAppId}
                  fields="name,email,picture"
                  callback={this.loginFacebookResponse}
                  onFailure={this.loginFacebookFailure}
                  cssClass="facebookLoginButton"
                  disableMobileRedirect={true}
                  icon={<FaFacebookF />}
                  textButton={<FormattedMessage id="login.entrarFacebook" />}
                />) : null}
            </Col>

            <Col md={6} className="colsGButton">

              {this.state.googleAppId != null ? (
                <GoogleLogin
                  clientId={this.state.googleAppId}
                  onSuccess={this.loginGoogleSuccess}
                  onFailure={this.loginGoogleFailure}
                  isSignedIn={false}
                  uxMode={"popup"}
                  disabled={this.state.googleButtonDisabled}
                  responseType={"code"}
                  accessType={"offline"}
                  prompt={"consent"}
                  render={renderProps => (
                    <button className="googleLoginButton" onClick={renderProps.onClick} disabled={renderProps.disabled}><FcGoogle /> <FormattedMessage id="login.entrarGoogle" /></button>
                  )}
                />)
                : null}
            </Col>
          </Row>

          {this.state.googleSetupErrorMessage.length > 0 ?
            <Row style={{ marginTop: "25px" }}>
              <Col>
                <label style={{ fontSize: "15px" }}>{this.state.googleSetupErrorMessage}</label>
              </Col>
            </Row>
            : null}

        </Col>
      </Row >
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    user: state.user,
    loading: state.loading
  };
};

export default connect(mapStateToProps)(Login);
