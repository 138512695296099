// Obter detalhes de produtos
import { isNumber } from "lodash";
import ReactGA from "react-ga";

export function sendUserID(id) {
    return new Promise((resolve, reject) => {
        return fetch("/sendUserID", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                UserID: id
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                return json.product;
            })
            .catch(error => console.log(error));
    });
}

// Obter detalhes de produtos
export function getProductDetails(id, tipo) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return fetch("/products/productDetails", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    id: id,
                    tipo: tipo
                })
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {

                    // Para remover o loading da página
                    dispatch(loading(false));

                    if (json.success === true) {

                        let product = null;

                        if (json.obj != null && json.obj[0]) {
                            product = json.obj[0];
                            product.tipo = tipo;
                        }
                        dispatch(fetchProductDetails(product));
                    }

                    resolve(json);
                    return json;
                })
                .catch(error => {
                    console.log(error.message);
                    dispatch(fetchCartFailure(error))
                });
        });
    };
}

// Login
export function login(email, password) {
    return (dispatch, getState) => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            if (true) {
                return fetch("/user/login", {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    credentials: "include",
                    body: JSON.stringify({
                        email: email,
                        password: password
                    })
                })
                    .then(handleErrors)
                    .then(res => res.json())
                    .then(json => {

                        if (json.obj != null && typeof json.obj.user !== 'undefined') {
                            ReactGA.set({ userId: json.obj.user.ID_Cliente });
                            ReactGA.set({ dimension1: json.obj.user.ID_TipoCliente });
                            dispatch(loginAccount(json.obj.user));
                            dispatch(loading(false));
                            resolve(json);
                        } else {
                            resolve(json);
                            dispatch(loading(false));
                        }
                    })
                    .catch(error => {
                        resolve({
                            success: false,
                            message: "Erro ao conectar ao servidor"
                        })
                    });
            }
        });
    };
}

export function recoverPassword(email) {
    return (dispatch, getState) => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            if (true) {
                return fetch("/user/recoverPassword", {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    credentials: "include",
                    body: JSON.stringify({
                        email: email
                    })
                })
                    .then(handleErrors)
                    .then(res => res.json())
                    .then(json => {
                        resolve(json);
                        dispatch(loading(false));
                    })
                    .catch(error => {

                        console.log(error.message);
                        resolve({
                            success: false,
                            message: "Erro ao conectar ao servidor"
                        })

                        dispatch(loading(false));
                    });
            }
        });
    };
}

export function logout() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/logout", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include"
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {
                    resolve({});
                    dispatch(logoutAccount());
                })
                .catch(error => console.log(error));
        });
    };
}

export function register(novoUtilizador) {
    return (dispatch, getState) => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            return fetch("/user/validateRegister", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    NomeCompleto: novoUtilizador.nomeCompleto,
                    Email: novoUtilizador.email,
                    EmailRecomendacao: novoUtilizador.emailRecomendacao,
                    Telefone: novoUtilizador.telefone,
                    DataNascimento: novoUtilizador.dataNascimento,
                    Pais: novoUtilizador.pais,
                    Localidade: novoUtilizador.localidade,
                    Refer: novoUtilizador.refer,
                    GDPR: novoUtilizador.gdpr
                })
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {
                    dispatch(registerAccount());
                    resolve({ message: json.message });
                    dispatch(loading(false));
                    return json;
                })
                .catch(error => console.log(error));
        });
    };
}

export function verifyEmail(verifyUser) {
    return (dispatch, getState) => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            return fetch("/user/setPassword", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    Link: verifyUser.link,
                    Password: verifyUser.password,
                    ConfirmPassword: verifyUser.confirmPassword
                })
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {
                    dispatch(loading(false));
                    resolve(json);
                    return json.user;
                })
                .catch(error => {
                    dispatch(loading(false));
                    resolve({ success: false, message: "Erro ao comunicar com o servidor, por favor tente mais tarde" });
                });
        });
    };
}

export function changePassword(changeUserPassword) {
    return (dispatch, getState) => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            return fetch("/user/changePassword", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    Link: changeUserPassword.link,
                    Password: changeUserPassword.password,
                    ConfirmPassword: changeUserPassword.confirmPassword
                })
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {
                    dispatch(loading(false));
                    resolve(json);
                    return json;
                })
                .catch(error => {
                    var response = { success: false, message: "Erro ao comunicar com Servidor para Definir Nova Palavra-Passe!" }
                    resolve(response);
                    return response;
                });
        });
    };
}

export function getUser() {
    return new Promise((resolve, reject) => {
        return fetch("/get/user", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                resolve(json.user);
            })
            .catch(error => console.log(error));
    });
}

// Dados de utilizador
export function fetchUser() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/get/user", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include"
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {
                    dispatch(loginAccount(json.user));
                    resolve({ user: json.user });
                    return json.user;
                })
                .catch(error => dispatch(fetchCartFailure(error)));
        });
    };
}

// Dados de utilizador
export function fetchCurrencySelected() {
    return new Promise((resolve, reject) => {
        return fetch("/geral/getAvailableCurrency", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                resolve(json.currencies);
            })
            .catch(error => console.log(error));
    });
}

export function fetchCart() {
    return dispatch => {
        dispatch(loadingCart(true));
        dispatch(fetchCartFailure(null));
        return new Promise((resolve, reject) => {

            return fetch("/user/getCart", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include"
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {
                    dispatch(loadingCart(false));

                    if (json.success === true) {
                        dispatch(fetchCartSuccess(json.obj));
                        dispatch(fetchCartNumber(json.obj.Itens));
                    } else {
                        dispatch(fetchCartFailure(json.message));
                    }

                    resolve(json);
                })
                .catch(error => {
                    dispatch(fetchCartFailure("Erro ao comunicar com o servidor para obter Carrinho."));
                    resolve({ success: false, message: "Erro ao comunicar com o servidor para obter Carrinho." });
                });
        });
    };
}

export function fetchCartNumberItems() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return fetch("/user/getCart", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include"
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {

                    //Se conseguirmos fazer o pedido com sucesso
                    if (json.success === true) {

                        dispatch(fetchCartNumber(json.obj.Itens));
                    } else {
                        dispatch(fetchCartFailure(json.message));
                    }

                    resolve(json);
                    return json;
                })
                .catch(error => {
                    resolve({
                        success: false,
                        message: "Erro ao comunicar com o servidor para ir buscar os Produtos do Carrinho"
                    });
                    dispatch(fetchCartFailure("Erro ao comunicar com o servidor para ir buscar os Produtos do Carrinho"));
                });
        });
    };
}

export function updateCart() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return fetch("/user/getCart", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include"
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {

                    if (json.success === true) {
                        resolve(json.obj);
                        dispatch(INCREMENTAR_PRODUCT_CART(json.obj));
                    } else {
                        dispatch(fetchCartFailure(json.message));
                    }

                    return json;
                })
                .catch(error => {

                    console.log(error.message);
                    resolve({
                        success: false,
                        message: "Erro ao carregar os produtos do Carrinho"
                    })
                    dispatch(fetchCartFailure("Erro ao carregar os produtos do Carrinho"));
                });
        });
    };
}

// Incrementar produto
export function incrementarProdutoCart(id) {
    return dispatch => {
        return fetch("/user/addItemCart", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                ID_Produto: parseInt(id),
                quantity: 1
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchCartNumberItems());
                return json.cart;
            })
            .catch(error => console.log(error));
    };
}

// Incrementar produto
export function incrementarPackCart(id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return fetch("/user/addPackCart", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    ID_Pack: parseInt(id),
                    quantity: 1
                })
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {
                    dispatch(fetchCartNumberItems());
                    return json.cart;
                })
                .catch(error => console.log(error));
        });
    };
}

// Decrementar produto
export function decrementPackCart(id) {
    return dispatch => {
        return fetch("/user/decrementPackCart", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                ID_Pack: parseInt(id)
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchCart());
                return json.message;
            })
            .catch(error => console.log(error));
    };
}

// Remover produto
export function removerProdutoCart(id) {
    return dispatch => {
        return fetch("/user/removeItemCart", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                ID_Produto: parseInt(id)
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchCart());
                dispatch(verifyOrder());
                return json.cart;
            })
            .catch(error => console.log(error));
    };
}

// Remover produto
export function removerPackCart(id) {
    return dispatch => {
        return fetch("/user/removePackCart", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                ID_Pack: parseInt(id)
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchCart());
                dispatch(verifyOrder());
                return json.cart;
            })
            .catch(error => console.log(error));
    };
}

// Remover Todos os produtos do carrinho
export function clearCart() {
    return dispatch => {
        return fetch("/user/clearCart", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchCart());
                return json.message;
            })
            .catch(error => console.log(error));
    };
}

export function incrementProductCart(id) {
    return dispatch => {
        return fetch("/user/addItemCart", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                ID_Produto: parseInt(id)
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchCart());
                return json.message;
            })
            .catch(error => console.log(error));
    };
}

export function incrementPackCart(id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return fetch("/user/addPackCart", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    ID_Pack: parseInt(id),
                    quantity: 1
                })
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {
                    dispatch(fetchCart());
                    return json.cart;
                })
                .catch(error => console.log(error));
        });
    };
}

export function decrementProductCart(id) {
    return dispatch => {
        return fetch("/user/decrementItemCart", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                ID_Produto: parseInt(id)
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchCart());
                return json.message;
            })
            .catch(error => console.log(error));
    };
}

export function updateProductQuantityCart(productId, quantity) {
    return dispatch => {
        return fetch("/user/updateProductQuantityInCart", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                ProductId: parseInt(productId),
                Quantity: quantity
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchCart());
                return json;
            })
            .catch(error => {
                return ({
                    success: false,
                    message: "Erro inesperado ao atualizar quantidade do Produto no Carrinho!"
                })
            });
    };
}

export function updatePackQuantityCart(packId, quantity) {
    return dispatch => {
        return fetch("/user/updatePackQuantityInCart", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                PackId: parseInt(packId),
                Quantity: quantity
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchCart());
                return json;
            })
            .catch(error => {
                return ({
                    success: false,
                    message: "Erro inesperado ao atualizar quantidade do Pack no Carrinho!"
                })
            });
    };
}

// Obter idiomas
// Obsoleto
export function fetchLanguageFunction() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/geral/getLanguages", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include"
            })
                .then(res => res.json())
                .then(json => {
                    dispatch(changeLanguage(json.languages));
                    // resolve({ data: json });
                })
                .catch(error => console.log(error));
        });
    };
}

// Mudar idioma
// Obsoleto
export function changeLanguageFE(id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return fetch("/geral/chooseLanguage", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ id: id })
            })
                .then(res => res.json())
                .then(json => {

                    dispatch(changeLanguage(json.languages));
                    resolve(json.languages);
                })
                .catch(error => {
                    console.log(error)
                    resolve([])
                });
        });
    };
}

export function setLoadingAllPageContent(value) {
    return dispatch => {
        dispatch(setLoadingAllPage(value));
    }
}

// Obter moedas
export function fetchCurrencyFunction() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/geral/getAvailableCurrency", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include"
            })
                .then(res => res.json())
                .then(json => {
                    dispatch(changeCurrency(json.currencies));
                    // dispatch(fetchCart());
                    return json.currencies;
                })
                .catch(error => console.log(error));
        });
    };
}

export function changeCurrencyFE(id) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/geral/chooseCurrency", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ id: id })
            })
                .then(res => res.json())
                .then(json => {

                    //Aqui para atualizar os preços das coisas no carrinho
                    dispatch(fetchCart());

                    //Método para ir buscar os produtos quando estamos na listagem de produtos
                    dispatch(getProducts(getState().categories.selected));

                    //Método para ir buscar os produtos quando estamos na parte do produto individual   
                    if (getState().product != null && typeof getState().product.ID_Produto !== 'undefined') {
                        dispatch(getProductDetails(getState().product.ID_Produto, getState().product.tipo));
                    }

                    dispatch(changeCurrency(json.currencies));
                    dispatch(sideBarMenu(false));

                    return json.currencies;
                })
                .catch(error => console.log(error));
        });
    };
}

export function sideBarMenuChangeState(value) {
    return (dispatch, getState) => {
        dispatch(sideBarMenu(value));
    };
}

export function changeCategoryFE(id) {
    return dispatch => {
        dispatch(loading(true));
        return fetch("/categories/getAll", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({ id: id })
        })
            .then(res => res.json())
            .then(json => {
                dispatch(fetchCategories(json.categories));
                return json.categories;
            })
            .catch(error => console.log(error));
    };
}

export function menuToggleGetState() {
    return (dispatch, getState) => {
        dispatch(menuToggle(!getState().menuToggleState));
    };
}

export function cartToggleGetState() {
    return (dispatch, getState) => {
        dispatch(cartToggle(!getState().cartToggleState));
    };
}

export function profileToggleGetState() {
    return (dispatch, getState) => {
        dispatch(profileToggle(!getState().profileToggleState));
    };
}

export function getProducts(category, pesquisa, orderField, orderAsc) {
    return dispatch => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            return fetch("/products/getAllByCategory", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    category: category,
                    pesquisa: pesquisa,
                    orderField: orderField,
                    orderAsc: orderAsc
                })
            })
                .then(res => res.json())
                .then(json => {
                    dispatch(fetchProducts(json.products));
                    dispatch(loading(false));
                    resolve({ products: json.products });
                    return json.products;
                })
                .catch(error => console.log(error));
        });
    };
}

export function setProductStockNotificationInProductList(productId, packId, notificationHash) {
    return (dispatch, getState) => {

        var productsObj = { ...getState().products };

        if (productsObj && productsObj != null) {
            var products = productsObj.produtos;
            if (products && products != null) {
                var p = products.find(p => (p.ID_Produto != null && p.ID_Produto == productId) || (p.ID_Pack != null && p.ID_Pack == packId));
                if (p && p != null) {
                    p.Notificacao = true;
                    p.NotificacaoHash = notificationHash;
                    dispatch(fetchProducts(productsObj));
                }
            }
        }
    };
}

//Remover o estado de notificação de um produto
export function removeProductStockNotificationInProductList(productId, packId) {
    return (dispatch, getState) => {
        var productsObj = { ...getState().products };

        if (productsObj && productsObj != null) {
            var products = productsObj.produtos;
            if (products && products != null) {
                var p = products.find(p => (p.ID_Produto != null && p.ID_Produto == productId) || (p.ID_Pack != null && p.ID_Pack == packId));
                if (p && p != null) {
                    p.Notificacao = false;
                    p.NotificacaoHash = null;
                    dispatch(fetchProducts(productsObj));
                }
            }
        }
    };
}

export function getCaterogyOrderByFields(category) {
    return new Promise((resolve, reject) => {
        return fetch("/products/getCaterogyOrderByFields", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                ID_TipoProduto: category,
            })
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => console.log(error));
    });
}


export function getSearch(pesquisa) {
    return dispatch => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            return fetch("/products/getAllByCategory", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    pesquisa: pesquisa
                })
            })
                .then(res => res.json())
                .then(json => {
                    dispatch(fetchProducts(json.products));
                    dispatch(loading(false));
                    resolve({ products: json.products });
                    return json.products;
                })
                .catch(error => console.log(error));
        });
    };
}

export function getPaymentTypes() {
    return dispatch => {
        return fetch("/checkout/getPaymentMethods", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                json.paymentTypes.map(obj => {
                    obj.Selected = false;
                    return obj;
                });
                dispatch(fetchPaymentTypes(json.paymentTypes));
                return json.paymentTypes;
            })
            .catch(error => console.log(error));
    };
}

export function selectPaymentType(id, numOfInstallments, phoneNumber) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var paymentTypes = getState().paymentTypes.map(function (item) {
                if (item.ID_TipoPagamento === id) {
                    item.Selected = true;
                } else {
                    item.Selected = false;
                }
                return item;
            });
            var checkoutData = getState().checkoutData;
            checkoutData.selectPaymentTypes = id;

            dispatch(fetchPaymentTypes(paymentTypes));
            dispatch(selectedPaymentTypeFunction(id));
            dispatch(selectedPaymentInstallmentsFunction(numOfInstallments));
            dispatch(selectedPaymentPhoneNumberFunction(phoneNumber));
            dispatch(verifyOrder()).then(data => {
                if (data.json.checkoutData.MensagemErro) {
                    resolve({ error: data.json.checkoutData.MensagemErro });
                } else {
                    resolve();
                }
            });

        });
    };
}

export function getShipmentTypes() {
    return dispatch => {
        return fetch("/checkout/getDeliveryMethods", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                json.shipmentTypes.map(obj => {
                    obj.Selected = false;
                    return obj;
                });
                dispatch(fetchShipmentTypes(json.shipmentTypes));
                return json.shipmentTypes;
            })
            .catch(error => console.log(error));
    };
}

export function selectShipmentType(id) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var shipmentTypes = getState().shipmentTypes.map(function (item) {
                if (item.ID_TipoTransporte === id) {
                    item.Selected = true;
                } else {
                    item.Selected = false;
                }
                return item;
            });

            var checkoutData = getState().checkoutData;
            checkoutData.selectShipmentType = id;

            //dispatch(fetchShipmentTypes(shipmentTypes));
            dispatch(selectedShipmentTypeFunction(id));
            // TODO - Resolver este problema (08-01-2019), não aparece mensagem de erro
            dispatch(verifyOrder()).then(data => {
                if (data.json.checkoutData.MensagemErro) {
                    resolve({ error: data.json.checkoutData.MensagemErro });
                } else {
                    resolve();
                }
            });
        });
    };
}

export function getShippingAddresses() {
    return dispatch => {
        return fetch("/checkout/getDeliveryAddress", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                dispatch(fetchDeliveryAddresses(json.deliveryAddresses));
                return json.deliveryAddresses;
            })
            .catch(error => console.log(error));
    };
}

export function addNewShippingAddress(novaMorada) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/user/addAddress", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    ID_Tipo_Morada: "R",
                    Morada: novaMorada.morada,
                    Localidade: novaMorada.localidade,
                    Nome: novaMorada.nome,
                    CodigoPostal: novaMorada.codigoPostal,
                    Principal: novaMorada.principal,
                    ID_Pais: novaMorada.id_pais
                })
            })
                .then(res => res.json())
                .then(json => {
                    dispatch(getShippingAddresses());
                    resolve({ data: json });
                })
                .catch(error => console.log(error));
        });
    };
}

export function addNewBillingAddress(novaMorada) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/user/addAddress", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    ID_Tipo_Morada: "F",
                    Morada: novaMorada.morada,
                    Nome: novaMorada.nome,
                    Nif: novaMorada.nif,
                    Localidade: novaMorada.localidade,
                    CodigoPostal: novaMorada.codigoPostal,
                    Principal: novaMorada.principal,
                    ID_Pais: novaMorada.id_pais
                })
            })
                .then(res => res.json())
                .then(json => {
                    dispatch(getBillingAddresses());
                    resolve({ data: json });
                })
                .catch(error => console.log(error));
        });
    };
}

export function getBillingAddresses() {
    return dispatch => {
        return fetch("/checkout/getBillingAddress", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                dispatch(fetchBillingAddresses(json.billingAddresses));
                return json.billingAddresses;
            })
            .catch(error => console.log(error));
    };
}

export function addBillingAddressCheckout(id) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.billingAddress = id;
            dispatch(verifyOrder());
            resolve();
        });
    };
}

export function addObservacao(id) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.observacoes = id;
            dispatch(verifyOrder());
            resolve();
        });
    };
}

export function addShippingAddressCheckout(id) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.shippingAddress = id;
            dispatch(verifyOrder());
            resolve();
        });
    };
}

export function removeBillingAddress(id) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/user/removeAddress", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    ID_Cliente_Morada: id
                })
            })
                .then(res => res.json())
                .then(json => {
                    dispatch(getBillingAddresses());
                    resolve({ data: json });
                })
                .catch(error => console.log(error));
        });
    };
}

export function getAddress(id) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/user/getAddress", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    ID_Cliente_Morada: id
                })
            })
                .then(res => res.json())
                .then(json => {
                    resolve({ data: json });
                })
                .catch(error => console.log(error));
        });
    };
}

export function modifyBillingAddress(alterarMorada) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/user/updateAddress", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    ID_Cliente_Morada: alterarMorada.id_cliente_morada,
                    ID_Tipo_Morada: "F",
                    Nome: alterarMorada.nome,
                    Nif: alterarMorada.nif,
                    Morada: alterarMorada.morada,
                    Localidade: alterarMorada.localidade,
                    CodigoPostal: alterarMorada.codigoPostal,
                    Principal: alterarMorada.principal,
                    ID_Pais: alterarMorada.id_pais
                })
            })
                .then(res => res.json())
                .then(json => {
                    dispatch(getBillingAddresses());
                    resolve({ data: json });
                })
                .catch(error => console.log(error));
        });
    };
}

export function modifyShippingAddress(alterarMorada) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/user/updateAddress", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    ID_Cliente_Morada: alterarMorada.id_cliente_morada,
                    Nome: alterarMorada.nome,
                    Morada: alterarMorada.morada,
                    Localidade: alterarMorada.localidade,
                    CodigoPostal: alterarMorada.codigoPostal,
                    Principal: alterarMorada.principal,
                    ID_Pais: alterarMorada.id_pais,
                    ID_Tipo_Morada: alterarMorada.ID_Tipo_Morada
                })
            })
                .then(res => res.json())
                .then(json => {
                    dispatch(getShippingAddresses());
                    dispatch(verifyOrder());
                    resolve({ data: json });
                })
                .catch(error => console.log(error));
        });
    };
}

export function removeShippingAddress(id) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/user/removeAddress", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    ID_Cliente_Morada: id
                })
            })
                .then(res => res.json())
                .then(json => {
                    dispatch(getShippingAddresses()).then(result => {
                        resolve({ data: json, moradas: result });
                    }).catch(error => {
                        resolve({ data: json });
                    });

                })
                .catch(error => console.log(error));
        });
    };
}

export function selectPromotionalCode(promotionalCode) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.promotionalCode = promotionalCode;
            dispatch(verifyOrder()).then(data => {
                if (data.json.checkoutData.MensagemErro !== "") {
                    checkoutData.promotionalCode = "";
                    resolve({ error: data.json.checkoutData.MensagemErro });
                } else {
                    resolve({ success: "Código inserido com sucesso" });
                }
            });
        });
    };
}

export function selectCredits(credits) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.credits = credits;
            dispatch(verifyOrder()).then(data => {
                if (data.json.checkoutData.MensagemErro) {
                    resolve({ error: data.json.checkoutData.MensagemErro });
                } else {
                    resolve({ success: "Créditos adicionados com sucesso" });
                }
            });
        });
    };
}

export function selectVouchers(vouchers) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.vouchers = vouchers;
            dispatch(verifyOrder()).then(data => {
                if (data.json.checkoutData.MensagemErro) {
                    resolve({ error: data.json.checkoutData.MensagemErro });
                } else {
                    resolve({ success: "Código inserido com sucesso" });
                }
            });
        });
    };
}

export function selectPaymentPhoneNumber(phoneNumber) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(selectedPaymentPhoneNumberFunction(phoneNumber));
        });
    };
}

export function removeCredits() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.credits = 0;
            dispatch(verifyOrder()).then(data => {
                if (data.json.checkoutData.message) {
                    resolve({ error: data.json.checkoutData.message });
                } else {
                    resolve({ success: "Removido com sucesso" });
                }
            });
        });
    };
}

export function removeVouchers() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.vouchers = 0;
            dispatch(verifyOrder()).then(data => {
                if (data.json.checkoutData.message) {
                    resolve({ error: data.json.checkoutData.message });
                } else {
                    resolve({ success: "Removido com sucesso" });
                }
            });
        });
    };
}

export function removePromotionalCode() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.promotionalCode = null;
            dispatch(verifyOrder()).then(data => {
                if (data.json.checkoutData.message) {
                    resolve({ error: data.json.checkoutData.message });
                } else {
                    resolve({ success: "Removido com sucesso" });
                }
            });
        });
    };
}

export function verifyOrder() {
    return (dispatch, getState) => {
        dispatch(setLoadingCheckoutPrices(true));
        return new Promise((resolve, reject) => {
            return fetch("/checkout/verifyOrder", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    ID_ClienteMoradaEnvio: getState().checkoutData.shippingAddress,
                    ID_ClienteMoradaFatura: getState().checkoutData.billingAddress,
                    ID_TipoTransporte: getState().selectedShipmentType,
                    ID_TipoPagamento: getState().selectedPaymentType,
                    PromotionalCode: getState().checkoutData.promotionalCode,
                    Vouchers: getState().checkoutData.vouchers,
                    Credits: getState().checkoutData.credits,
                    Observacoes: getState().checkoutData.observacoes
                })
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {
                    json.checkoutData.vouchers = getState().checkoutData.vouchers;
                    json.checkoutData.credits = getState().checkoutData.credits;
                    json.checkoutData.selectShipmentType = getState().checkoutData.selectShipmentType;
                    json.checkoutData.selectPaymentTypes = getState().checkoutData.selectPaymentTypes;
                    json.checkoutData.billingAddress = getState().checkoutData.billingAddress;
                    json.checkoutData.shippingAddress = getState().checkoutData.shippingAddress;
                    json.checkoutData.confirmOrderClicked = getState().checkoutData.confirmOrderClicked;
                    json.checkoutData.observacoes = getState().checkoutData.Observacoes;
                    json.checkoutData.promotionalCode = getState().checkoutData.promotionalCode;

                    if (!json.checkoutData.message) {
                        dispatch(fetchCheckoutData(json.checkoutData));
                        dispatch(setLoadingCheckoutPrices(false));
                    }

                    resolve({ json });
                    return json.checkoutData;
                })
                .catch(error => console.log(error));
        });
    };
}

export function confirmOrder() {
    return (dispatch, getState) => {
        dispatch(loading(true));

        return new Promise((resolve, reject) => {
            return fetch("/checkout/confirmOrder", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    ID_ClienteMoradaEnvio: getState().checkoutData.shippingAddress,
                    ID_ClienteMoradaFatura: getState().checkoutData.billingAddress,
                    ID_TipoTransporte: getState().selectedShipmentType,
                    ID_TipoPagamento: getState().selectedPaymentType,
                    ID_Portes: getState().checkoutData.ID_Portes,
                    Observacoes: getState().checkoutData.Observacoes,
                    ChaveCodigoPromocional: getState().checkoutData.ChaveCodigoPromocional,
                    CodigoVale: getState().checkoutData.CodigoVale,
                    DescontoVale: getState().checkoutData.vouchers,
                    DescontoCreditos: getState().checkoutData.credits,
                    Parcelas: getState().selectedPaymentInstallments,
                    NumeroTelemovelPagamento: getState().selectedPaymentPhoneNumber,
                })
            })
                .then(res => res.json())
                .then(json => {
                    // dispatch(fetchCart());
                    // Encomenda processou com sucesso
                    dispatch(loading(false));
                    if (json.checkoutData) {
                        getState().checkoutData.confirmOrderClicked = true;
                        resolve(json.checkoutData);
                    }
                    // Encomenda não foi gerada com sucesso
                    else {
                        // ReactGA.exception({
                        //   description: json.message,
                        //   fatal: true
                        // });
                        // reject(new Error(json.message));
                    }
                });
        });
    };
}

export function paymentPaypalConfirmed(id_encomenda) {
    return (dispatch, getState) => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            return fetch("/payment/paypalConfirmed", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    orderID: id_encomenda
                })
            })
                .then(res => res.json())
                .then(json => {
                    resolve({ data: json });
                    dispatch(loading(false));
                })
                .catch(error => console.log(error));
        });
    };
}

export function getOrderPayment(hash) {
    return new Promise((resolve, reject) => {
        return fetch("/payment/getOrderPayment", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                hash: hash
            })
        })
            .then(res => res.json())
            .then(json => {
                resolve({ data: json });
            })
            .catch(error => console.log(error));
    });
}

export function paymentPaypalCanceled(id_encomenda) {
    return (dispatch, getState) => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            return fetch("/payment/paypalCanceled", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    orderID: id_encomenda
                })
            })
                .then(res => res.json())
                .then(json => {
                    resolve({ data: json });
                    dispatch(loading(false));
                })
                .catch(error => console.log(error));
        });
    };
}

export function getPaises() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/geral/getCountries", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include"
            })
                .then(res => res.json())
                .then(json => {
                    dispatch(allCountries(json.paises));
                    resolve({ data: json });
                })
                .catch(error => console.log(error));
        });
    };
}

//FUNCAO PARA RECOMENDAR PRODUTO
export function recomendarProduto(emails, id) {
    return new Promise((resolve, reject) => {
        return fetch("/products/recomendarProduto", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                emails: emails,
                id: id
            })
        })
            .then(res => res.json())
            .then(json => {
                resolve({ data: json });
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    });
}

//FUNCAO PARA RECOMENDAR I HAVE THE POWER
export function recomendarWebsite(emails) {
    return new Promise((resolve, reject) => {
        return fetch("/user/recomendarWebsite", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                emails: emails
            })
        })
            .then(res => res.json())
            .then(json => {
                resolve({ data: json });
            })
            .catch(error => {
                reject(error);
            });
    });
}

//FUNCAO PARA ALTERAR TIPO DE PAGAMENTO
export function changePayment(TipoPagamento, encomendaID, hashEncomenda, NumParcelas, NumeroTelemovelPagamento) {
    return new Promise((resolve, reject) => {
        return fetch("/payment/changePayment", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                TipoPagamento: TipoPagamento,
                encomendaID: encomendaID,
                hashEncomenda: hashEncomenda,
                NumParcelas: NumParcelas,
                NumeroTelemovelPagamento: NumeroTelemovelPagamento
            })
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    });
}

export function getEbanxCheckoutURL() {
    return new Promise((resolve, reject) => {
        return fetch("/ebanx/getCheckoutURL", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => console.log(error));
    });
}

export function createNoStockNotification(idProduto, idPack, email) {
    return new Promise((resolve, reject) => {
        return fetch("/products/createNoStockNotification", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ID_Produto: idProduto,
                ID_Pack: idPack,
                Email: email
            }),
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => {
                resolve({
                    success: false,
                    message: "Erro ao comunicar com o servidor para criar Notificação de Aviso"
                })
            });
    })
}

export function removeStockNotification(hash) {
    return new Promise((resolve, reject) => {
        return fetch("/products/removeStockNotification", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                Hash: hash
            }),
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    });
}

export function removePersonalStockNotification(hash) {
    return new Promise((resolve, reject) => {
        return fetch("/products/removePersonalStockNotification", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                Hash: hash
            }),
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    });
}

//Método que retorna se os créditos estão a ser usados no checkout
export function areCheckoutCreditsUsed() {
    return (dispatch, getState) => {

        var credits = getState().checkoutData.credits;
        var areCreditsUsed = (typeof credits != 'undefined' && credits != null && isNumber(credits) && credits > 0);
        return {
            areCreditsUsed,
            credits
        };
    }
}

//Método que retorna se os vouchers estão a ser usados no checkout
export function areCheckoutVouchersUsed() {
    return (dispatch, getState) => {

        var vouchers = getState().checkoutData.vouchers;
        var areVouchersUsed = (typeof vouchers != 'undefined' && vouchers != null && isNumber(vouchers) && vouchers > 0);
        return {
            areVouchersUsed,
            vouchers
        };
    }
}

//Método que atualiza apenas o valor dos créditos
export function onlyUpdateCredits(credits) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.credits = credits;
            resolve();
        });
    };
}

//Método que atualiza apenas o valor dos vouchers
export function onlyUpdateVouchers(vouchers) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.vouchers = vouchers;
            resolve();
        });
    };
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export const LOADING = "LOADING";
export const GETCART = "GETCART";
export const LOADINGCART = "LOADINGCART";
export const ISVERIFIEDRECAPTCHA = "ISVERIFIEDRECAPTCHA";
export const SIDEBARMENU = "SIDEBARMENU";

export const FETCH_CART_NUMBER_ITEMS = "FETCH_CART_NUMBER_ITEMS";

export const CHECKOUT_PANEL_BILLING_ADDRESS = "CHECKOUT_PANEL_BILLING_ADDRESS";
export const CHECKOUT_PANEL_SHIPPING_ADDRESS =
    "CHECKOUT_PANEL_SHIPPING_ADDRESS";
export const CHECKOUT_PANEL_SHIPPING_METHOD = "CHECKOUT_PANEL_SHIPPING_METHOD";
export const CHECKOUT_PANEL_PAYMENT_METHOD = "CHECKOUT_PANEL_PAYMENT_METHOD";

export const FETCH_PRODUCTS_DETAILS = "FETCH_PRODUCTS_DETAILS";

export const LOGIN = "LOGIN";

export const GETUSER = "GETUSER";

export const FETCH_CART_BEGIN = "FETCH_CART_BEGIN";
export const FETCH_CART_SUCCESS = "FETCH_CART_SUCCESS";
export const FETCH_CART_FAILURE = "FETCH_CART_FAILURE";

export const INCREMENTAR_PRODUCT_CART = "INCREMENTAR_PRODUCT_CART";
export const DECREMENTAR_PRODUCT_CART = "DECREMENTAR_PRODUCT_CART";
export const REMOVER_PRODUCT_CART = "REMOVER_PRODUCT_CART";
export const LIMPAR_CART = "LIMPAR_CART";

export const FETCH_LANGUAGE = "FETCH_LANGUAGE";
export const FETCH_CURRENCY = "FETCH_CURRENCY";
export const FETCH_PRODUCTS_FILTERS = "FETCH_PRODUCTSFILTERS";

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CHANGE_CURRENCY = "CHANGE_CURRENCY";
export const CHANGE_FILTER = "CHANGE_FILTER";

export const FETCH_CATEGORIES = "FETCH_CATEGORIES";

export const FETCH_PRODUCTS = "FETCH_PRODUCTS";

export const MENU_TOGGLE = "MENU_TOGGLE";

export const CART_TOGGLE = "CART_TOGGLE";

export const PROFILE_TOGGLE = "PROFILE_TOGGLE";

export const FETCH_PAYMENT_TYPES = "FETCH_PAYMENT_TYPES";
export const FETCH_SHIPMENT_TYPES = "FETCH_SHIPMENT_TYPES";

export const SELECT_SHIPMENT_TYPE = "SELECT_SHIPMENT_TYPE";
export const SELECT_PAYMENT_TYPE = "SELECT_PAYMENT_TYPE";
export const SELECT_PAYMENT_INSTALLMENTS = "SELECT_PAYMENT_INSTALLMENTS";
export const SELECT_PAYMENT_PHONE_NUMBER = "SELECT_PAYMENT_PHONE_NUMBER";

export const FETCH_DELIVERY_ADDRESSES = "FETCH_DELIVERY_ADDRESSES";
export const FETCH_BILLING_ADDRESSES = "FETCH_BILLING_ADDRESSES";

export const FETCH_CHECKOUT_DATA = "FETCH_CHECKOUT_DATA";

export const LOGOUT = "LOGOUT";

export const REGISTER = "REGISTER";

export const sideBarMenu = sideBarMenuState => ({
    type: SIDEBARMENU,
    payload: { sideBarMenuState }
});

export const isVerifiedRecaptcha = isVerifiedState => ({
    type: ISVERIFIEDRECAPTCHA,
    payload: { isVerifiedState }
});

export const loading = loadingState => ({
    type: LOADING,
    payload: { loadingState }
});

export const loadingCart = loadingCartState => ({
    type: LOADINGCART,
    payload: { loadingCartState }
});

export const getCart = getCartState => ({
    type: GETCART,
    payload: { getCartState }
});

export const checkoutPanelBillingAddress = panelBillingState => ({
    type: CHECKOUT_PANEL_BILLING_ADDRESS,
    payload: { panelBillingState }
});

export const checkoutPanelShippingAddress = panelShippingAddressState => ({
    type: CHECKOUT_PANEL_SHIPPING_ADDRESS,
    payload: { panelShippingAddressState }
});

export const checkoutPanelShippingMethod = panelShippingMethodState => ({
    type: CHECKOUT_PANEL_SHIPPING_METHOD,
    payload: { panelShippingMethodState }
});

export const checkoutPanelPaymentMethod = panelPaymentMethodState => ({
    type: CHECKOUT_PANEL_PAYMENT_METHOD,
    payload: { panelPaymentMethodState }
});

export const fetchProductDetails = product => ({
    type: FETCH_PRODUCTS_DETAILS,
    payload: { product }
});

export const loginAccount = user => ({
    type: LOGIN,
    payload: { user }
});

export const getUserFetch = user => ({
    type: GETUSER,
    payload: { user }
});

export const logoutAccount = () => ({
    type: LOGOUT
});

export const registerAccount = () => ({
    type: REGISTER
});

export const fetchCartSuccess = cartSuccess => ({
    type: FETCH_CART_SUCCESS,
    payload: { cartSuccess }
});

export const fetchCartNumber = cartNumberItems => ({
    type: FETCH_CART_NUMBER_ITEMS,
    payload: { cartNumberItems }
});

export const fetchCartFailure = cartLoadError => ({
    type: FETCH_CART_FAILURE,
    payload: { cartLoadError }
});


export const decrementarProduto = cart => ({
    type: DECREMENTAR_PRODUCT_CART,
    payload: { cart }
});

export const removerProduto = cart => ({
    type: REMOVER_PRODUCT_CART,
    payload: { cart }
});

export const fetchLanguage = language => ({
    type: FETCH_LANGUAGE,
    payload: { language }
});

export const fetchCurrency = currency => ({
    type: FETCH_CURRENCY,
    payload: { currency }
});

export const fetchProductsFilters = filters => ({
    type: FETCH_PRODUCTS_FILTERS,
    payload: { filters }
});

export const changeLanguage = language => ({
    type: CHANGE_LANGUAGE,
    payload: { language }
});

export const changeCurrency = currency => ({
    type: CHANGE_CURRENCY,
    payload: { currency }
});

export const changeFilter = filters => ({
    type: CHANGE_FILTER,
    payload: { filters }
});
export const fetchCategories = categories => ({
    type: FETCH_CATEGORIES,
    payload: { categories }
});

export const fetchProducts = products => ({
    type: FETCH_PRODUCTS,
    payload: { products }
});

export const menuToggle = menuToggleState => ({
    type: MENU_TOGGLE,
    payload: { menuToggleState }
});

export const cartToggle = cartToggleState => ({
    type: CART_TOGGLE,
    payload: { cartToggleState }
});

export const profileToggle = profileToggleState => ({
    type: PROFILE_TOGGLE,
    payload: { profileToggleState }
});

export const fetchPaymentTypes = paymentTypes => ({
    type: FETCH_PAYMENT_TYPES,
    payload: { paymentTypes }
});

export const fetchShipmentTypes = shipmentTypes => ({
    type: FETCH_SHIPMENT_TYPES,
    payload: { shipmentTypes }
});

export const selectedPaymentTypeFunction = selectedPaymentType => ({
    type: SELECT_PAYMENT_TYPE,
    payload: { selectedPaymentType }
});

export const selectedPaymentInstallmentsFunction = selectedPaymentInstallments => ({
    type: SELECT_PAYMENT_INSTALLMENTS,
    payload: { selectedPaymentInstallments }
});

export const selectedPaymentPhoneNumberFunction = selectedPaymentPhoneNumber => ({
    type: SELECT_PAYMENT_PHONE_NUMBER,
    payload: { selectedPaymentPhoneNumber }
});

export const selectedShipmentTypeFunction = selectedShipmentType => ({
    type: SELECT_SHIPMENT_TYPE,
    payload: { selectedShipmentType }
});

export const fetchDeliveryAddresses = deliveryAddresses => ({
    type: FETCH_DELIVERY_ADDRESSES,
    payload: { deliveryAddresses }
});

export const fetchBillingAddresses = billingAddresses => ({
    type: FETCH_BILLING_ADDRESSES,
    payload: { billingAddresses }
});

export const fetchCheckoutData = checkoutData => ({
    type: FETCH_CHECKOUT_DATA,
    payload: { checkoutData }
});


export const ALL_COUNTRIES = "ALL_COUNTRIES";
export const allCountries = allPaises => ({
    type: ALL_COUNTRIES,
    payload: { allPaises }
});

export const LOADING_CHECKOUT_PRICES = "LOADING_CHECKOUT_PRICES";
export const setLoadingCheckoutPrices = loadingCheckoutPrices => ({
    type: LOADING_CHECKOUT_PRICES,
    payload: { loadingCheckoutPrices }
})

export const LOADING_ALL_PAGE = "LOADING_ALL_PAGE";
export const setLoadingAllPage = loadingAllPage => ({
    type: LOADING_ALL_PAGE,
    payload: { loadingAllPage }
})