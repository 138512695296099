import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastr";
import { confirmOrder, removeCredits, removePromotionalCode, removeVouchers, verifyOrder } from "../../../actions/miniCartActions";
import { getOrderDetails } from "../../../actions/myihtpActions";
import { checkout_Encomendar, funnelStep } from "../../../Analytics/DataLayer";
import Loading from "../Loading/Loading";
import Skeleton from '@material-ui/lab/Skeleton';

let container;

class Total extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paypal: false,
      buttonCheckout: false,
      paymentLoading: false
    };
  }
  componentDidMount() {
    this.props.dispatch(verifyOrder()).then(data => {
      if (data.message !== undefined) {
        container.clear();
        container.warning(`${data.message}`);
      }
    });
  }

  removeCredits = async e => {
    e.stopPropagation();
    this.props.dispatch(removeCredits()).then(data => {
      if (data.message !== undefined) {
        container.clear();
        container.warning(`${data.message}`);
      }
    });
  };

  removeVouchers = async e => {
    e.stopPropagation();
    this.props.dispatch(removeVouchers()).then(data => {
      if (data.message !== undefined) {
        container.clear();
        container.warning(`${data.message}`);
      }
    });
  };

  removePromotionalCode = async e => {
    e.stopPropagation();
    this.props.dispatch(removePromotionalCode()).then(data => {
      if (data.message !== undefined) {
        // container.warning(`${data.message}`);
      }
    });
  };

  encomendar = () => {

    this.setState({
      buttonCheckout: true,
      paymentLoading: true
    });

    this.props.dispatch(confirmOrder())
      .then(data => {

        if (!data.HashEncomenda) {
          if (data.MensagemErro) {
            container.clear();
            // container.error(data.mensagem);
            this.setState({ paymentLoading: false });
            container.error(`${data.MensagemErro}`);
          } else {
            this.setState({ paymentLoading: false });
            container.clear();
            container.error(<FormattedMessage id="total.erro.finalizar" />);
          }

          this.setState({
            buttonCheckout: false
          });
        } else {
          // Obter o detalhe de um produto
          getOrderDetails(data.ID_Encomenda, this.props.user.userID)
            .then(result => {

              //Se conseguirmos obter os dados com sucesso da API
              if (result.success === true) {

                var detalhes = result.obj.Detalhes[0];

                // Dados de encomenda
                var order = {
                  IDEncomenda: detalhes.ID_Encomenda,
                  Affiliation: "Loja Online",
                  precoFinal: 0.01, //Fizemos este "hack" para a encomenda pode ser contabilizada no Analytics
                  precoTaxa: 0,
                  precoEnvio: 0
                };

                // Dados de produtos
                var products = [];

                // Criar o datalayer e enviar datalayer
                checkout_Encomendar(order, products);
                // Efetuar uma encomenda

                funnelStep(7);
                container.clear();
                container.success(data.Mensagem ? data.Mensagem : (
                  <FormattedMessage id="total.sucesso" />)
                );

                setTimeout(function () {
                  window.location = "/shop/payment/" + data.HashEncomenda;
                }, 2000);
              }
            }
            );

        }
      });
  };

  CustomizedSketelon() {
    return (<Skeleton variant="text" width={75} height={24} />);
  }

  render() {
    const { checkoutData, currencies, user, loadingCheckoutPrices } = this.props;

    var currencySelected = currencies.selected;
    var loadingPrices = loadingCheckoutPrices;

    return (
      <Row style={{ minHeight: "100%" }}>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="checkout-cart-total">
            {this.state.paymentLoading ? (
              <Loading />
            ) : (
              <div>

                {/* Titulo do Formulário */}
                <h4 style={{ "text-align": "center" }}>
                  {this.props.intl.formatMessage({ id: "total.titulo" })}
                </h4>

                {/* 1º listagem */}
                <ul>

                  {/* Sub Total */}
                  <li>
                    {this.props.intl.formatMessage({ id: "total.subtotal" })}{" "}
                    <span>
                      {loadingPrices === true ? (
                        this.CustomizedSketelon()
                      ) : (
                        <FormattedNumber value={checkoutData.Subtotal} style="currency"
                          currency={currencySelected} minimumFractionDigits={2} maximumFractionDigits={2} />
                      )}
                    </span>
                  </li>

                  {/* Portes */}
                  <li>
                    {this.props.intl.formatMessage({ id: "total.portes" })}{" "}
                    <span>
                      {loadingPrices === true ? (
                        this.CustomizedSketelon()
                      ) : (
                        <FormattedNumber value={checkoutData.Portes} style="currency"
                          currency={currencySelected} minimumFractionDigits={2} maximumFractionDigits={2} />
                      )}
                    </span>
                  </li>

                  {/* Iva */}
                  <li>
                    {this.props.intl.formatMessage({ id: "total.iva" })}{" "}
                    <span>
                      {loadingPrices === true ? (
                        this.CustomizedSketelon()
                      ) : (
                        <FormattedNumber value={checkoutData.Iva} style="currency"
                          currency={currencySelected} minimumFractionDigits={2} maximumFractionDigits={2} />
                      )}
                    </span>
                  </li>

                  {/* Código Promocional */}
                  {checkoutData.ChaveCodigoPromocional ? (
                    <li>
                      <div className="discountAbleToRemove">
                        - {this.props.intl.formatMessage({ id: "total.codigoPromocional" })}{" "}

                        {loadingPrices === true ? (
                          this.CustomizedSketelon()
                        ) : (
                          <span>
                            <FormattedNumber color="rgb(202, 0, 106)" value={checkoutData.ValorDescontoCP} style="currency"
                              currency={currencySelected}
                              minimumFractionDigits={2} maximumFractionDigits={2} />

                            <span className="removeDiscountButton" onClick={this.removePromotionalCode}>
                              {this.props.intl.formatMessage({ id: "total.remover" })}
                            </span>
                          </span>
                        )}
                      </div>
                    </li>
                  ) : null}

                  {/* Vouchers */}
                  {checkoutData.vouchers ? (
                    <li>
                      <div className="discountAbleToRemove">
                        - {this.props.intl.formatMessage({ id: "total.vales" })}{" "}

                        {loadingPrices === true ? (
                          this.CustomizedSketelon()
                        ) : (
                          <span>
                            <FormattedNumber color="rgb(202, 0, 106)" value={checkoutData.vouchers} style="currency" currency={currencySelected}
                              minimumFractionDigits={2} maximumFractionDigits={2} />

                            <span className="removeDiscountButton" onClick={this.removeVouchers}>
                              {this.props.intl.formatMessage({ id: "total.remover" })}
                            </span>
                          </span>
                        )}
                      </div>
                    </li>
                  ) : null}

                  {/* Créditos */}
                  {checkoutData.credits ? (
                    <li>
                      <div className="discountAbleToRemove">
                        - {this.props.intl.formatMessage({ id: "total.creditos" })}{" "}

                        {loadingPrices === true ? (
                          this.CustomizedSketelon()
                        ) : (
                          <span>
                            <FormattedNumber color="rgb(202, 0, 106)" value={checkoutData.credits} style="currency" currency={currencySelected}
                              minimumFractionDigits={2} maximumFractionDigits={2} />

                            <span className="removeDiscountButton" onClick={this.removeCredits}>
                              {this.props.intl.formatMessage({ id: "total.remover" })}
                            </span>
                          </span>
                        )}
                      </div>
                    </li>
                  ) : null}

                  {/* Descontos */}
                  <li>
                    <div className={checkoutData.Descontos > 0 ? "hasGlobalDiscount" : ""}>
                      - {this.props.intl.formatMessage({ id: "total.desconto" })}{" "}

                      {loadingPrices === true ? (
                        this.CustomizedSketelon()
                      ) : (
                        <FormattedNumber color="rgb(202, 0, 106)" value={checkoutData.Descontos} style="currency" currency={currencySelected}
                          minimumFractionDigits={2} maximumFractionDigits={2} />
                      )}

                    </div>
                  </li>
                </ul>

                <h6 className="center" style={{ margin: "0px", padding: "0px" }}>

                  {/* Label do valor a pagar */}
                  <Row style={{ display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center", margin: "0px", padding: "0px" }}>
                    <Col style={{ padding: "0px", textAlign: "left" }}>
                      {this.props.intl.formatMessage({ id: "total.valorPagar" })}{" "}
                    </Col>

                    {/* Valor a Pagar */}
                    <Col style={{ padding: "0px", textAlign: "right", display: "flex", justifyContent: "flex-end" }}>

                      {loadingPrices === true ? (
                        <Skeleton width={150} height={33} variant="text" />
                      ) : (
                        <FormattedNumber value={checkoutData.Total} style="currency" currency={currencySelected}
                          minimumFractionDigits={2} maximumFractionDigits={2} />
                      )}

                    </Col>
                  </Row>
                </h6>

                {/*Divisória*/}
                <p className="center" style={{ textAlign: "right" }} />

                <br />
                <div className="col-12 center">
                  <Button
                    className="btn btn-primary"
                    id="encomendarButton"
                    name="encomendarButton"
                    onClick={this.encomendar}
                    disabled={this.state.buttonCheckout || loadingPrices === true ? true : false}
                    style={{
                      backgroundColor: "rgb(202, 0, 106)",
                      borderColor: "rgb(202, 0, 106)",
                      minWidth: "100%"
                    }}
                  >
                    <FormattedMessage id="total.Finalizar" />
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart,
    checkoutData: state.checkoutData,
    currencies: state.currencies,
    loading: state.loading,
    loadingCheckoutPrices: state.loadingCheckoutPrices
  };
};

export default injectIntl(withRouter(connect(mapStateToProps)(Total)));
