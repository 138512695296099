import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastr";
import { getShipmentTypes, getShippingAddresses, selectShipmentType } from "../../../actions/miniCartActions";
import "../Checkout/Checkout.css";

let container;

class FormasEnvio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shipmentType: {
        type: null
      }
    };

    this.props.dispatch(getShippingAddresses());
  }
  componentDidMount() {
    this.props.dispatch(getShipmentTypes());

  }

  handleChangeShipmentType = (event, index, value) => {
    this.props.dispatch(selectShipmentType(value)).then(data => {
      if (typeof data !== 'undefined') {
        container.clear();
        container.error(`${data.error}`);
      }
    });

    this.setState(prevState => ({
      shipmentType: {
        ...prevState.shipmentType,
        type: value
      }
    }));
  };

  render() {
    const { shipmentTypes, checkoutData, deliveryAddresses } = this.props;

    return checkoutData.ProdutosOnline !== 0 ? (
      <div>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <div
          className={
            !checkoutData.confirmOrderClicked && checkoutData.selectShipmentType
              ? "checkoutBox doneSelected"
              : checkoutData.confirmOrderClicked
                ? checkoutData.selectShipmentType
                  ? "checkoutBox doneSelected"
                  : "checkoutBox discountSelected"
                : "checkoutBox"
          }
        >
          <Row>
            <Col xs={3} sm={3} md={3} lg={3} style={{ textAlign: "left" }}>
              <h4>
                <svg
                  width="35px"
                  height="34px"
                  viewBox="0 0 612 612"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path
                      d="M256,0C114.609,0,0,114.609,0,256c0,141.391,114.609,256,256,256c141.391,0,256-114.609,256-256
		C512,114.609,397.391,0,256,0z M256,472c-119.297,0-216-96.703-216-216S136.703,40,256,40s216,96.703,216,216S375.297,472,256,472z
		"
                    />
                    <g>
                      <path
                        d="M182.828,204.781c2.688-24.062,9.672-42.922,20.938-56.5C215.031,134.766,232.812,128,257.25,128
			c21.156,0,37.938,5.969,50.25,17.875s18.469,27.75,18.469,47.469c0,14.125-2.562,25.375-7.781,33.781
			c-5.233,8.375-13.453,14.906-24.78,19.625c5.422,1.75,10.641,4.25,15.688,7.5c5.188,3.25,9.734,7.406,13.672,12.594
			c4.062,5.156,7.267,11.234,9.734,18.344c2.344,7.047,3.5,15.359,3.5,25.095c0,12.172-2.078,22.688-6.234,31.75
			c-4.28,9.188-9.983,16.905-17.077,22.983c-7.234,6.219-15.642,10.938-25.047,14.188c-9.531,3.233-19.673,4.797-30.392,4.797
			c-21.969,0-40.156-6.983-54.5-20.983c-14.344-13.984-23.25-34.548-26.75-62h32.562c3.188,20,8.734,34.047,16.5,42.202
			c7.75,8.031,18.531,12.062,32.188,12.062c6.156,0,12.094-1.017,17.703-3.048c5.641-2.016,10.516-5.016,14.812-9.016
			c4.312-4,7.734-8.734,10.392-14.281c2.5-5.688,3.702-11.905,3.702-18.655c0-13.517-4.125-25.017-12.5-34.438
			c-8.25-9.375-19.641-14.094-34.108-14.094h-18.516v-28.719h18.516c6.719,0,12.422-1.062,17.108-3.281
			c4.656-2.234,8.484-5.094,11.453-8.625c2.953-3.5,5.031-7.5,6.22-11.875c1.297-4.469,1.797-8.875,1.797-13.25
			c0-12.375-3.406-22.109-10.25-29.156c-6.828-7.078-15.922-10.594-27.188-10.594c-6.953,0-12.797,1.281-17.656,3.984
			c-4.812,2.641-8.875,6.156-12.078,10.547c-3.188,4.453-5.719,9.594-7.625,15.484c-1.859,5.875-3.219,12.109-4,18.516H182.828z"
                      />
                    </g>
                  </g>
                </svg>
              </h4>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "center" }}>
              <h5>
                <FormattedMessage id="formaenvio.titulo" />
              </h5>
            </Col>
            <Col xs={3} sm={3} md={3} lg={3} style={{ textAlign: "right" }}>
              <h4>
                <svg
                  width="35px"
                  height="34px"
                  viewBox="0 0 612 612"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <g>
                      <path
                        d="M226.764,375.35c-28.249,0-51.078,22.91-51.078,51.16c0,28.166,22.829,51.078,51.078,51.078s51.078-22.912,51.078-51.078
			C277.841,398.26,255.013,375.35,226.764,375.35z M226.764,452.049c-14.125,0-25.54-11.498-25.54-25.541
			c0-14.123,11.415-25.539,25.54-25.539c14.124,0,25.539,11.416,25.539,25.539C252.302,440.551,240.888,452.049,226.764,452.049z
			 M612,337.561v54.541c0,13.605-11.029,24.635-24.636,24.635h-26.36c-4.763-32.684-32.929-57.812-66.927-57.812
			c-33.914,0-62.082,25.129-66.845,57.812H293.625c-4.763-32.684-32.93-57.812-66.845-57.812c-33.915,0-62.082,25.129-66.844,57.812
			h-33.012c-13.606,0-24.635-11.029-24.635-24.635v-54.541H612L612,337.561z M494.143,375.35c-28.249,0-51.16,22.91-51.16,51.16
			c0,28.166,22.912,51.078,51.16,51.078c28.166,0,51.077-22.912,51.077-51.078C545.22,398.26,522.309,375.35,494.143,375.35z
			 M494.143,452.049c-14.125,0-25.539-11.498-25.539-25.541c0-14.123,11.414-25.539,25.539-25.539
			c14.042,0,25.539,11.416,25.539,25.539C519.682,440.551,508.185,452.049,494.143,452.049z M602.293,282.637l-96.817-95.751
			c-6.159-6.077-14.453-9.526-23.076-9.526h-48.86v-18.313c0-13.631-11.004-24.635-24.635-24.635H126.907
			c-13.55,0-24.635,11.005-24.635,24.635v3.86L2.3,174.429l177.146,23.068L0,215.323l178.814,25.423L0,256.25l102.278,19.29
			l-0.007,48.403h509.712v-17.985C611.983,297.171,608.452,288.796,602.293,282.637z M560.084,285.839h-93.697
			c-2.135,0-3.86-1.724-3.86-3.859v-72.347c0-2.135,1.725-3.86,3.86-3.86h17.82c0.985,0,1.971,0.411,2.71,1.068l75.796,72.347
			C565.257,281.569,563.532,285.839,560.084,285.839z"
                      />
                    </g>
                  </g>
                </svg>
              </h4>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className="shippmentType">
              <SelectField
                floatingLabelText={this.props.intl.formatMessage({
                  id: "formaenvio.acao"
                })}
                value={this.state.shipmentType.type}
                onChange={this.handleChangeShipmentType}
                fullWidth={true}
                className="SelectField"
                labelStyle={{ paddingRight: "0px" }}
              >
                {
                  shipmentTypes.map((shipmentType, j) => {
                    var credits = checkoutData.credits ? checkoutData.credits : 0;
                    var voucher = checkoutData.vouchers ? checkoutData.vouchers : 0;

                    var ValorDescontoCP = checkoutData.ValorDescontoCP
                      ? checkoutData.ValorDescontoCP
                      : 0;

                    var valorTotalEncomenda = checkoutData.Total + credits + voucher + ValorDescontoCP;
                    var valorTotalEncomendaMenosPortes = checkoutData.Total - checkoutData.Portes + credits + voucher + ValorDescontoCP;

                    //Acrescentamos a validação se pais da morada atual é portugal
                    if (
                      (!shipmentType.ValorMinimo && !shipmentType.ValorMaximo) ||
                      (!shipmentType.ValorMinimo && shipmentType.ValorMaximo >= valorTotalEncomenda) ||
                      (!shipmentType.ValorMaximo && shipmentType.ValorMinimo <= valorTotalEncomendaMenosPortes) ||
                      (shipmentType.ValorMinimo <= valorTotalEncomendaMenosPortes && shipmentType.ValorMaximo >= valorTotalEncomendaMenosPortes)
                    ) {
                      return (
                        <MenuItem
                          value={shipmentType.ID_TipoTransporte}
                          primaryText={shipmentType.Descricao}
                        />
                      );
                    }
                  })}
              </SelectField>
            </Col>
          </Row>
        </div>
      </div>
    ) : (
        <div className={"checkoutBox doneSelected"}>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ "text-align": "center", top: "7px", height: "112px" }}
            >
              <h5>
                <FormattedMessage id="formaenvio.naoaplicavel" />
              </h5>
              <h6>
                <FormattedMessage id="formaenvio.naoaplicavel1" />
              </h6>
            </Col>
          </Row>
        </div>
      );
  }
}
const mapStateToProps = state => {
  return {
    shipmentTypes: state.shipmentTypes,
    checkoutData: state.checkoutData,
    deliveryAddresses: state.deliveryAddresses
  };
};

export default injectIntl(connect(mapStateToProps)(FormasEnvio));
