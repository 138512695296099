import React, { Component } from 'react';
import { connect } from "react-redux";
import { changeLanguageFE, setLoadingAllPageContent } from '../../../../actions/miniCartActions';
import { IntlContext } from "../../../../IntlProviderWrapper";

class SelectLanguage extends Component {

    constructor(props) {
        super(props);
    }

    async handleInputLingua(e) {
        let value = e.target.value;

        this.props.dispatch(setLoadingAllPageContent(true));
        await this.props.dispatch(changeLanguageFE(value));

        if (typeof this.props.callback !== 'undefined') {
            this.props.callback();
        }
    }

    render() {
        const { languages, user } = this.props;
        return (
            <div>
                {/* Botão de selecionar idioma */}
                <IntlContext.Consumer>
                    {({ switchTo }) => (
                        <div className="selectLanguageContainer">
                            <select
                                onChange={async (e) => {
                                    var value = e.target.value;
                                    await this.handleInputLingua(e);
                                    switchTo(value);
                                }
                                }
                                name="language"
                                className="select-css selectLanguageInput"
                                style={{
                                    color: "white",
                                    backgroundColor: user.persona != user.userID
                                        ? user.persona
                                            ? "#05ef00"
                                            : "rgba(3, 66, 117, 1)"
                                        : user.ConsultantType == 0
                                            ? "#e2e220"
                                            : "rgba(3, 66, 117, 1)",
                                    boxShadow: "none"
                                }}
                            >
                                {languages.selected ? (
                                    <option value={languages.selected}>
                                        {languages.selected}{" "}
                                    </option>
                                ) : null}
                                {languages.list
                                    ? languages.list.map((lingua, j) => {
                                        return lingua.ID_Lingua ===
                                            languages.selected ? null : (
                                            <option value={lingua.ID_Lingua}>
                                                {lingua.ID_Lingua}
                                            </option>
                                        );
                                    })
                                    : null}
                            </select>
                        </div>
                    )}
                </IntlContext.Consumer>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        languages: state.languages
    };
};

export default connect(mapStateToProps)(SelectLanguage);