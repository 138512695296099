import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import HeaderDesktop from "../../../components/Desktop/Header/Header";
import LoginDesktop from "../../../components/Desktop/Login/Login";
import Footer from "../../../components/Footer/Footer";

class Login extends Component {

  render() {


    var previousInCheckout = false;

    var state = this.props.location.state;
    if (typeof state !== 'undefined' && state != null) {
      if (typeof state.previousInCheckout !== 'undefined' && state.previousInCheckout === true) {
        previousInCheckout = true;
      }
    }
    return (
      <div className="App">
        <HeaderDesktop />
        <Container className="mainContentContainer">
          <Row style={{ textAlign: "center" }} >
            <Col xs="12" sm="12" md="12" lg="12">
              <LoginDesktop history={this.props.history} previousInCheckout={previousInCheckout} />
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    user: state.user
  };
};

export default connect(mapStateToProps)(Login);
