import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastr";
import { selectVouchers } from "../../../actions/miniCartActions";
import "./Checkout.css";
import Skeleton from '@material-ui/lab/Skeleton';

let container;

class Vales extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vouchers: ""
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() { }

  handleChange = name => event => {
    const { checkoutData } = this.props;
    // Somente consegue adicionar um valor até perfazer o valor de vales permitidos
    if (
      event.target.value <= parseFloat(checkoutData.valesPermitidos).toFixed(2)
    ) {
      this.setState({
        [name]: event.target.value
      });
    } else {
    }
  };

  handleFormSubmit(e) {
    e.preventDefault();
    const { checkoutData } = this.props;
    if (this.state.vouchers) {
      var totalVouchers = 0;


      //Verificar o valor dos vouchers preenchidos
      var tmpVoucher = this.state.vouchers;

      //Substituimos , para . 
      tmpVoucher = tmpVoucher.replace(/,/g, ".");

      //Se não for um número mostramos erro
      if (isNaN(Number(tmpVoucher))) {
        container.clear();
        container.error(<FormattedMessage id="vales.erro.valorInvalido" />);
        return;
      }

      //Se já tiver lá vouchers
      if (checkoutData.vouchers !== undefined) {
        totalVouchers =
          parseFloat(checkoutData.vouchers) + parseFloat(tmpVoucher);

        //Se ainda não tivermos vouchers
      } else {
        totalVouchers = parseFloat(tmpVoucher);
      }

      this.props.dispatch(selectVouchers(totalVouchers)).then(data => {

        if (data.success) {
          this.setState({
            vouchers: ""
          });
          container.clear();
          container.success(`${data.success}`);
        }
        if (data.error) {
          this.setState({
            vouchers: ""
          });
          container.clear();
          container.error(`${data.error}`);
        }
      });
    } else {
      container.clear();
      container.warning(<FormattedMessage id="vales.erro.semVale" />);
    }
  }

  render() {
    const { checkoutData, currencies, user, loadingCheckoutPrices } = this.props;

    var currencySelected = currencies.selected;
    var loadingPrices = loadingCheckoutPrices;
    return (
      <div>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <div
          className={
            /* Validar se existe créditos metendo a disabled se o valor for nulo ou 0 */
            Math.round(checkoutData.valesPermitidos * 100) / 100 === 0
              ? "checkoutBox disabledCreditsBox"
              : "checkoutBox "
          }
        >
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <h5>
                <FormattedMessage id="vales.titulo" />
              </h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="textfieldVales">
                <TextField
                  fullWidth={true}
                  value={this.state.vouchers}
                  onChange={this.handleChange("vouchers")}
                  placeholder={this.props.intl.formatMessage({
                    id: "vales.inserirvalor"
                  })}
                  inputStyle={{ textAlign: "center" }}
                />
                <p className="vouchersUsedContainer">
                  <FormattedMessage id="vales.utilizados" />{" "}

                  {loadingPrices === true ? (
                    <Skeleton variant="text" width={50} height={24} />
                  ) : (
                      <FormattedNumber
                        value={checkoutData.vouchers ? checkoutData.vouchers : 0}
                        style="currency"
                        currency={currencySelected}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    )}

                </p>
                <p className="vouchersAvailableContainer">
                  <FormattedMessage id="vales.consegueutilizar" />{" "}

                  {loadingPrices === true ? (
                    <Skeleton variant="text" width={50} height={24} />
                  ) : (
                      <FormattedNumber
                        value={checkoutData.valesPermitidos}
                        style="currency"
                        currency={currencySelected}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    )}
                </p>
                <div className="buttonsValidarCodigos">
                  {this.state.vouchers ? (
                    <FlatButton
                      disabled={false}
                      label={this.props.intl.formatMessage({
                        id: "vales.validar"
                      })}
                      secondary={true}
                      onClick={this.handleFormSubmit}
                    />
                  ) : (
                      <FlatButton
                        disabled={true}
                        label={this.props.intl.formatMessage({
                          id: "vales.validar"
                        })}
                        secondary={true}
                      />
                    )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    checkoutData: state.checkoutData,
    currencies: state.currencies,
    loadingCheckoutPrices: state.loadingCheckoutPrices
  };
};

export default injectIntl(connect(mapStateToProps)(Vales));
