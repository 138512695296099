import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import HeaderDesktop from "../../../../../components/Desktop/Header/Header";
import Footer from "../../../../../components/Footer/Footer";
import PaymentConditions from "../../../../../components/PaymentConditions/PaymentConditions";

class CondicoesPagamento extends Component {
  render() {
    return (
      <div className="App">
        <HeaderDesktop />
        <br />
        <Container
          style={{
            position: "relative",
            top: "60px",
            width: "90vw"
          }}
        >
          <Row
            style={{
              textAlign: "center"
            }}
          >
            <Col xs="12" sm="12" md="12" lg="12">
              <h2>FORMAS DE PAGAMENTO, PORTES, ENVIOS E DEVOLUÇÕES</h2>
              <hr />
              <PaymentConditions />
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    user: state.user
  };
};

export default connect(mapStateToProps)(CondicoesPagamento);
