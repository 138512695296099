import React, { Component } from "react";

import Footer from "../Footer/Footer";

import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

class ValidateEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    return (
      <div className="App">
        
        <br />

        <div class="container">
          <div>
            <div className="paymentMultibancoFormatting">
              <div className="paymentMultibanco">
                <p>
                  <b>Registo efetuado com Sucesso</b>
                </p>
                <p>
                  <b>
                    Aceda ao seu email e valide a sua conta para aceder ao seu
                    registo
                  </b>
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart
  };
};

export default withRouter(connect(mapStateToProps)(ValidateEmail));
