import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { paymentPaypalCanceled, paymentPaypalConfirmed } from "../../actions/miniCartActions";
import Loading from "../Desktop/Loading/Loading";
import "./Payment.css";

// https://developer.paypal.com/demo/checkout/#/pattern/responsive
// https://www.npmjs.com/package/react-paypal-express-checkout

class PaymentPaypal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelado: false,
      sucesso: false,
    };
  }

  render() {
    const onSuccess = payment => {
      this.props
        .dispatch(paymentPaypalConfirmed(this.props.encomenda))
        .then(data => {
          this.setState({ sucesso: true });
          setTimeout(function () {
            window.location.href = process.env.REACT_APP_MY_WEBSITE_URL + "encomendas/";
          }, 2000);
        });
    };

    const onCancel = d => {
      this.props
        .dispatch(paymentPaypalCanceled(this.props.encomenda))
        .then(data => {
          this.setState({ cancelado: true });
        });
    };

    const onError = err => {
      alert("Erro no pagamento!");
      console.log("Error!", err);
    };

    let env = "production";
    const client = {
      sandbox:
        "ARryo3EMtByfK7CEsi2oVl1EiM0U1t1ObdefdzsXr50uEldQ2x57AdBnipFTRp2nEWPMB5Aa4CXPkIvz",
      production:
        "AR5958tP2XMZQZaosxfqHOZ-AA2zbTAWN5WCoZVCZFRLsRFkM1lgMFOYw5Qa3f0_IY7tXm3cjHHDKv4Z",
    };
    return (
      <Row className="payment">
        {!this.state.sucesso ? (
          !this.state.cancelado ? (
            <Col xs={12} sm={12} md={12} lg={12}>
              <p><FormattedMessage id="pagamento.alterarTipoPagamento.p1" /> </p>
              {this.props.total && this.props.currency ? (
                this.props.loading ? (
                  <Loading />
                ) : (
                    <PaypalExpressBtn
                      env={env}
                      client={client}
                      currency={this.props.currency.toUpperCase()}
                      total={parseFloat(this.props.total)}
                      onError={onError}
                      onSuccess={onSuccess}
                      onCancel={onCancel}
                      style={{ color: "gold", zIndex: "1" }}
                    />
                  )
              ) : null}
            </Col>
          ) : (
              <Col xs={12} sm={12} md={12} lg={12}>
                <p>
                  <h1><FormattedMessage id="pagamento.alterarTipoPagamento.paypal.cancelado" /></h1>
                </p>
              </Col>
            )
        ) : (
            <Col xs={12} sm={12} md={12} lg={12}>
              <p>
                <h3><FormattedMessage id="pagamento.alterarTipoPagamento.paypal.sucesso" /></h3>
              </p>
            </Col>
          )}
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart,
    currencies: state.currencies,
    loading: state.loading,
  };
};

export default withRouter(connect(mapStateToProps)(PaymentPaypal));
