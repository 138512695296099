import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getEbanxCheckoutURL } from "../../actions/miniCartActions";
import "./Payment.css";

class PaymentEbanx extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkoutURL: ''
    };
  }

  componentDidMount() {
    getEbanxCheckoutURL().then(value => {
      console.log(value);
      this.setState({
        checkoutURL: value
      });
    })
  }

  render() {
    return (
      <Row className="payment" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Col xs={6} sm={6} md={6} lg={6}>
          <img
            src="https://doremon.ihavethepower.net/assets/images/ebanx.svg"
            className="logoPayment"
            alt=""
          />
        </Col>
        <Col xs={6} sm={6} md={6} lg={6}>
          <p><FormattedMessage id="pagamento.alterarTipoPagamento.p1" /> </p>
          <a href={this.state.checkoutURL + this.props.hash} target="blank">
            <FormattedMessage id="pagamento.alterarTipoPagamento.p2" />
          </a>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart
  };
};

export default withRouter(connect(mapStateToProps)(PaymentEbanx));
