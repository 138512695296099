import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./Payment.css";

class PaymentMBWay extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Row className="payment" >
        <Col xs={5} sm={5} md={5} lg={5} style={{ display: "flex", alignItems: "center", alignContent: "center", justifyContent: "center" }}>
          <img
            src="https://doremon.ihavethepower.net/assets/images/MBWay.png"
            className="logoPayment"
            alt=""
          />
        </Col>
        <Col xs={7} sm={7} md={7} lg={7}>
          <p><FormattedMessage id="pagamento.alterarTipoPagamento.mbWay.p1" /> (<b>{this.props.numtelemovel}</b>) <FormattedMessage id="pagamento.alterarTipoPagamento.mbWay.p2" /></p>
          <p style={{ fontSize: "16px", fontWeight: "600" }}><FormattedMessage id="pagamento.alterarTipoPagamento.mbWay.p3" /> - {this.props.idEncomenda}</p>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart
  };
};

export default withRouter(connect(mapStateToProps)(PaymentMBWay));
