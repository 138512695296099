import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import FooterMenuItem from './FooterMenuItem'

export default class FooterPartners extends Component {
    render() {
        return (
            <Col xs="12" sm="12" md="12" lg="12" className="footerPartner">
                <hr />
                <FooterMenuItem title="O NOSSO ORGULHO" customStyle={{ marginBottom: "20px" }} />

                <Row className="footerPartnerColumnsContainer">
                    <Col className="footerPartnerCol">
                        <img width="100%" height="auto" src="/assets/images/ihtp-solfut.svg"></img>
                    </Col>
                    <Col className="footerPartnerCol">
                        <img width="100%" height="auto" src="/assets/images/ihtp-business-consulting.svg"></img>
                    </Col>
                    <Col className="footerPartnerCol">
                        <img width="100%" height="auto" src="/assets/images/ihtp-wonder-lives.svg"></img>
                    </Col>

                </Row>
                <hr style={{ marginTop: "0px" }} />
            </Col>
        )
    }
}
