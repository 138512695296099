import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";

class PaymentConditions extends Component {
  render() {
    return (
      <div className="App">

        <div className="page-section mb-50">
          <div className="container">
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ "text-align": "justify" }}
              >
                <p>
                  <b>IMPORTANTE:</b>
                </p>
                <p>
                  No caso de pagar com um nome diferente do da encomenda, por
                  favor envie-nos um e-mail indicando o nome do titular do
                  pagamento para podermos sincronizar com a sua encomenda, e
                  desta forma evitarmos atrasos.{" "}
                </p>
                <p>
                  <b>FATURAS</b>
                </p>{" "}
                <p>
                  Após recepção do seu pagamento a sua factura e recibo ficam
                  disponíveis no detalhe da sua encomenda, acessível via MyIHTP.
                </p>
                <p>
                  <b>FORMAS DE PAGAMENTO</b>
                </p>
                <p>- Cheque(à ordem de Solfut, Lda)</p>{" "}
                <p>
                  - Transferência bancária para: IBAN: PT50 0007 0447 0000 8940
                  0041 7
                </p>{" "}
                <p>
                  - Transferência bancária internacional para: SWIFT/BIC
                  BESCPTPL
                </p>{" "}
                <p>
                  - Cartão de crédito através da PayPal (www.paypal.com) - opção
                  segura já que não circulam os dados do seu cartão
                </p>{" "}
                <p>- Paypal</p>
                <p>- Boleto Bancário (apenas Brasil)</p>
                <p>- Multibanco</p>
                <p>
                  <b> DESPESAS DE ENVIO / PORTES</b>
                </p>
                <p>
                  Valor encomenda: até 35.00Eur -- Despesas de Envio = até 3.55
                  Eur. dependendo do peso da encomenda.
                </p>{" "}
                <p>
                  Valor encomenda: maior ou igual a 35.00Eur -- Despesas de
                  Envio Grátis
                </p>{" "}
                <p>
                  Envio para locais, fora de Portugal continental: portes
                  calculados em cada encomenda em função do destino e peso da
                  encomenda.
                </p>
                <p>
                  <b> ENVIOS</b>
                </p>{" "}
                <p>
                  Após recepção e confirmação do pagamento, a SOLFUT, Lda. envia
                  os produtos encomendados via serviço de correio para a morada
                  de envio indicada na encomenda.
                </p>{" "}
                <p>
                  No caso de produtos digitais, tais como conteúdos on-line
                  (MP3, Vídeo, etc.), os mesmos serão disponibilizados no I HAVE
                  THE POWER MEDIA CENTER, acessível na área reservada do cliente
                  (MyIHTP), após recepção do respectivo pagamento.
                </p>{" "}
                <p>
                  As compras estão abrangidas por uma Garantia de Satisfação de
                  100%, ou seja, caso não fique totalmente satisfeito,
                  devolvemos o seu dinheiro.
                </p>
                <p>
                  <b> DEVOLUÇÕES</b>
                </p>{" "}
                <p>No caso de devoluções os portes são a cargo do cliente.</p>
              </Col>
            </Row>
          </div>
        </div>
        {/*  */}
      </div>
    );
  }
}

export default PaymentConditions;
