import React, { Component } from 'react'
import { FormattedMessage } from "react-intl";

export default class MainPageButton extends Component {
    render() {
        return (
            <a href={process.env.REACT_APP_MAIN_WEBSITE_URL} className="headerLinks">
                <span>
                    <FormattedMessage id="header.mainPage" />
                </span>
            </a>
        )
    }
}
