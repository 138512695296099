import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

export default class ProductGridCategoryItem extends Component {
    render() {

        var backgroundColor = null;

        //Se a cor de fundo não estiver definida 
        if (!this.props.backgroundColor) {

            //Se estiver selecionado colamos a cor
            if (this.props.isSelected === true) {
                backgroundColor = "rgba(0, 100, 181, 0.8)";
            }
        } else {

            backgroundColor = (this.props.isSelected === true && this.props.selectedBackgroundColor) ?
                this.props.selectedBackgroundColor
                : this.props.backgroundColor

        }

        var showLink = true;

        if (this.props.startDate && this.props.endDate) {
            if (Date.now() >= this.props.startDate && Date.now() <= this.props.endDate) {
                showLink = true;
            } else {
                showLink = false
            }
        }

        return (
            <div>
                {showLink === true ? (
                    <li style={{
                        textAlign: "center",
                        padding: "5px",
                        fontSize: "18px",
                        marginBottom: "5px",
                        backgroundColor: backgroundColor
                    }}>
                        <Link to={this.props.linkTo} onClick={this.props.onClick} >
                            {this.props.text ? this.props.text :
                                <FormattedMessage id={this.props.textSource} />
                            }
                        </Link>
                    </li>
                ) : null}
            </div>
        )
    }
}
