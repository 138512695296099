import { isInteger } from "lodash";
import { instanceOf } from "prop-types";
import React, { Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Cookies } from "react-cookie";
import { isMobile } from "react-device-detect";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { clearCart, fetchCart, incrementPackCart, incrementProductCart } from "../../../actions/miniCartActions";
import { default as Routes, default as RoutesObject } from "../../../helpers/Routes";
import Loading from "../../Desktop/Loading/Loading";
import "./Cart.css";
import CartIsEmpty from "./Components/CartIsEmpty";
import CartItem from "./Components/CartItem";
import ErrorLoadingCart from "./Components/ErrorLoadingCart";
import TrashIcon from "./Components/TrashIcon";
import { ToastContainer } from "react-toastr";

let container;
class Cart extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };


  componentDidMount() {
    var willTryToAddItem = false;
    var allURLParams = new URLSearchParams(window.location.search);

    //Verificamos se estamos a tentar adicionar primeiro um produto
    if (allURLParams.has('addProduct') && allURLParams.get('addProduct') != null) {

      willTryToAddItem = true;

      var addProduct = this.validateParamIsProperInt(allURLParams.get('addProduct'));
      if (addProduct != null) {

        //Adicionamos o produto ao carrinho
        this.props.dispatch(incrementProductCart(addProduct)).then(data => {
          this.props.dispatch(fetchCart());
        });
      }

      //Apagamos a query da route
      allURLParams.delete('addProduct');
      this.props.history.replace(
        this.props.location.pathname,
      );
    }

    //Verificamos se estamos a tentar adicionar primeiro um pack
    if (allURLParams.has('addPack') && allURLParams.get('addPack') != null) {

      willTryToAddItem = true;

      var addPack = this.validateParamIsProperInt(allURLParams.get('addPack'));
      if (addPack != null) {
        //Adicionamos o produto ao carrinho
        this.props.dispatch(incrementPackCart(addPack)).then(data => {
          this.props.dispatch(fetchCart());
        });
      }

      //Apagamos a query da route
      allURLParams.delete('addPack');
      this.props.history.replace(
        this.props.location.pathname,
      );
    }

    //Se não estivermos a adicionar nenhum produto, apenas carregamos dados
    if (willTryToAddItem === false) {
      this.props.dispatch(fetchCart());
    }
  }

  //Validamos se o parametro é um inteiro válido
  validateParamIsProperInt = (value) => {
    console.log(value);
    if (value == null) return null;
    if (value.length <= 0) return null;

    var tmp = Number(value);

    if (isNaN(tmp)) return null;

    if (isInteger(tmp)) return parseInt(tmp);

    return null;
  }

  limpar = userID => async e => {
    e.stopPropagation();
    this.props.dispatch(clearCart()).then(data => {
      window.location.href = "/";
    });
  };

  removeAll = () => {
    this.props.dispatch(clearCart()).then(data => { });
  };

  render() {
    const { cart, currencies, user, loadingCart, cartLoadError } = this.props;

    var isCartEmpty = true;
    isCartEmpty = !((cart.Carrinho || cart.CarrinhoPack) && (cart.Carrinho.length > 0 || cart.CarrinhoPack.length > 0));

    var currency = currencies.selected;

    return (
      <div>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />

        {/* Titulo da Página */}
        <Row className="cartTitleParent">
          <Col>
            <div className="cartTitle">
              <FormattedMessage id="shop.cart.carrinho.title" />
            </div>
          </Col>
        </Row>
        <hr />

        {
          loadingCart === true ? (
            <Loading />
          ) : (
            <div>

              {cartLoadError != null && cartLoadError.length > 0 ? (
                <ErrorLoadingCart errorMessage={cartLoadError} />
              ) : (
                <div>
                  {/* Mostrar produtos do carrinho*/}
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <div style={{ marginTop: "10px" }}>

                        {/* Apresentar Produtos no Carrinho */}
                        {cart.Carrinho && cart.Carrinho.length > 0 && cart.Carrinho.map((item, j) => {
                          return <CartItem currency={currency} toast={container} key={item.ID_Produto} item={item} user={user} />
                        })
                        }

                        {/* Apresentar Packs no Carrinho */}
                        {cart.CarrinhoPack && cart.CarrinhoPack.length > 0 && cart.CarrinhoPack.map((item, j) => {
                          return <CartItem currency={currency} toast={container} key={item.ID_Pack} item={item} user={user} />
                        })
                        }
                      </div >
                    </Col >
                  </Row >
                  <br />

                  {isCartEmpty === false && (
                    <Row>
                      <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "left" }}>

                        {/* Linha com espaço separatório*/}
                        <Row>
                          <Col xs="12" sm="12" md="12" lg="8" />
                        </Row>

                        <Row>
                          {/* Link de Continuar a Comprar */}
                          <Col xs={9} sm={7} md={9} lg={10} style={{ marginRight: "15px" }}>
                            <NavLink to={RoutesObject.shop.home}>
                              <b>{"< "}<FormattedMessage id="shop.cart.carrinho.continuarComprar"></FormattedMessage></b>
                            </NavLink>
                          </Col>

                          {/* Botão para remover todos os produtos do carrinho */}
                          <Col xs="2" sm="2" md="2" lg="1">
                            <div
                              style={{
                                float: "right",
                                minWidth: "100%"
                              }}
                            >
                              <Button aria-label="Decrement" className="cartRemoveAllItems"
                                onClick={() => { this.removeAll(); }}>
                                <TrashIcon color="white" />
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}

                  {/* Linha para mostar o total a pagar em Mobile */}
                  {isMobile === true && isCartEmpty === false && (
                    <Container className="cartBottomCheckoutContainer">
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <div>
                            {/* Total */}
                            <div className="center" style={{ backgroundColor: "white", marginTop: "10px", fontSize: "20px" }}>

                              {/* Mostrar label a dizer total */}
                              <FormattedMessage style={{ fontSize: "20px" }} id="shop.cart.carrinho.total" />:{" "}

                              {/* Mostrar valor do total*/}
                              <span style={{ fontSize: "20px" }}>
                                <FormattedNumber
                                  value={cart.Total}
                                  style="currency"
                                  currency={currency}
                                  minimumFractionDigits={2}
                                  maximumFractionDigits={2}
                                />
                              </span>
                            </div>

                            {/* Poupou */}
                            {cart.Descontos && cart.Descontos > 0 ? (
                              <div style={{ color: "green", fontWeight: "400" }}>

                                {/* Poupou label */}
                                <span style={{ fontSize: "18px" }}>
                                  <FormattedMessage id="shop.cart.carrinho.poupar" />{": "}
                                </span>

                                {/* Poupou value */}
                                <span style={{ fontSize: "18px" }}>
                                  <FormattedNumber value={cart.Descontos} style="currency" currency={currency} minimumFractionDigits={2} maximumFractionDigits={2} />
                                </span>
                              </div>
                            ) : null}

                            {/* Créditos ou NetPoints*/}
                            {user.userType === "CS" ? (
                              <div style={{ marginBottom: "15px", color: "green" }}>

                                {/* Label Mostrar NetPoints da Encomenda */}
                                <span style={{ fontSize: "15px" }}>
                                  <FormattedMessage style={{ fontSize: "20px" }} id="shop.cart.carrinho.netpoints" />{": "}
                                </span>

                                {/* Mostrar valor dos netpoints*/}
                                <span style={{ fontSize: "15px" }}>
                                  <FormattedNumber
                                    value={cart.Netpoints}
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                  />
                                </span>
                              </div>
                            ) : (
                              <div style={{ marginBottom: "15px", color: "green" }}>

                                {/* Label Mostrar Créditos da Encomenda */}
                                <span style={{ fontSize: "15px" }}>
                                  <FormattedMessage id="shop.cart.carrinho.creditos" />{": "}
                                </span>

                                {/* Mostrar valor dos Créditos*/}
                                <span style={{ fontSize: "15px" }}>
                                  <FormattedNumber
                                    value={cart.Creditos}
                                    style="currency"
                                    currency={currency}
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                  />
                                </span>
                              </div>
                            )}

                            {/* Botão de Encomendar*/}
                            <div>
                              <a href={Routes.shop.checkout}>
                                <Button className="cartCheckoutButton">
                                  <FormattedMessage id="shop.cart.carrinho.encomendar" />
                                </Button>
                              </a>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  )}


                  {/* Verificar se o Carrinho, está vazio e mostrar mensagem correspondente*/}
                  {isCartEmpty === true && (
                    <CartIsEmpty />
                  )}

                  {/* Linha para mostrar o total e o que pagar em DESKTOP */}
                  {isMobile === false && isCartEmpty === false && (
                    <Container className="cartBottomCheckoutContainer">
                      <Row style={{ marginBottom: "10px", marginTop: "10px", backgroundColor: "white" }}>
                        <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                          <div>
                            <div className="center" style={{ backgroundColor: "white" }}>

                              {/* Total */}
                              <div>
                                {/* Total label */}
                                <FormattedMessage style={{ fontSize: "20px" }} id="shop.cart.carrinho.total" />:{" "}

                                {/* Total value */}
                                <span style={{ fontSize: "20px" }}>
                                  <FormattedNumber value={cart.Total} style="currency" currency={currency} minimumFractionDigits={2} maximumFractionDigits={2} />
                                </span>
                              </div>

                              {/* Poupou */}
                              {cart.Descontos && cart.Descontos > 0 ? (
                                <div style={{ color: "green", fontWeight: "400" }}>

                                  {/* Poupou label */}
                                  <span style={{ fontSize: "18px" }}>
                                    <FormattedMessage id="shop.cart.carrinho.poupar" />{": "}
                                  </span>

                                  {/* Poupou value */}
                                  <span style={{ fontSize: "18px" }}>
                                    <FormattedNumber value={cart.Descontos} style="currency" currency={currency} minimumFractionDigits={2} maximumFractionDigits={2} />
                                  </span>
                                </div>
                              ) : null}

                              {/* Créditos ou NetPoints*/}
                              {user.userType === "CS" ? (
                                <div style={{ marginBottom: "15px", color: "green" }}>

                                  {/* Label Mostrar NetPoints da Encomenda */}
                                  <span style={{ fontSize: "15px" }}>
                                    <FormattedMessage style={{ fontSize: "20px" }} id="shop.cart.carrinho.netpoints" />{": "}
                                  </span>

                                  {/* Mostrar valor dos netpoints*/}
                                  <span style={{ fontSize: "15px" }}>
                                    <FormattedNumber
                                      value={cart.Netpoints}
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                    />
                                  </span>
                                </div>
                              ) : (
                                <div style={{ marginBottom: "15px", color: "green" }}>

                                  {/* Label Mostrar Créditos da Encomenda */}
                                  <span style={{ fontSize: "15px" }}>
                                    <FormattedMessage id="shop.cart.carrinho.creditos" />{": "}
                                  </span>

                                  {/* Mostrar valor dos Créditos*/}
                                  <span style={{ fontSize: "15px" }}>
                                    <FormattedNumber
                                      value={cart.Creditos}
                                      style="currency"
                                      currency={currency}
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                    />
                                  </span>
                                </div>
                              )}
                            </div>

                            {/* Botão de Encomenda */}
                            <NavLink to={Routes.shop.checkout}>
                              <Button className="cartCheckoutButton">
                                <FormattedMessage id="shop.cart.carrinho.encomendar" />
                              </Button>
                            </NavLink>
                          </div >
                        </Col >
                      </Row >
                    </Container >
                  )}
                </div>
              )}
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart,
    user: state.user,
    cartLoadError: state.cartLoadError,
    currencies: state.currencies,
    loadingCart: state.loadingCart
  };
};

export default withRouter(connect(mapStateToProps)(Cart));

