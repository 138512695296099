import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastr";
import { selectPromotionalCode } from "../../../actions/miniCartActions";
import Skeleton from '@material-ui/lab/Skeleton';
import "./Checkout.css";

let container;

class CodigosPromocionais extends Component {
  constructor(props) {
    super(props);

    this.state = {
      promotionalCode: ""
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() { }

  handleFormSubmit(e) {
    e.preventDefault();
    this.props
      .dispatch(selectPromotionalCode(this.state.promotionalCode))
      .then(data => {

        if (data.error) {
          this.setState({
            promotionalCode: ""
          });
          container.clear();
          container.error(`${data.error}`);
        } else {
          this.setState({
            promotionalCode: ""
          });
          container.clear();
          container.success(`${data.success}`);
        }
      });
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    const { checkoutData, currencies, user, loadingCheckoutPrices } = this.props;

    var currencySelected = currencies.selected;
    var loadingPrices = loadingCheckoutPrices;

    return (
      <div>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <div
          className={
            /* Validar se existe créditos metendo a disabled se o valor for nulo ou 0 */
            checkoutData.ValorDescontoCP === 0 ||
              checkoutData.ChaveCodigoPromocional
              ? "checkoutBox disabledCodigoBox"
              : "checkoutBox "
          }
        >
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <h5>
                <FormattedMessage id="codigopromo.titulo" />
              </h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <TextField
                fullWidth={true}
                value={this.state.promotionalCode}
                onChange={this.handleChange("promotionalCode")}
                placeholder={this.props.intl.formatMessage({
                  id: "codigopromo.inserircodigo"
                })}
                maxLength="20"
                inputStyle={{ textAlign: "center" }}
              />
              <p style={{ "font-size": "14px", "margin-bottom": "5px" }}>
                {checkoutData.ChaveCodigoPromocional ? (
                  <b>{checkoutData.ChaveCodigoPromocional}</b>
                ) : (
                    this.props.intl.formatMessage({
                      id: "codigopromo.nenhumcodigo"
                    })
                  )}
              </p>
              <p className="promotionalCodeUsedContainer">
                <FormattedMessage id="codigopromo.descontoUtilizado" />{" "}

                {loadingPrices === true ? (
                  <Skeleton variant="text" width={50} height={24} />
                ) : (
                    <FormattedNumber
                      value={checkoutData.ValorDescontoCP}
                      style="currency"
                      currency={currencySelected}
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                  )}

              </p>
              <div className="buttonsValidarCodigos">
                {this.state.promotionalCode ? (
                  <FlatButton
                    label={this.props.intl.formatMessage({
                      id: "codigopromo.validar"
                    })}
                    secondary={true}
                    onClick={this.handleFormSubmit}
                  />
                ) : (
                    <FlatButton
                      disabled={true}
                      label={this.props.intl.formatMessage({
                        id: "codigopromo.validar"
                      })}
                      secondary={true}
                    />
                  )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    checkoutData: state.checkoutData,
    currencies: state.currencies,
    loadingCheckoutPrices: state.loadingCheckoutPrices
  };
};

export default injectIntl(connect(mapStateToProps)(CodigosPromocionais));

