import React, { Component } from 'react'
import { Row, Col } from 'reactstrap';
import SocialMedia from "../../SocialMedia/SocialMedia";

export default class FooterSocialMedia extends Component {
    render() {
        return (
            <Row className="footerSocialMedia">
                <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                    <SocialMedia />
                </Col>
            </Row>
        )
    }
}
