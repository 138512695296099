import React, { Component } from 'react';
import { FaMinus, FaPlus } from "react-icons/fa";
import { FormattedNumber, FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Input, Row } from "reactstrap";
import { decrementPackCart, decrementProductCart, incrementPackCart, incrementProductCart, removerPackCart, removerProdutoCart, updatePackQuantityCart, updateProductQuantityCart } from "../../../../actions/miniCartActions";
import TrashIcon from './TrashIcon';

class CartItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            quantity: this.props.item.Quantidade,
            userWantsToUpdateValue: false
        }

        this.removeItem = this.removeItem.bind(this);
        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.updateItemQuantity = this.updateItemQuantity.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.item.Quantidade != this.props.item.Quantidade) {
            this.setState({
                quantity: this.props.item.Quantidade
            })
        }
    }

    filterInt = function (value) {
        if (/^(\-|\+)?([0-9]+)$/.test(value))
            return Number(value);
        return NaN;
    }

    //Verificamos se é um pack ou um produto
    isProduct() {
        try {
            var item = this.props.item;
            var isProduct = true;
            var id = null;
            if (typeof item.ID_Pack !== 'undefined' && item.ID_Pack != null) {
                isProduct = false;
                id = item.ID_Pack;
            } else {
                isProduct = true;
                id = item.ID_Produto;
            }

            return { isProduct, id };
        } catch (ex) {
            return null;
        }
    }

    //Método para remover um item
    removeItem() {
        const { id, isProduct } = this.isProduct();

        //Se for produto
        if (isProduct === true) {
            this.props.dispatch(removerProdutoCart(id)).then(data => { });
        } else {  //Se for Pack
            this.props.dispatch(removerPackCart(id)).then(data => { });
        }
    }

    //Método para lidar com as alterações no campo da quantidade
    handleChangeInput(e) {

        var inputValue = e.target.value;
        this.setState({
            quantity: inputValue,
            userWantsToUpdateValue: (inputValue != this.props.item.Quantidade) ? true : false
        })
    }

    //Atualizar quantidade no carrinho
    updateItemQuantity() {

        const { id, isProduct } = this.isProduct();
        var quantity = this.state.quantity;

        if (isNaN(this.filterInt(quantity))) {
            this.props.toast.clear();
            this.props.toast.error(this.props.intl.formatMessage({ id: "shop.cart.carrinho.quantidadeInvalida" }));
            return;
        }

        quantity = parseInt(quantity);

        if (quantity <= 0) {
            this.props.toast.clear();
            this.props.toast.error(this.props.intl.formatMessage({ id: "shop.cart.carrinho.quantidadeMaior0" }));
            return;
        }

        if (quantity > 2500) {
            this.props.toast.clear();
            this.props.toast.error(this.props.intl.formatMessage({ id: "shop.cart.carrinho.quantidadeMenor2500" }));
            return;
        }

        //Se for produto
        if (isProduct === true) {

            this.props.dispatch(updateProductQuantityCart(id, quantity));

        } else {  //Se for Pack
            this.props.dispatch(updatePackQuantityCart(id, quantity));
        }
    }

    handleKeyPress = (target) => {
        if (target.charCode === 13) {

            //Se o utilizador especificou que pretende alterar o valor por este ser diferente do primeiro definido
            if (this.state.userWantsToUpdateValue === true) {
                this.updateItemQuantity();
            }
        }
    }

    render() {
        const { item, user } = this.props;

        var currency = this.props.currency ? this.props.currency : user.currency;

        return (
            <div>
                <Row className="cartItemContainer">
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "left" }}>
                        <Row>
                            {/* Titulo do Produto*/}
                            <Col xs="12" sm="12" md="12" lg="8" className="cartItemTitleContainer">
                                <a className="cartItemProductLink" href={"/Loja/DetalheProduto/" + item.ID_Produto + "/" + item.titleUrl}>
                                    {item.Descricao}
                                </a>
                            </Col>

                            {/* Ações com o produto */}
                            <Col xs="12" sm="12" md="12" lg="4" >
                                <Row className="cartItemActionsGroupContainer">

                                    {/* Botão de Remover Produto */}
                                    <Col xs={12} sm={4} md={3} lg={1} className="cartItemActionContainer cartItemRemove" >
                                        <div style={{ cursor: "pointer" }} onClick={this.removeItem}>
                                            <TrashIcon color="#ca006a" />
                                        </div>
                                    </Col>

                                    <Col xs={12} sm={6} md={6} lg={10} className="cartItemActionContainer">
                                        <Row className="cartItemPriceQuantityContainer">
                                            <Col md={12} className="cartItemPrice">
                                                <Input className="cartItemQuantityInput" min="1" max="2500" type="number" onKeyPress={this.handleKeyPress} onChange={this.handleChangeInput}
                                                    value={this.state.quantity}></Input>
                                                {" x "}{" "}

                                                {/* Se consultor */}
                                                {user.userType === "CS" ? (
                                                    <FormattedNumber value={item.precoconsultorcomiva}
                                                        style="currency"
                                                        currency={currency}
                                                        minimumFractionDigits={2}
                                                        maximumFractionDigits={2}
                                                    />
                                                ) : (
                                                        <FormattedNumber value={item.precoclientefinalcomiva}
                                                            style="currency"
                                                            currency={currency}
                                                            minimumFractionDigits={2}
                                                            maximumFractionDigits={2}
                                                        />

                                                    )
                                                }
                                            </Col>
                                        </Row>

                                        {this.state.userWantsToUpdateValue === true ? (
                                            <Row className="cartItemUpdateQuantityContainer" onClick={this.updateItemQuantity}>
                                                <Col md={12}>
                                                    <span className="cartItemUpdateQuantityContainerLink">
                                                        <FormattedMessage id="shop.cart.carrinho.atualizarQuantidade" />
                                                    </span>
                                                </Col>
                                            </Row>
                                        ) : null}
                                    </Col >
                                </Row >
                            </Col>
                        </Row >

                    </Col >
                </Row >
                <hr style={{ textAlign: "center" }} />
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

export default injectIntl(withRouter(connect(mapStateToProps)(CartItem)));