import React, { Component } from 'react'

export default class LogoutIcon extends Component {
    render() {
        return (
            <svg width="22px" height="22px" viewBox="0 0 612 612" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <g id="_x37__32_">
                        <g>
                            <path
                                fill="#FFFFFF"
                                d="M497.25,497.25c0,21.114-17.117,38.25-38.25,38.25H76.5c-21.133,0-38.25-17.136-38.25-38.25v-382.5
                  c0-21.133,17.117-38.25,38.25-38.25H459c21.133,0,38.25,17.117,38.25,38.25v57.375h38.25V114.75c0-42.247-34.253-76.5-76.5-76.5
                  H76.5C34.253,38.25,0,72.503,0,114.75v382.5c0,42.247,34.253,76.5,76.5,76.5H459c42.247,0,76.5-34.253,76.5-76.5v-57.375h-38.25
                  V497.25z M592.875,286.875H180.043l100.272-100.272c7.478-7.458,7.478-19.584,0-27.042c-7.478-7.478-19.584-7.478-27.042,0
                  L121.329,291.522c-3.997,3.978-5.699,9.256-5.432,14.478c-0.268,5.221,1.435,10.5,5.413,14.478l131.943,131.943
                  c7.458,7.478,19.584,7.478,27.042,0c7.478-7.459,7.478-19.584,0-27.043L180.043,325.125h412.832
                  c10.557,0,19.125-8.568,19.125-19.125C612,295.443,603.432,286.875,592.875,286.875z"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}
