class Routes {
  constructor() {

    var mainWebsiteURL = process.env.REACT_APP_MAIN_WEBSITE_URL;
    var myWebsiteURL = process.env.REACT_APP_SHOP_WEBSITE_URL;
    var oldWebsiteURL = process.env.REACT_APP_OLD_WEBSITE_URL;

    this.home = {
      home: "/",
      relatarResultados: "/Home/RelatarResultados",
      recomendarDesafios: "/Home/RecomendarDesafios",
      conhecerResultados: "/Home/ConhecaResultados",
      error: "/Home/Error"
    };

    this.contactos = {
      contactos: mainWebsiteURL + "contactos"
    };

    this.institucional = {
      quemSomos: "/institucional/quemSomos/porqueihtp"
    };
    this.account = {
      login: "/Account/LogOn",
      recuperar: "/Account/Recuperar",
      registo: "/MyIHTP/Create"
    };

    this.myIHTP = {
      home: myWebsiteURL + ""
    };

    this.shop = {
      home: "/Loja",
      loja: "/loja",
      condicoesPagamento: "/Conteudo/Ver/FormasPagamento",
      subscricoes: "/Loja/Subscricoes",
      packs: "/Loja/PacksEspeciais",
      promocoes: "/Loja/Promocoes",
      novidades: "/Loja/Novidades",
      catalogo: "/Loja/Catalogo/:category",
      detalheproduto: "/Loja/DetalheProduto/:id",
      cart: "/Carrinho",
      amorparasempre: "/Pack/428/programa-amor-para-sempre",
      vitaminas:
        "/Pack/841/vitaminas-para-a-mente---subscricao-anual-de-audio-on-line",
      coaching: "/Produto/100/piep---coaching-para-a-excelencia-pessoal",
      nascidoparatriunfar:
        "/Produto/1110/curso-nascidos-para-triunfar---em-exclusivo-na-sua-escola",
      pnlLisboa:
        "/Curso/3111/lisboa-certificacao-internacional-practitioner-de-pnl",
      pnlLisboa2:
        "/Curso/3118/lisboa-ihtp-coaching-practitioner---certificacao-internacional-de-coaching",
      altodesempenho:
        "/Curso/3292/porto-ihtp-leader---lideranca-e-motivacao-de-equipas-de-alto-desempenho-2as-feiras",
      ansiedade: "/Curso/3355/lisboa-oficina-juvenil-da-ansiedade-a-felicidade",
      pn2Porto: "/Curso/3357/porto-i-have-the-power---alto-desempenho-com-pnl",
      altodesempenhoPorto:
        "/Curso/3370/porto-ihtp-sales-mastery---alto-desempenho-em-vendas-com-pnl",
      pnlPorto:
        "/Curso/3109/porto-certificacao-internacional-practitioner-de-pnl",
      comentario: "/Loja/Comentario",
      recomendar: "/Loja/RecomendarAmigo",
      checkout: "/Shop/Checkout"
    };

    this.consultor = {
      consultor: mainWebsiteURL + "ser-consultor/"
    };

    this.empresa = {
      fundador: mainWebsiteURL + "fundador/",
      testemunhos: mainWebsiteURL + "testemunhos-lista/",
      noticias: mainWebsiteURL + "noticias/",
      imprensa: mainWebsiteURL + "tv-radio-e-imprensa-pagina/",
      protecaoDados: mainWebsiteURL + "protecao-de-dados-e-utilizacao/",
      cancelamentoGarantia: mainWebsiteURL + "cancelamento-e-garantia-de-satisfacao/",
      empresa: mainWebsiteURL + "empresa/",
      associacao: mainWebsiteURL + "responsabilidade-social/",
    };

    this.empresas = {
      empresas: mainWebsiteURL + "empresas/",
      solucoes: mainWebsiteURL + "solucoes-a-medida/",
      palestras: mainWebsiteURL + "palestras/",
      coaching: mainWebsiteURL + "coaching/",
    }

    this.particulares = {
      oferta: mainWebsiteURL + "particulares/",
      cursosEventos: "/Loja/Catalogo/CS",
      produtos: "/Loja/Catalogo/TS",
      coaching: "/Loja/Catalogo/SV"
    }

    this.consultor = {
      oque: mainWebsiteURL + "ser-consultor/#consultor",
      beneficios: mainWebsiteURL + "ser-consultor/#beneficios",
      passoAPasso: mainWebsiteURL + "ser-consultor/#passoapasso",
    }

    this.recursosGratis = {
      ebooks: mainWebsiteURL + "e-books/",
      desafios: mainWebsiteURL + "desafios-para-excelencia/",
      afiliados: mainWebsiteURL + "recursos-gratis/",
      frases: mainWebsiteURL + "frases-poderosas/",
    };

    this.nossoorgulho = {
      digupulse: "https://www.digipulse.pt",
      businessconsulting: "http://ihtpconsulting.com",
      wonderlives: "/Loja/Catalogo/SA",
      nascidosparatriunfar: "https://nascidosparatriunfar.webnode.pt"
    };

  }
}

var RoutesObject = new Routes();

export default RoutesObject;
