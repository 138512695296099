import { IconButton, NativeSelect } from "@material-ui/core";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import $ from "jquery";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { withCookies } from "react-cookie";
import { isMobile, isTablet } from "react-device-detect";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastr";
import { Label } from "reactstrap";
import { changeCategoryFE, getCaterogyOrderByFields, getProducts, removeProductStockNotificationInProductList, setProductStockNotificationInProductList, removeStockNotification } from "../../../../src/actions/miniCartActions";
import { getEmail } from "../../../actions/userActions";
import { funnelStep } from "../../../Analytics/DataLayer";
import MenuButton from "../Header/Components/MenuButton";
import Loading from "../Loading/Loading";
import "./Paginate.css";
import Product from "./Product";
import "./ProductGrid.css";
import ProductGridCategoryItem from "./ProductGridCategoryItem";

let container;
class ProductGrid extends Component {
  constructor(props) {
    super(props);

    funnelStep(3);

    this.state = {
      data: [],
      offset: 0,
      procurarInput: null,
      categoriasAca: [],
      orderSelected: '',
      orderAsc: true,
      orderFields: [],
      menuShop: true,
      width: 0,
      email: null
    };

    this.onchangeFilterShop = this.onchangeFilterShop.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.setDefaultOrderField = this.setDefaultOrderField.bind(this);
    this.loadCommentsFromServer = this.loadCommentsFromServer.bind(this);
    this.openMenuShop = this.openMenuShop.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.closeMenuShop = this.closeMenuShop.bind(this);
    this.afterNotificationCreate = this.afterNotificationCreate.bind(this);
    this.afterNotificationRemove = this.afterNotificationRemove.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  //Alterar estado do menu lateral para o estado inverso
  openMenuShop = async e => {
    if (!this.state.menuShop) {
      $(".categorias").css("display", "block");
      this.setState({
        menuShop: true
      });
    } else {
      $(".categorias").css("display", "none");
      this.setState({
        menuShop: false
      });
    }
  };

  //Fechar menu lateral 
  closeMenuShop() {
    $(".categorias").css("display", "none");
    this.setState({
      menuShop: false
    });
  }

  // Carregamento da loja com produtos iniciais
  loadCommentsFromServer() {
    var promise = new Promise((resolve, reject) => {

      let cat = this.props.categories && this.props.categories.selected
        ? this.props.categories.selected
        : this.props.category;

      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get("search");

      this.props.dispatch(getProducts(cat, myParam, this.state.orderSelected, this.state.orderAsc)).then(data => {
        // Array para adicionar as impressions
        var prodAnalytics = [];

        // Objecto Produto aux      
        var productAux = {};

        // Percorrer todos os produtos
        if (data.products.produtos && data.products.produtos.length > 0) {
          data.products.produtos.map(function (x, index) {
            productAux = {
              // Id de produto
              id: x.ID_Produto !== null ? "P" + x.ID_Produto : "K" + x.ID_Pack,

              // Nome de produto
              name: x.Descricao,

              // Categoria do produto
              category:
                x.ID_TipoProduto !== null
                  ? "P-" + x.ID_TipoProduto
                  : "K-" + x.ID_TipoPack,

              // Lista do produto
              list: data.products.ID_TipoProduto,

              // Posição do produto
              position: index + 1
            };

            // Adicionar ao array de produtos
            prodAnalytics.push(productAux);
          });
        }
        // Obter o datalayer
        window.dataLayer = window.dataLayer || [];

        // Adicionar novo evento no datalayer
        window.dataLayer.push({
          event: "eec.impressionView",
          ecommerce: {
            impressions: prodAnalytics
          }
        });

        resolve();
      });
    });
    return promise;
  }

  // Quando os components são montados
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    let cat = this.props.categories && this.props.categories.selected
      ? this.props.categories.selected
      : this.props.category;

    this.loadOrderFields(cat)
      .then(this.setDefaultOrderField)
      .then(this.loadCommentsFromServer)

    this.props.dispatch(changeCategoryFE(cat));

    //VERIFICAR SE O CLIENTE CLICOU NO LINK PARA REMOVER A NOTIFICAÇÃO
    const urlParams = new URLSearchParams(window.location.search);
    const hash = urlParams.get("id");
    if (hash) {
      this.removeStockNotification(hash);
    }


    this.getUserEmail();
  }

  removeStockNotification(hash) {
    var promise = new Promise((resolve, reject) => {
      removeStockNotification(hash).then(result => {
        if (!result.success) {
          container.clear();
          container.error(`${result.message}`);
        }
        else {
          container.clear();
          container.success(`${result.message}`);
        }
        resolve(result);
      }).catch(err => {
        container.error("Ocorreu um erro ao remover a notificação. Por favor tente mais tarde.");
        reject(err);
      })
    });

    return promise;
  }

  //Colocar o campo default de ordenaçaõ
  setDefaultOrderField() {
    var promise = new Promise((resolve, reject) => {
      if (this.state.orderFields.length > 0) {
        this.setState(
          prevState => ({
            ...prevState,
            orderSelected: this.state.orderFields[0].Id,
            orderAsc: true
          })
          , () => {
            resolve();
            return;
          });
      } else {
        resolve();
      }
    });
    return promise;
  }

  closeCat(className) {
    $(className).css("display", "none");
    let botaoMaisClass = className + "mais";
    let botaoMenosClass = className + "menos";
    $(botaoMaisClass).css("display", "unset");
    $(botaoMenosClass).css("display", "none");
  }

  openCat(className) {
    $(className).css("display", "block");
    let botaoMaisClass = className + "mais";
    let botaoMenosClass = className + "menos";
    $(botaoMaisClass).css("display", "none");
    $(botaoMenosClass).css("display", "unset");
  }

  onchangeFilterShop = async e => {
    e.stopPropagation();
    var id = e.target.value;
    alert(id);
  };

  onchangeSearchShop = async e => {
    e.stopPropagation();
    alert(this.state.procurarInput);
  };

  handleInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        [name]: value
      }), () => {
        if (name === 'orderSelected') {
          this.loadCommentsFromServer()
            .then(() => {
              //Se estivermos num ecrã pequeno, temos que fechar o menu
              if (this.state.width <= 540) {
                this.closeMenuShop();
              }
            });
        }
      }
    );
  }

  //Ao clicar no botão para mudar a categoria
  onClickCategory = category => async e => {
    e.stopPropagation();
    this.setState(
      prevState => ({
        ...prevState,
        orderSelected: '',
        orderAsc: false
      })
    );

    //Se estivermos num ecrã pequeno, fazesmos scroll para cima para ver a loading bar
    if (this.state.width <= 540) window.scrollTo(0, 0)

    this.props.dispatch(changeCategoryFE(category))
      .then(() => this.loadOrderFields(category))
      .then(this.setDefaultOrderField)
      .then(this.loadCommentsFromServer)
      .then(() => {

        //Se estivermos num ecrã pequeno, temos que fechar o menu
        if (this.state.width <= 540) {
          this.closeMenuShop();
        }
      });

  };

  //Carregar os campos de ordenação para cada categoria
  loadOrderFields(category) {
    var promise = new Promise((resolve, reject) => {
      getCaterogyOrderByFields(category)
        .then(result => {
          if (result.success === true) {
            this.setState(
              prevState => ({
                ...prevState,
                orderFields: result.obj
              }), () => {
                resolve();
              }
            );
          } else {
            this.setState(
              prevState => ({
                ...prevState,
                orderFields: []
              })
              , () => {
                resolve();
              });
          }
        })
        .catch(err => {
          reject();
        });
    });
    return promise;
  }

  //Método para lidar com a alteração Ascendente ou descendente do artigos
  changeOrderAscOrDesc = value => async e => {
    this.setState(
      prevState => ({
        ...prevState,
        orderAsc: value
      }), () => {
        this.loadCommentsFromServer()
          .then(() => {
            //Se estivermos num ecrã pequeno, temos que fechar o menu
            if (this.state.width <= 540) {
              this.closeMenuShop();
            }
          });
      }
    );
  };

  //Método a ser chamado depois de criar a notificação
  afterNotificationCreate(result) {
    try {

      //Se conseguirmos criar com sucesso
      if (result.success === true) {
        container.clear();
        container.success(result.message);
        this.props.dispatch(setProductStockNotificationInProductList(result.obj.productId, result.obj.packId, result.obj.hash));
        //Se não conseguirmos criar 
      } else {
        container.clear();
        container.error(result.message);
      }
    } catch (err) {
      container.clear();
      container.error("Erro ao criar notificação. Por favor contacte a equipa de Suporte");
    }
  }

  //Método a ser chamado depois de remover a notificação
  afterNotificationRemove(result) {
    try {

      //Se conseguirmos remover com sucesso
      if (result.success === true) {
        container.clear();
        container.success(result.message);
        this.props.dispatch(removeProductStockNotificationInProductList(result.obj.productId, result.obj.packId));
        //Se não conseguirmos remover 
      } else {
        container.clear();
        container.error(result.message);
      }
    } catch (err) {
      container.clear();
      container.error("Erro ao remover a notificação. Por favor contacte a equipa de Suporte");
    }
  }

  // Método para ir buscar o email do utilizador para aparecer s
  getUserEmail() {
    getEmail().then(result => {
      this.setState({
        email: result.Email
      })
    }).catch(err => {
      this.setState({
        email: null
      })
    })
  }

  render() {
    const { products, categories, user, loading } = this.props;
    const shareUrl = "https://www.ihavethepower.net" + window.location.pathname;

    let idCatSel = categories
      ? categories.selected
        ? categories.selected
        : null
      : null;

    return (
      <div>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />

        {loading ? <Loading /> : (
          <div>

            {/* Linha com Nome do Produto */}
            <Row style={{ display: "flex", alignContent: "center", alignItems: "center" }}>

              {/* Titulo da categoria */}
              <Col xs="10" sm="10" md="12" lg="12" xl={12} style={{ display: "flex", alignItems: "center", alignContent: "center", justifyContent: "center", padding: "0", margin: "0" }}>
                <h3 style={{ textAlign: "center", padding: "0", margin: "0" }}>
                  {categories
                    ? categories.categoryDescription
                      ? categories.categoryDescription
                      : null
                    : null}
                </h3>
              </Col>

              {/* Menu para abrir */}
              <Col xs="2" sm="2" md="12" lg="12" xl={12} style={{ padding: "0" }}>
                <span className='categoriesButtonDisplay' onClick={() => this.openMenuShop()}>
                  <MenuButton color="#034275" />
                </span>
              </Col>
            </Row>

            {/* Categorias e Listagem de Produtos */}
            <Row style={{ marginTop: "50px" }}>

              {/* Categorias dos Produtos */}
              <Col xs="12" sm="12" md="4" lg="3" style={{ textAlign: "center" }} className="categorias">
                <Router>
                  <ul>
                    {/*  */}

                    <ProductGridCategoryItem startDate={new Date(2021, 1, 8, 0, 0, 0)} endDate={new Date(2021, 1, 14, 23, 59, 59)} selectedBackgroundColor="#DB0000" backgroundColor="red"
                      isSelected={idCatSel === 'DN'} textSource="Dia dos Namorados" onClick={this.onClickCategory("DN")} linkTo={"/Loja/Catalogo/DN"} />

                    <ProductGridCategoryItem startDate={new Date(2020, 11, 25, 0, 0, 0)} endDate={new Date(2020, 11, 31, 23, 59, 59)} selectedBackgroundColor="#DB0000" backgroundColor="red"
                      isSelected={idCatSel === '2E1'} textSource="Oferta 2 em 1" onClick={this.onClickCategory("2E1")} linkTo={"/Loja/Catalogo/2E1"} />

                    <ProductGridCategoryItem startDate={new Date(2020, 11, 11, 0, 0, 0)} endDate={new Date(2020, 11, 24, 23, 59, 59)} selectedBackgroundColor="#DB0000" backgroundColor="red"
                      isSelected={idCatSel === 'PN'} textSource="Packs Natal" onClick={this.onClickCategory("PN")} linkTo={"/Loja/Catalogo/PN"} />

                    <ProductGridCategoryItem startDate={new Date(2020, 10, 23, 0, 0, 0)} endDate={new Date(2020, 10, 30, 23, 59, 59)} selectedBackgroundColor="#DB0000" backgroundColor="red"
                      isSelected={idCatSel === 'BF'} textSource="Black Week" onClick={this.onClickCategory("BF")} linkTo={"/Loja/Catalogo/BF"} />

                    <ProductGridCategoryItem isSelected={idCatSel === 'TS'} textSource="productgrid.selecao1" onClick={this.onClickCategory("TS")} linkTo={"/Loja/Catalogo/TS"} />
                    <ProductGridCategoryItem isSelected={idCatSel === 'PR'} textSource="productgrid.selecao3" onClick={this.onClickCategory("PR")} linkTo={"/Loja/Catalogo/PR"} />
                    <ProductGridCategoryItem isSelected={idCatSel === 'NV'} text="Novidades" onClick={this.onClickCategory("NV")} linkTo={"/Loja/Catalogo/NV"} />
                    <ProductGridCategoryItem isSelected={idCatSel === 'OPK'} text="Ofertas Packs" onClick={this.onClickCategory("OPK")} linkTo={"/Loja/Catalogo/OPK"} />
                    <ProductGridCategoryItem isSelected={idCatSel === 'PKS'} text="Vitaminas para a mente" onClick={this.onClickCategory("PKS")} linkTo={"/Loja/Catalogo/PKS"} />
                    <ProductGridCategoryItem isSelected={idCatSel === 'SX'} text="Success Box" onClick={this.onClickCategory("SX")} linkTo={"/Loja/Catalogo/SX"} />
                    <hr />
                    <h6 style={{ textAlign: "center" }}>
                      <FormattedMessage id="productgrid.categoriasTitle" />
                    </h6>
                    <hr />
                    {categories && categories.list
                      ? categories.list.map((categorie, j) => {
                        return (<ProductGridCategoryItem isSelected={categorie.Descricao ===
                          categories.categoryDescription} text={categorie.Descricao} onClick={this.onClickCategory(categorie.ID_TipoProduto)} linkTo={"/Loja/Catalogo/" + categorie.ID_TipoProduto} />)
                      })
                      : null}
                    <hr />
                    <h6 style={{ textAlign: "center" }}>
                      <FormattedMessage id="productgrid.academiasTitle" />
                    </h6>
                    <hr />

                    <ProductGridCategoryItem backgroundColor="#ED1A22" textSource="productgrid.academia1" onClick={this.onClickCategory("PNL")} linkTo={"/Loja/Catalogo/PNL"} />
                    <ProductGridCategoryItem backgroundColor="#A065AB" textSource="productgrid.academia2" onClick={this.onClickCategory("WNS")} linkTo={"/Loja/Catalogo/WNS"} />
                    <ProductGridCategoryItem backgroundColor="#EC028C" textSource="productgrid.academia3" onClick={this.onClickCategory("ENS")} linkTo={"/Loja/Catalogo/ENS"} />
                    <ProductGridCategoryItem backgroundColor="#71BF44" textSource="productgrid.academia4" onClick={this.onClickCategory("DES")} linkTo={"/Loja/Catalogo/DES"} />
                    <ProductGridCategoryItem backgroundColor="#0394DA" textSource="productgrid.academia5" onClick={this.onClickCategory("COR")} linkTo={"/Loja/Catalogo/COR"} />
                    <ProductGridCategoryItem backgroundColor="#F68A1F" textSource="productgrid.academia6" onClick={this.onClickCategory("CHG")} linkTo={"/Loja/Catalogo/CHG"} />
                    <ProductGridCategoryItem backgroundColor="#BB8C55" textSource="productgrid.academia7" onClick={this.onClickCategory("LID")} linkTo={"/Loja/Catalogo/LID"} />
                  </ul>
                </Router>
              </Col>

              {/* Listagens de Produtos */}
              <Col xs="12" sm="12" md="8" lg="9" className="productListContainer" style={{ textAlign: "center" }}>
                {this.state.orderFields.length > 0 ? (
                  <Row className='productsOrderContainer'>
                    <Col style={{ padding: "0" }} lg={12} md={12} sm={12} xs={12}>
                      <div style={{ marginBottom: "20px", fontSize: "15px" }}>
                        <Label style={{ margin: '0px 10px 0px 0px' }}>Ordenar por:</Label>
                        <NativeSelect style={{ fontFamily: 'Montserrat, sans-serif' }} name="orderSelected" onChange={this.handleInput} value={this.state.orderSelected}>
                          {this.state.orderFields.map(field => {
                            return (<option value={field.Id}>{field.Nome}</option>)
                          })}
                        </NativeSelect>
                        {this.state.orderAsc === false ?
                          <IconButton onClick={this.changeOrderAscOrDesc(true)} title="Ver Ascendente" >
                            <ArrowDownwardIcon />
                          </IconButton>
                          :
                          <IconButton onClick={this.changeOrderAscOrDesc(false)} title="Ver Descendente">
                            <ArrowUpwardIcon />
                          </IconButton>
                        }
                      </div>
                    </Col>
                  </Row>)
                  : null}


                {products && products.produtos ? (
                  products.produtos.length > 0 ? (
                    products.produtos.map((product, j) => {
                      var legn = this.state.categoriasAca.length - 1;
                      var titulo = product.DescricaoTipoProduto
                        ? product.DescricaoTipoProduto
                        : product.DescricaoTipoPack;
                      var id_tipo = product.ID_TipoProduto
                        ? product.ID_TipoProduto
                        : product.ID_TipoPack;
                      let cat = this.props.categories.selected
                        ? this.props.categories.selected
                        : this.props.category;
                      this.state.categoriasAca.push(titulo);
                      if (
                        cat === "PNL" ||
                        cat === "WNS" ||
                        cat === "ENS" ||
                        cat === "DES" ||
                        cat === "COR" ||
                        cat === "CHG" ||
                        cat === "LID" ||
                        cat === "PES" ||
                        cat === "BF" ||
                        cat === "PN"
                      ) {
                        return (
                          <div>

                            {/*Quando existes subcategorias */}
                            {this.state.categoriasAca[legn] !== titulo ? (
                              <Row>
                                <Col xs="10" sm="10" md="10" lg="10">
                                  <h3 style={{ textAlign: "left", maxWidth: "90%" }}>
                                    {titulo}{" "}
                                  </h3>
                                </Col>
                                <Col xs="2" sm="2" md="2" lg="2" style={{ textAlign: "right" }}>
                                  <span
                                    onClick={() => {
                                      this.closeCat("." + id_tipo);
                                    }}
                                  >
                                    <svg
                                      className={id_tipo + "menos"}
                                      style={{ display: "unset" }}
                                      width="25px"
                                      height="25px"
                                      viewBox="0 0 60 60"
                                      version="1.1"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill="rgb(202, 0, 106)"
                                        d="M0,0v60h60V0H0z M58,58H2V2h56V58z"
                                      />
                                      <rect
                                        fill="rgb(202, 0, 106)"
                                        x="9"
                                        y="29"
                                        width="42"
                                        height="2"
                                      />
                                    </svg>
                                  </span>
                                  <span
                                    onClick={() => {
                                      this.openCat("." + id_tipo);
                                    }}
                                  >
                                    <svg
                                      className={id_tipo + "mais"}
                                      style={{ display: "none" }}
                                      width="25px"
                                      height="25px"
                                      viewBox="0 0 60 60"
                                      version="1.1"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill="rgb(202, 0, 106)"
                                        d="M0,0v60h60V0H0z M58,58H2V2h56V58z"
                                      />
                                      <polygon
                                        fill="rgb(202, 0, 106)"
                                        points="29,51 31,51 31,31 51,31 51,29 31,29 31,9 29,9 29,29 9,29 9,31 29,31 	"
                                      />
                                    </svg>
                                  </span>
                                </Col>
                                <Col lg="12">
                                  <hr />
                                </Col>
                              </Row>
                            ) : null}


                            <div style={{ marginBottom: "30px" }}>
                              <Product
                                defaultEmail={this.state.email}
                                afterNotificationCreate={this.afterNotificationCreate}
                                afterNotificationRemove={this.afterNotificationRemove}
                                notification={product.Notificacao}
                                notificationHash={product.NotificacaoHash}
                                user={user}
                                id={product.ID_Produto ? product.ID_Produto : product.ID_Pack}
                                esgotado={product.Esgotado}
                                name={product.Descricao}
                                stars="5"
                                price={product.precoclientesempromocao}
                                priceWithDiscount={product.precoclientefinalcomiva}
                                priceConsulor={product.precoconsultorsempromocao}
                                precoclientesempromocao={product.precoclientesempromocao}
                                precoclientefinalcomiva={product.precoclientefinalcomiva}
                                precoconsultorsempromocao={product.precoconsultorsempromocao}
                                tituloURL={product.titleUrl}
                                precoconsultorcomiva={product.precoconsultorcomiva}
                                classDiv={id_tipo}
                                product={product}
                                src={"https://doremon.ihavethepower.net/assets/uploads/" + product.urlficheiro}
                                npts={product.npts}
                                inscrever={cat === "PG" ? true : false}
                              />
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div style={{ marginBottom: "30px" }}>
                            <Product
                              defaultEmail={this.state.email}
                              afterNotificationCreate={this.afterNotificationCreate}
                              afterNotificationRemove={this.afterNotificationRemove}
                              notification={product.Notificacao}
                              notificationHash={product.NotificacaoHash}
                              user={user}
                              id={product.ID_Produto ? product.ID_Produto : product.ID_Pack}
                              esgotado={product.Esgotado}
                              name={product.Descricao}
                              stars="5"
                              price={product.precoclientesempromocao}
                              priceWithDiscount={product.precoclientefinalcomiva}
                              priceConsulor={product.precoconsultorsempromocao}
                              precoclientesempromocao={product.precoclientesempromocao}
                              precoclientefinalcomiva={product.precoclientefinalcomiva}
                              precoconsultorsempromocao={product.precoconsultorsempromocao}
                              tituloURL={product.titleUrl}
                              precoconsultorcomiva={product.precoconsultorcomiva}
                              product={product}
                              src={"https://doremon.ihavethepower.net/assets/uploads/" + product.urlficheiro}
                              npts={product.npts}

                              inscrever={cat === "PG" ? true : false}
                            />
                          </div>
                        );
                      }
                    })
                  ) : (
                    <Row>
                      <Col md={8} lg={8}>
                        <h2>Não existem produtos disponiveis!</h2>
                      </Col>
                    </Row>
                  )
                ) : (
                  <Row>
                    <Col md={8} lg={8}>
                      <h2>Não existem produtos disponiveis!</h2>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row >
          </div >
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    products: state.products,
    categories: state.categories,
    user: state.user,
    filters: state.filters,
    loading: state.loading
  };
};

export default withCookies(connect(mapStateToProps)(ProductGrid));