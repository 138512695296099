import TextField from "material-ui/TextField";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { addObservacao } from "../../../actions/miniCartActions";
import "./Checkout.css";

class Observacao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      observacoes: "",
    };
  }

  componentDidMount() { }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
    this.props.dispatch(addObservacao(event.target.value)).then(data => { });
  };

  render() {
    return (
      <div className="checkoutBox">
        <Row style={{ minHeight: "100%" }}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className="observacaoBox">
              <h5>
                {this.props.intl.formatMessage({
                  id: "observacoes.title",
                })}
              </h5>
              <TextField
                label="Multiline"
                name="observacoes"
                fullWidth={true}
                multiLine={true}
                rowsMax={8}
                value={this.state.multiline}
                onChange={this.handleChange("multiline")}
                placeholder={this.props.intl.formatMessage({
                  id: "observacoes.text",
                })}
              />
            </div>
            <br />
            <br />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default injectIntl(connect(mapStateToProps)(Observacao));
