import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Routes from "../../../../helpers/Routes";
import CartIcon from "../Icons/CartIcon";
import CartQuantityIcon from "../Icons/CartQuantityIcon";

export default class CartHeader extends Component {
  render() {
    return (
      <div
        className="cartIcon"
        style={{
          display: "inline-flex",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <NavLink to={Routes.shop.cart}
          style={{ minWidth: "67px" }}
          className="aComprar">

          <CartIcon />

          <CartQuantityIcon />

        </NavLink>
      </div>
    );
  }
}