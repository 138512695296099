import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import $ from "jquery";
import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { changeCurrencyFE, logout, sideBarMenu } from "../../../actions/miniCartActions";
import Routes from "../../../helpers/Routes"; // Caminhos para as páginas
import SocialMedia from "../../SocialMedia/SocialMedia";
import "./Menu.scss";
import CrossDomainEventHandler from "../../../helpers/CrossDomainEventHandler";
import SelectLanguage from "../Header/Components/SelectLanguage";
import CloseIcon from "./Icons/CloseIcon";
import ProfileIcon from "./Icons/ProfileIcon";
import CustomMenuItem from "./Components/CustomMenuItem";
import LogoutIcon from "./Icons/LogoutIcon";
import HomeIcon from "./Icons/HomeIcon";
import RegisterIcon from "./Icons/RegisterIcon";
import ShopIcon from "./Icons/ShopIcon";
import BlogIcon from "./Icons/BlogIcon";
import BeConsultantIcon from "./Icons/BeConsultantIcon";
import ContactsIcon from "./Icons/ContactsIcon";
import SelectCurrency from "../Header/Components/SelectCurrency";

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currencies: [],
      textoPesquisa: {
        texto: ""
      }
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleInputPesquisar = this.handleInputPesquisar.bind(this);
  }

  logoutAccount() {
    this.props.dispatch(logout()).then(() => {
      CrossDomainEventHandler.sendEvent();
      window.location = "/Loja"
    });
  }

  handleInput(e) {
    let value = e.target.value;
    this.props.dispatch(changeCurrencyFE(value));
  }

  handleInputPesquisar(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        textoPesquisa: {
          ...prevState.textoPesquisa,
          [name]: value
        }
      }),
      () => console.log(this.state.textoPesquisa)
    );
  }

  componentDidMount() {
    // this.props.dispatch(fetchCurrencyFunction());
  }

  onSetSidebarOpen(open) {
    this.props.dispatch(sideBarMenu(open));
    $("#sidebarMenu").css("height", "0px");
  }

  onchangeSearchShop = () => {
    window.location =
      "/Loja/Catalogo/PES?search=" + this.state.textoPesquisa.texto;
  };

  render() {
    const { user, open, currencies } = this.props;
    return (
      <div className="bp3-md-menu">

        {/* Parte de cima título e icon para fechar */}
        <Row className="sidebarMenuTitleContainer">

          {/* Empty Space */}
          <Col xs="2" sm="2" md="4" lg="4" />

          {/* Título */}
          <Col xs="8" sm="8" md="4" lg="4">
            <a href={Routes.home.home}>
              <img
                src="/assets/images/md-logo-ihtpweb-05sombra-1.png"
                class="bp3-md-menu-logoihtpweb05sombra1"
              />
            </a>
          </Col>

          {/* Símbolo de fechar */}
          <Col xs="1" sm="1" md="4" lg="4" onClick={() => this.onSetSidebarOpen(false)}>
            <CloseIcon />
          </Col>

        </Row>
        <br />
        <br />
        <br />
        <Container>
          {user.userID ? (
            <div>

              {/* Menu MyIHTP */}
              <Row className="sidebarMenuItemContainer">
                <Col xs="5" sm="5" md="3" lg="3">
                  <CustomMenuItem
                    isClickable={false}
                    icon={<ProfileIcon />}
                    link={process.env.REACT_APP_MY_WEBSITE_URL}
                    labelId={"menu.MyIHTP"}
                  />
                </Col>
              </Row>

              <br />

              {/* Menu Sair */}
              <Row className="sidebarMenuItemContainer">
                <Col xs="5" sm="5" md="3" lg="3">
                  <CustomMenuItem
                    icon={<LogoutIcon />}
                    link={null}
                    labelId={"menu.Sair"}
                    isClickable={true}
                    onClick={() => this.logoutAccount()}
                  />
                </Col>
              </Row>

            </div>
          ) : (
            <div>

              {/*Menu de Entrar */}
              <Row className="sidebarMenuItemContainer">
                <Col xs="5" sm="5" md="3" lg="3">
                  <CustomMenuItem
                    isClickable={false}
                    icon={<RegisterIcon />}
                    link={Routes.account.login}
                    labelId={"menu.Entrar"}
                  />
                </Col>
              </Row>
              <br />

              {/* Menu de Registar */}
              <Row className="sidebarMenuItemContainer">
                <Col xs="5" sm="5" md="3" lg="3">
                  <CustomMenuItem
                    isClickable={false}
                    icon={<RegisterIcon />}
                    link={Routes.account.registo}
                    labelId={"menu.Registar"}
                  />
                </Col>
              </Row>

            </div>
          )}
        </Container>
        <br />
        <br />
        <Container>

          {/* Menu Inicial */}
          <Row className="sidebarMenuItemContainer">
            <Col xs="5" sm="5" md="3" lg="3">
              <CustomMenuItem
                isClickable={false}
                icon={<HomeIcon />}
                link={Routes.home.home}
                labelId={"header.mainPage"}
              />
            </Col>
          </Row>

          <p />

          {/* Menu Loja */}
          <Row className="sidebarMenuItemContainer">
            <Col xs="5" sm="5" md="3" lg="3">
              <CustomMenuItem
                isClickable={false}
                icon={<ShopIcon />}
                link={Routes.shop.home}
                labelId={"menu.Loja"}
              />
            </Col>
          </Row>

          <p />

          {/* Menu Blog */}
          <Row className="sidebarMenuItemContainer">
            <Col xs="5" sm="5" md="3" lg="3">
              <CustomMenuItem
                isClickable={false}
                icon={<BlogIcon />}
                link={"https://blog.ihavethepower.net"}
                labelId={"menu.Blog"}
              />
            </Col>
          </Row>

          <p />

          {/* Menu Ser Consultor */}
          <Row className="sidebarMenuItemContainer">
            <Col xs="5" sm="5" md="3" lg="3">
              <CustomMenuItem
                isClickable={false}
                icon={<BeConsultantIcon />}
                link={Routes.consultor.consultor}
                labelId={"menu.Serconsultor"}
              />
            </Col>
          </Row>
          <p />

          {/* Menu Contactos */}
          <Row className="sidebarMenuItemContainer">
            <Col xs="5" sm="5" md="3" lg="3">
              <CustomMenuItem
                isClickable={false}
                icon={<ContactsIcon />}
                link={Routes.contactos.contactos}
                labelId={"menu.Contactos"}
              />
            </Col>
          </Row>

          <p />

          <br />
          {/* Selecionar a moeda */}
          <Row className="sidebarMenuItemContainer">
            <Col xs="5" sm="5" md="3" lg="3" style={{ margin: "0px" }}>
              <SelectCurrency />
            </Col>
          </Row>

          <p />

          {/* Parte de selecionar idioma */}
          <Row className="sidebarMenuItemContainer">
            <Col xs="5" sm="5" md="3" lg="3">
              <SelectLanguage isMobile={isMobile} callback={
                () => this.onSetSidebarOpen(false)
              } />
            </Col>
          </Row>

          <br />

        </Container>
        <br />
        <br />
        <Row>
          <Col md="1" lg="1" />
          <Col xs="12" sm="12" md="5" lg="5" className="sobrenos">
            <a
              href={Routes.empresa.sobreNos}
              style={{ color: "rgba(3, 66, 117, 1.0)" }}
              class="bp3-md-menu-frases-poderosas"
            >
              <FormattedMessage id="menu.Sobrenos" />
            </a>
          </Col>
          <Col xs="12" sm="12" md="5" lg="5" className="protecaodados">
            <a
              href={Routes.empresa.protecaoDados}
              style={{ color: "rgba(3, 66, 117, 1.0)" }}
              class="bp3-md-menu-frases-poderosas"
            >
              <FormattedMessage id="menu.protecaodados" />
            </a>
          </Col>
          <Col md="1" lg="1" />
        </Row>
        <Row>
          <Col md="1" lg="1" />
          <Col xs="12" sm="12" md="5" lg="5" className="fundador">
            <a
              href={Routes.empresa.fundador}
              style={{ color: "rgba(3, 66, 117, 1.0)" }}
              class="bp3-md-menu-frases-poderosas"
            >
              <FormattedMessage id="menu.Fundador" />
            </a>
          </Col>
          <Col xs="12" sm="12" md="5" lg="5" className="cancelamentoegarantia">
            <a
              href={Routes.empresa.cancelamentoGarantia}
              style={{ color: "rgba(3, 66, 117, 1.0)" }}
              class="bp3-md-menu-frases-poderosas"
            >
              <FormattedMessage id="menu.cancelamento" />
            </a>
          </Col>
          <Col md="1" lg="1" />
        </Row>
        <Row>
          <Col md="1" lg="1" />
          <Col xs="12" sm="12" md="5" lg="5" className="testemunhos">
            <a
              href={Routes.empresa.testemunhos}
              style={{ color: "rgba(3, 66, 117, 1.0)" }}
              class="bp3-md-menu-frases-poderosas"
            >
              <FormattedMessage id="menu.Testemunhos" />
            </a>
          </Col>
          <Col xs="12" sm="12" md="5" lg="5" className="desafios">
            <a
              href={Routes.recursosGratis.desafios}
              style={{ color: "rgba(3, 66, 117, 1.0)" }}
              class="bp3-md-menu-frases-poderosas"
            >
              <FormattedMessage id="menu.desafios" />
            </a>
          </Col>
          <Col md="1" lg="1" />
        </Row>
        <Row>
          <Col md="1" lg="1" />
          <Col xs="12" sm="12" md="5" lg="5" className="noticias">
            <a
              href={Routes.empresa.noticias}
              style={{ color: "rgba(3, 66, 117, 1.0)" }}
              class="bp3-md-menu-frases-poderosas"
            >
              <FormattedMessage id="menu.Noticias" />
            </a>
          </Col>
          <Col xs="12" sm="12" md="5" lg="5" className="afiliados">
            <a
              href={Routes.recursosGratis.afiliados}
              style={{ color: "rgba(3, 66, 117, 1.0)" }}
              class="bp3-md-menu-frases-poderosas"
            >
              <FormattedMessage id="menu.afiliados" />
            </a>
          </Col>
          <Col md="1" lg="1" />
        </Row>
        <Row>
          <Col md="1" lg="1" />
          <Col xs="12" sm="12" md="5" lg="5" className="tvradio">
            <a
              href={Routes.empresa.imprensa}
              style={{ color: "rgba(3, 66, 117, 1.0)" }}
              class="bp3-md-menu-frases-poderosas"
            >
              <FormattedMessage id="menu.tvradioimprensa" />
            </a>
          </Col>
          <Col xs="12" sm="12" md="5" lg="5" className="frasespoderosas">
            <a
              href={Routes.recursosGratis.frases}
              style={{ color: "rgba(3, 66, 117, 1.0)" }}
              class="bp3-md-menu-frases-poderosas"
            >
              <FormattedMessage id="menu.frases" />
            </a>
          </Col>
          <Col md="1" lg="1" />
        </Row>
        <br />
        <br />
        <Row style={{ textAlign: "center" }}>
          <Col xs="12" sm="12" md="12" lg="12">
            <SocialMedia />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currencies: state.currencies,
    user: state.user
  };
};

export default connect(mapStateToProps)(Home);
