import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'

export default class FooterMenuItem extends Component {
    render() {
        return (
            <div className="footerMenuItem" style={this.props.customStyle ? (this.props.customStyle) : {}}>
                <Row>
                    <Col>
                        {this.props.title}
                    </Col>
                </Row>

                {this.props.children && (
                    <Row className="footerMenuItemChildren">
                        <Col>
                            {this.props.children}
                        </Col>
                    </Row>
                )}
            </div>
        )
    }
}
