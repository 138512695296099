import React, { Component } from 'react'
import ProfileIcon from '../Icons/ProfileIcon'
import { connect } from "react-redux";

class ProfileButton extends Component {
    render() {

        const { user } = this.props;

        return (
            user.userID ? (
                <div>
                    <a
                        className="loginIcon"
                        href={
                            user.userType === "CF"
                                ? process.env.REACT_APP_MY_WEBSITE_URL + "encomendas/"
                                : process.env.REACT_APP_MY_WEBSITE_URL + "Dashboard/"
                        }
                        style={{ padding: "15px" }}
                    >
                        <ProfileIcon />
                    </a>
                </div>
            ) : (
                null
            )

        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(ProfileButton);