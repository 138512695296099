import React, { Component } from "react";
// import Footer from "../../components/Footer/Footer";
import { Button, Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getOrderPayment } from "../../actions/miniCartActions";
import HeaderDesktop from "../Desktop/Header/Header";
import PaymentEbanx from "../Payment/PaymentEbanx";
import PaymentMB from "../Payment/PaymentMB";
import PaymentPaypal from "../Payment/PaymentPaypal";
import PaymentTransferencia from "../Payment/PaymentTransferencia";
import DialogChangePaymentMethod from "./DialogChangePaymentMethod";
import "./Payment.css";
import PaymentCheque from "./PaymentCheque";
import PaymentMBWay from "./PaymentMBWay";

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderExist: null,
      orderState: null,
      encomendaID: 0,
      encomendaPreco: 0,
      encomendaTipoPagamento: "",
      encomendaEstadoEncomenda: "",
      encomendaEbanxHash: "",
      encomendaNumeroTelemovel: ""
    };
    this.updatePayment = this.updatePayment;
  }

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = value => {
    this.setState({ selectedValue: value, open: false });
  };

  componentDidMount() {
    getOrderPayment(this.props.match.params.hash).then(data => {
      if (data.data.details) {
        this.setState({
          encomendaID: data.data.details[0].ID_Encomenda,
          encomendaPreco: data.data.details[0].PrecoFinal,
          encomendaTipoPagamento: data.data.details[0].ID_TipoPagamento,
          encomendaMoeda: data.data.details[0].ID_Moeda,
          encomendaNomeMoradaE: data.data.details[0].nomeEnvio,
          encomendaMoradaE: data.data.details[0].mEnvio,
          encomendaCPE: data.data.details[0].codigoPostalEnvio,
          encomendaNomeMoradaF: data.data.details[0].nomeFaturacao,
          encomendaMoradaF: data.data.details[0].mFaturacao,
          encomendaCPF: data.data.details[0].codigoPostalFaturacao,
          encomendaMoradaFNIF: data.data.details[0].nifFaturacao,
          encomendaMetodoEnvio: data.data.details[0].descricaoTipoTransporte,
          encomendaDescontos: data.data.details[0].DescontoTotal,
          encomendaPortes: data.data.details[0].CustoTransporte,
          encomendaTotal: data.data.details[0].PrecoFinal,
          encomendaValorPagar: data.data.details[0].PrecoFinal,
          encomendaEstadoEncomenda: data.data.details[0].ID_EstadoEncomenda,
          encomendaReferenciaMB: data.data.details[0].ReferenciaMB,
          encomendaEntidadeMB: data.data.details[0].EntidadeMB,
          encomendaID_TipoTransporte: data.data.details[0].ID_TipoTransporte,
          encomendaEbanxHash: data.data.details[0].EbanxHash,
          encomendaNumeroTelemovel: data.data.details[0].NumeroTelemovelPagamento,
          encomendaValorConversao: data.data.details[0].ValorConversao
        });
      }
    });
  }

  updatePayment = () => {
    getOrderPayment(this.props.match.params.hash).then(data => {
      if (data.data.details) {
        this.setState({
          encomendaID: data.data.details[0].ID_Encomenda,
          encomendaPreco: data.data.details[0].PrecoFinal,
          encomendaTipoPagamento: data.data.details[0].ID_TipoPagamento,
          encomendaMoeda: data.data.details[0].ID_Moeda,
          encomendaNomeMoradaE: data.data.details[0].nomeEnvio,
          encomendaMoradaE: data.data.details[0].mEnvio,
          encomendaCPE: data.data.details[0].codigoPostalEnvio,
          encomendaNomeMoradaF: data.data.details[0].nomeFaturacao,
          encomendaMoradaF: data.data.details[0].mFaturacao,
          encomendaCPF: data.data.details[0].codigoPostalFaturacao,
          encomendaMoradaFNIF: data.data.details[0].nifFaturacao,
          encomendaMetodoEnvio: data.data.details[0].descricaoTipoTransporte,
          encomendaDescontos: data.data.details[0].DescontoTotal,
          encomendaPortes: data.data.details[0].CustoTransporte,
          encomendaTotal: data.data.details[0].PrecoFinal,
          encomendaValorPagar: data.data.details[0].PrecoFinal,
          encomendaEstadoEncomenda: data.data.details[0].ID_EstadoEncomenda,
          encomendaReferenciaMB: data.data.details[0].ReferenciaMB,
          encomendaEntidadeMB: data.data.details[0].EntidadeMB,
          encomendaID_TipoTransporte: data.data.details[0].ID_TipoTransporte,
          encomendaEbanxHash: data.data.details[0].EbanxHash,
          encomendaNumeroTelemovel: data.data.details[0].NumeroTelemovelPagamento
        });
      }
    });
  };

  render() {

    var discountValue = this.state.encomendaDescontos * this.state.encomendaValorConversao;
    var totalValue = this.state.encomendaTotal * this.state.encomendaValorConversao;
    var valueToPay = this.state.encomendaValorPagar * this.state.encomendaValorConversao;
    var deliveryCosts = this.state.encomendaPortes * this.state.encomendaValorConversao;

    return (
      <div className="App">
        <HeaderDesktop />
        <br />
        <Container style={{
          position: "relative",
          top: "60px",
          width: "90vw"
        }}>
          <div className="paymentFormatting"
            style={{
              marginLeft: isMobile ? "5%" : "20%",
              marginRight: isMobile ? "5%" : "20%"
            }}
          >
            <DialogChangePaymentMethod
              selectedValue={this.state.selectedValue}
              open={this.state.open}
              onClose={this.handleClose}
              encomendaID={this.state.encomendaID}
              hashEncomenda={this.props.match.params.hash}
              paymentType={this.state.encomendaTipoPagamento}
              updatePaymentFunction={this.updatePayment}
            />
            <Row className="payment">
              <Col xs="12" sm="12" md="12" lg="12">
                <Row>
                  <Col xs="12" sm="12" md="12" lg="12">
                    {this.state.encomendaID !== 0 ? (
                      this.state.encomendaEstadoEncomenda === "AP" ||
                        this.state.encomendaEstadoEncomenda === "PC" ? (
                          <div>
                            <Row>
                              <Col xs="12" sm="12" md="12" lg="12">
                                <h4 className="colorPink"><FormattedMessage id="pagamento.resumo.title" /></h4>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12" sm="12" md="12" lg="12">
                                <p>
                                  <FormattedMessage id="pagamento.resumo.p1" />{" "}
                                  <b>{this.state.encomendaID}</b>
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12" sm="12" md="12" lg="12">
                                <p>
                                  <FormattedMessage id="pagamento.resumo.p2" />
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12" sm="12" md="12" lg="12">
                                <p>
                                  <FormattedMessage id="pagamento.resumo.p3" />
                                </p>
                              </Col>
                            </Row>
                            <hr />

                            <Row style={{ display: isMobile ? "none" : "flex" }}>
                              {this.state.encomendaID_TipoTransporte !== "NA" ? (
                                <Col xs="12" sm="12" md="4" lg="4">
                                  <h5 className="colorPink">
                                    <FormattedMessage id="pagamento.resumo.moradaFaturacao.title" />
                                  </h5>
                                  {this.state.encomendaNomeMoradaF} <br />
                                  {this.state.encomendaMoradaF} <br />
                                  {this.state.encomendaCPF}
                                  <br />
                                  {this.state.encomendaMoradaFNIF} <br />
                                </Col>
                              ) : (
                                  <Col xs="12" sm="12" md="4" lg="12">
                                    <h5 className="colorPink">
                                      <FormattedMessage id="pagamento.resumo.moradaFaturacao.title" />
                                    </h5>
                                    {this.state.encomendaNomeMoradaF} <br />
                                    {this.state.encomendaMoradaF} <br />
                                    {this.state.encomendaCPF}
                                    <br />
                                    {this.state.encomendaMoradaFNIF} <br />
                                  </Col>
                                )}
                              {this.state.encomendaID_TipoTransporte !== "NA" ? (
                                <Col xs="12" sm="12" md="4" lg="4">
                                  <h5 className="colorPink">
                                    <FormattedMessage id="pagamento.resumo.moradaEnvio.title" />
                                  </h5>
                                  {this.state.encomendaNomeMoradaE} <br />
                                  {this.state.encomendaMoradaE} <br />
                                  {this.state.encomendaCPE} <br />
                                </Col>
                              ) : null}
                              {this.state.encomendaID_TipoTransporte !== "NA" ? (
                                <Col xs="12" sm="12" md="4" lg="4">
                                  <h5 className="colorPink">
                                    <FormattedMessage id="pagamento.resumo.metodoEnvio.title" />
                                  </h5>
                                  {this.state.encomendaMetodoEnvio} <br />
                                </Col>
                              ) : null}
                            </Row>
                            {this.state.encomendaID_TipoTransporte !== "NA" ? (
                              <Row
                                style={{ display: isMobile ? "block" : "none" }}
                              >
                                <Col xs="12" sm="12" md="12" lg="4">
                                  <h5 className="colorPink">
                                    <FormattedMessage id="pagamento.resumo.moradaFaturacao.title" />
                                  </h5>
                                  {this.state.encomendaNomeMoradaF} <br />
                                  {this.state.encomendaMoradaF} <br />
                                  {this.state.encomendaCPF}
                                  <br />
                                  {this.state.encomendaMoradaFNIF} <br />
                                </Col>
                                <hr
                                  style={{ display: isMobile ? "block" : "none" }}
                                />
                              </Row>
                            ) : (
                                <Row
                                  style={{ display: isMobile ? "block" : "none" }}
                                >
                                  <Col xs="12" sm="12" md="12" lg="12">
                                    <h5 className="colorPink">
                                      <FormattedMessage id="pagamento.resumo.moradaFaturacao.title" />
                                    </h5>
                                    {this.state.encomendaNomeMoradaF} <br />
                                    {this.state.encomendaMoradaF} <br />
                                    {this.state.encomendaCPF}
                                    <br />
                                    {this.state.encomendaMoradaFNIF} <br />
                                  </Col>
                                  <hr
                                    style={{ display: isMobile ? "block" : "none" }}
                                  />
                                </Row>
                              )}
                            {this.state.encomendaID_TipoTransporte !== "NA" ? (
                              <Row
                                style={{ display: isMobile ? "block" : "none" }}
                              >
                                <Col xs="12" sm="12" md="12" lg="4">
                                  <h5 className="colorPink"><FormattedMessage id="pagamento.resumo.moradaEnvio.title" /></h5>
                                  {this.state.encomendaNomeMoradaE} <br />
                                  {this.state.encomendaMoradaE} <br />
                                  {this.state.encomendaCPE} <br />
                                </Col>
                                <hr
                                  style={{ display: isMobile ? "block" : "none" }}
                                />
                              </Row>
                            ) : null}
                            {this.state.encomendaID_TipoTransporte !== "NA" ? (
                              <Row
                                style={{ display: isMobile ? "block" : "none" }}
                              >
                                <Col xs="12" sm="12" md="12" lg="4">
                                  <h5 className="colorPink"><FormattedMessage id="pagamento.resumo.metodoEnvio.title" /></h5>
                                  {this.state.encomendaMetodoEnvio} <br />
                                </Col>
                                <hr />
                              </Row>
                            ) : null}
                            <hr
                              style={{ display: isMobile ? "none" : "block" }}
                            />
                            <Row>
                              <Col xs="12" sm="12" md="12" lg="12">
                                <h5 className="colorPink">Total</h5>
                              </Col>
                              <Col xs="12" sm="12" md="3" lg="3" />
                              <Col xs="6" sm="6" md="3" lg="3" style={{ textAlign: "left" }}>
                                <FormattedMessage id="pagamento.resumo.descontos.title" />
                                <br />
                                <FormattedMessage id="pagamento.resumo.portes.title" />
                                <br />
                                <FormattedMessage id="pagamento.resumo.total.title" />
                                <br />
                                <FormattedMessage id="pagamento.resumo.valorAPagar.title" />
                              </Col>
                              <Col xs="6" sm="6" md="3" lg="3" style={{ textAlign: "right" }} >
                                <FormattedNumber
                                  value={discountValue}
                                  style={"currency"}
                                  currency={this.state.encomendaMoeda}
                                  minimumFractionDigits={2}
                                  maximumFractionDigits={2}
                                />
                                <br />
                                <FormattedNumber
                                  value={deliveryCosts}
                                  style="currency"
                                  currency={this.state.encomendaMoeda}
                                  minimumFractionDigits={2}
                                  maximumFractionDigits={2}
                                />
                                <br />
                                <FormattedNumber
                                  value={totalValue}
                                  style="currency"
                                  currency={this.state.encomendaMoeda}
                                  minimumFractionDigits={2}
                                  maximumFractionDigits={2}
                                />
                                <br />
                                <FormattedNumber
                                  value={valueToPay}
                                  style="currency"
                                  currency={this.state.encomendaMoeda}
                                  minimumFractionDigits={2}
                                  maximumFractionDigits={2}
                                />

                              </Col>
                              <Col xs="12" sm="12" md="3" lg="3" />
                            </Row>
                            <br />
                            <hr />
                            {this.state.encomendaTipoPagamento !== "NA" ? (
                              <h5 className="colorPink"><FormattedMessage id="pagamento.resumo.pagamento.title" /></h5>
                            ) : null}

                            {(() => {
                              switch (this.state.encomendaTipoPagamento) {
                                case "PP":
                                  return (
                                    <div>
                                      <PaymentPaypal
                                        encomenda={this.state.encomendaID}
                                        total={valueToPay}
                                        currency={this.state.encomendaMoeda}
                                      />
                                      <br />
                                    </div>
                                  );
                                case "EX":
                                case "EY":
                                case "EZ":
                                  return (
                                    <div>
                                      <PaymentEbanx
                                        hash={this.state.encomendaEbanxHash}
                                      />
                                      <br />
                                    </div>
                                  );
                                case "TB":
                                  return (
                                    <div>
                                      <PaymentTransferencia
                                        total={valueToPay}
                                        currency={this.state.encomendaMoeda}
                                      />
                                      <br />
                                    </div>
                                  );
                                case "CH":
                                  return (
                                    <div>
                                      <PaymentCheque
                                        total={valueToPay}
                                        currency={this.state.encomendaMoeda}
                                      />
                                      <br />

                                    </div>
                                  );
                                case "MB":
                                  return (
                                    <div>
                                      <PaymentMB
                                        entidade={this.state.encomendaEntidadeMB}
                                        referencia={
                                          this.state.encomendaReferenciaMB
                                        }
                                        total={valueToPay}
                                        currency={this.state.encomendaMoeda}
                                      />
                                      <br />
                                    </div>
                                  );
                                case "MW":
                                  return (
                                    <div>
                                      <PaymentMBWay numtelemovel={this.state.encomendaNumeroTelemovel} idEncomenda={this.state.encomendaID} />
                                      <br />
                                    </div>
                                  )
                                default:
                                  return (
                                    <div>
                                      <label><FormattedMessage id="pagamento.resumo.erro.metodoPagamentoInvalido" /></label>
                                    </div>
                                  );
                              }
                            })()}

                            {/* Método para alterar o tipo de pagamento da Encomenda*/}
                            <Row>
                              <Col xs={12} sm={12} md={12} lg={12}>
                                <span
                                  class="fake-link"
                                  id="fake-link-1"
                                  onClick={() => {
                                    this.handleClickOpen();
                                  }}>
                                  <FormattedMessage id="pagamento.resumo.alterarTipoPagamento" />
                                </span>
                              </Col>
                            </Row>
                            <br />

                            {/* Método para ir para as suas encomendas*/}
                            <Row>
                              <Col xs={12} sm={12} md={12} lg={12}>
                                <Button
                                  aria-label="Increment"
                                  style={{
                                    backgroundColor: "#ca006a",
                                    border: "none",
                                    minWidth: isMobile ? "50%" : "20%"
                                  }}
                                  href={process.env.REACT_APP_MY_WEBSITE_URL + "encomendas/"}>
                                  <FormattedMessage id="pagamento.resumo.irParaEncomendas" />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          <div>
                            <Row>
                              <Col xs="12" sm="12" md="12" lg="12">
                                {" "}
                                <h4 className="colorPink"><FormattedMessage id="pagamento.resumo.title" /></h4>
                                <hr />
                                <p>
                                  {/* A encomenda já foi processada {this.state.encomenda} */}
                                  <b><FormattedMessage id="pagamento.resumo.encomendaJaProcessada" /></b>
                                </p>
                                <Button
                                  aria-label="Increment"
                                  style={{
                                    backgroundColor: "#ca006a",
                                    border: "none",
                                    minWidth: isMobile ? "50%" : "20%"
                                  }}
                                  href={process.env.REACT_APP_MY_WEBSITE_URL + "encomendas/"}>
                                  <FormattedMessage id="pagamento.resumo.irParaEncomendas" />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        )
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart
  };
};

export default withRouter(connect(mapStateToProps)(Payment));
