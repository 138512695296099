import React, { Component } from 'react'
import { connect } from "react-redux";

class CartQuantityIcon extends Component {
    render() {
        const { cartNumberItems } = this.props;
        return (
            <span
                style={{
                    borderRadius: "67%",
                    padding: "3px 8px",
                    top: "-9px",
                    left: "-9px",
                    position: "relative",
                    backgroundColor: "rgb(202, 0, 106)",
                    color: "white"
                }}
            >
                {cartNumberItems && typeof cartNumberItems !== 'undefined' && cartNumberItems != null ? cartNumberItems : 0}
            </span>
        )
    }
}

const mapStateToProps = state => {
    return {
        cartNumberItems: state.cartNumberItems
    };
};

export default connect(mapStateToProps)(CartQuantityIcon);
