import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { areCheckoutCreditsUsed, areCheckoutVouchersUsed, fetchUser, onlyUpdateCredits, onlyUpdateVouchers, verifyOrder } from "../../../actions/miniCartActions";
import { funnelStep } from "../../../Analytics/DataLayer";
import CodigosPromocionais from "../Checkout/CodigosPromocionais";
import Creditos from "../Checkout/Creditos";
import DadosEnvio from "../Checkout/DadosEnvio";
import DadosFaturacao from "../Checkout/DadosFaturacao";
import FormasEnvio from "../Checkout/FormasEnvio";
import Observacao from "../Checkout/Observacao";
import TiposPagamento from "../Checkout/TiposPagamento";
import Total from "../Checkout/Total";
import Vales from "../Checkout/Vales";
import "./Checkout.css";
import { ToastContainer } from "react-toastr";
import { FormattedMessage } from "react-intl";

let container;
class Checkout extends Component {
  constructor(props) {
    super(props);
    funnelStep(6);
  }

  componentDidMount() {
    this.props.dispatch(fetchUser());
  }

  componentDidUpdate(prevProps, prevState) {

    //Se a moeda tiver sido alterada
    if (prevProps.currencies != null && Array.isArray(prevProps.currencies) == false && prevProps.currencies.selected != this.props.currencies.selected) {

      //Ficamos como a nova moeda
      var selectedCurrencyObj = this.props.currencies.list.find(c => c.ID_Moeda == this.props.currencies.selected);

      //Ficamos com a antiga
      var lastSelectedCurrencyObj = this.props.currencies.list.find(c => c.ID_Moeda == prevProps.currencies.selected);

      //Se tivermos encontrado as duas moedas
      if (selectedCurrencyObj != null && lastSelectedCurrencyObj != null) {

        //Ficamos com o valor de cambio das duas
        var currencyExchangeRate = selectedCurrencyObj.ValorConversao;
        var previousCurrencyExchangeRate = lastSelectedCurrencyObj.ValorConversao;

        //Verificamos se créditos e vouchers estão a ser usados
        var areCreditsUsedResult = this.props.dispatch(areCheckoutCreditsUsed());
        var areVouchersUsedResult = this.props.dispatch(areCheckoutVouchersUsed());

        //Se os créditos estiverem a ser usados
        if (areCreditsUsedResult.areCreditsUsed === true) {

          //Convertemos os créditos da moeda antiga para a nova moeda
          var newCredits = areCreditsUsedResult.credits;

          //Para fazer o round é necessário multiplicar por 100 e depois dividir por 100
          newCredits = Math.round((newCredits * currencyExchangeRate / previousCurrencyExchangeRate) * 100) / 100;
          this.props.dispatch(onlyUpdateCredits(newCredits));
        }

        //Se os voucher estiverem a ser usados
        if (areVouchersUsedResult.areVouchersUsed === true) {

          //Convertemos os vouchers da moeda antiga para a nova moeda
          var newVouchers = areVouchersUsedResult.vouchers;

          //Para fazer o round é necessário multiplicar por 100 e depois dividir por 100
          newVouchers = Math.round((newVouchers * currencyExchangeRate / previousCurrencyExchangeRate) * 100) / 100;
          this.props.dispatch(onlyUpdateVouchers(newVouchers));
        }
      }

      this.props.dispatch(verifyOrder()).then(data => {
        var errorMessage = data?.json?.checkoutData?.MensagemErro;
        if (errorMessage !== undefined && errorMessage != null && errorMessage.trim().length > 0) {
          container.clear();
          container.error(`${errorMessage}`);
        }
      });
    }
  }

  render() {
    const { user } = this.props;

    return (
      <div>

        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />

        <Row className="checkoutTitleParent">
          <Col>
            <div className="checkoutTitle">
              <FormattedMessage id="shop.checkout.title" />
            </div>
          </Col>
        </Row>
        <hr />

        <Row style={{ marginBottom: "15px" }}>
          <Col xs={12} sm={12} md={12} lg={6} style={{ marginTop: "15px" }}>
            <DadosEnvio />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} style={{ marginTop: "15px" }}>
            <DadosFaturacao />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={8}>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={6}
                style={{ marginTop: "15px" }}
                className="formasEnvioCol"
              >
                <FormasEnvio />
              </Col>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={6}
                style={{ marginTop: "15px" }}
                className="tiposPagamentoCol"
              >
                <TiposPagamento />
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ marginTop: "15px" }}
                className="codigosPromocionaisCol"
              >
                <CodigosPromocionais user={user} />
              </Col>{" "}
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                style={{ marginTop: "15px" }}
                className="valesCol"
              >
                <Vales user={user} />
              </Col>
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                style={{ marginTop: "15px" }}
                className="creditosCol"
              >
                <Creditos user={user} />
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ marginTop: "15px", minHeight: "100%" }}
                className="observacaoCol"
              >
                <Observacao />
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={4}
            style={{ marginTop: "15px", minHeight: "100%" }}
            className="totalCol"
          >
            <Total user={user} />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart,
    user: state.user,
    loading: state.loading,
    currencies: state.currencies
  };
};

export default connect(mapStateToProps)(Checkout);
