import { Button } from "@material-ui/core";
import { instanceOf } from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { Cookies, withCookies } from "react-cookie";
import { isMobile, isTablet } from "react-device-detect";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { ToastContainer } from "react-toastr";
import { incrementarPackCart, incrementarProdutoCart } from "../../../../src/actions/miniCartActions";
import { addToCart } from "../../../Analytics/DataLayer";
import ProductOutOfStock from "../ProductDetail/ProductOutOfStock";
import "./ProductGrid.css";
import dateFormat from "date-format";
import moment from "moment-timezone";
import { isDate } from "../../../helpers/UsefulFunctions";

let container;

class Product extends Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  componentDidMount() {
  }

  addToCart = id => async e => {
    var product = {
      ID_Produto: this.props.product.ID_Produto,
      Descricao: this.props.product.Descricao,
      ID_TipoProduto: this.props.product.ID_TipoProduto,
      quantity: 1,
      price: this.props.product.precoclientefinalcomiva
    };

    // Criar o datalayer e enviar datalayer
    addToCart(product);

    e.stopPropagation();
    const { cookies } = this.props;
    this.props.dispatch(incrementarProdutoCart(id, cookies.get("userID")));
    container.clear();
    container.success(`Item adicionado com sucesso`);
  };

  addToCartPack = id => async e => {
    var product = {
      ID_Pack: this.props.product.ID_Pack,
      Descricao: this.props.product.Descricao,
      ID_TipoPack: this.props.product.ID_TipoPack,
      quantity: 1,
      price: this.props.product.precoclientefinalcomiva
    };

    // Criar o datalayer e enviar datalayer
    addToCart(product);
    e.stopPropagation();
    const { cookies } = this.props;
    this.props.dispatch(incrementarPackCart(id, cookies.get("userID")));
    container.clear();
    container.success(`Item adicionado com sucesso`);
  };

  render() {
    var { currencies, user, product } = this.props;
    const userRefer = user.userID ? user.userID : "1";
    var isUserLoggedIn = (typeof user.userID !== 'undefined' && user.userID != null);
    var precoAApresentar = 0;
    var precoNormal = null;
    var precoAnterior = null;

    var dataInicio = null;
    var dataFim = null;

    //Se DataInicio for do tipo date
    if (isDate(product.DataInicio)) {
      var initDate = new Date(product.DataInicio).toLocaleDateString();
      var initHour = new Date(product.DataInicio).toLocaleTimeString({}, { hour: '2-digit', minute: '2-digit' });
      dataInicio = initDate + " " + initHour;
    } else {
      //Se não for uma data colocamos o valor que chegou da API
      dataInicio = product.DataInicio;
    }

    //Se a DataFim for do tipo date
    if (isDate(product.DataFim)) {
      var endDate = new Date(product.DataFim).toLocaleDateString();
      var endHour = new Date(product.DataFim).toLocaleTimeString({}, { hour: '2-digit', minute: '2-digit' });
      dataFim = endDate + " " + endHour;
    } else {
      //Se não for uma data colocamos o valor que chegou da API
      dataFim = product.DataFim;
    }

    //Vamos tentar adivinhar a Zona do Utilizador
    var zone = moment.tz.guess();
    var abrev = moment().tz(zone).zoneAbbr();



    //Se for Consultor
    if (this.props.user.userType === 'CS') {

      //E não tiver preço de consultor
      if (this.props.precoconsultorsempromocao === null) {
        precoNormal = this.props.precoclientefinalcomiva;
        precoAApresentar = this.props.precoconsultorcomiva;
      } else {
        precoAApresentar = this.props.precoconsultorcomiva;
        precoAnterior = this.props.precoconsultorsempromocao;
      }

      //Se for cliente final ou etc
    } else {

      //Se tiver preço de cliente sem promoção
      if (this.props.precoclientesempromocao === null) {
        precoAApresentar = this.props.precoclientefinalcomiva;
      } else {
        precoAApresentar = this.props.precoclientefinalcomiva;
        precoAnterior = this.props.precoclientesempromocao
      }
    }

    var linkToProduct = encodeURI("/Loja/DetalheProduto/" + this.props.id + "/" + this.props.tituloURL);
    var linkToPack = encodeURI("/Loja/DetalheProduto/" + this.props.product.ID_Pack + "/" + this.props.tituloURL);
    return (
      <div className={this.props.classDiv}>
        <Row>
          <Col xs="12" sm="12" md="12" lg="12" className="productGridProductContainer">
            <div>
              {" "}
              <ToastContainer
                ref={ref => (container = ref)}
                className="toast-bottom-right"
              />
              <Row style={{ margin: 0, padding: 0 }}>
                {/*Tratar da parte da Imagem*/}
                <Col xs="12" sm="12" md="12" lg="2" style={{ padding: 0 }}>
                  {this.props.product.ID_Pack ? (
                    <NavLink to={linkToPack}>
                      <img alt="Imagem do Pack" src={this.props.src} style={{ maxWidth: "100%" }} />
                    </NavLink>
                  ) : (
                      <NavLink to={linkToProduct}>
                        <img alt="Imagem do Produto" src={this.props.src} style={{ maxWidth: "100%" }} />
                      </NavLink>
                    )}
                </Col>

                {/* Tratar da parte do Texto No Meio*/}
                <Col xs="12" sm="12" md="12" lg="7" className="productCenterSpace">

                  {/* Tratar da parte do titulo */}
                  <Row style={{ marginBottom: "15px" }}>
                    <Col xs="12" sm="12" md="12" lg="12" className="productItemTitle">
                      {this.props.product.ID_Pack ? (
                        <NavLink to={linkToPack}>
                          {this.props.name}
                        </NavLink>
                      ) : (
                          <NavLink to={linkToProduct}>
                            {this.props.name}
                          </NavLink>
                        )}
                    </Col>
                  </Row>

                  {/*Tratar da parte dos icons*/}
                  <Row style={{ marginBottom: "15px" }}>
                    <Col style={{ display: "flex", justifyContent: "space-evenly", paddingLeft: 5 }} md={12} xl={3} lg={4}>
                      {product.ID_Produto ? (
                        <div class="bp3-md-grid-facebook" id="facebookTeste">
                          <FacebookShareButton
                            url={
                              "https://www.ihavethepower.net/Loja/DetalheProduto/" +
                              product.ID_Produto +
                              "/" +
                              product.titleUrl +
                              "?refer=" +
                              userRefer +
                              "&tipo=produto"
                            }
                            quote={this.props.name}
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon size={22} round={true} />
                          </FacebookShareButton>
                        </div>
                      ) : product.ID_Pack ? (
                        <div class="bp3-md-grid-facebook" id="facebookTeste">
                          <FacebookShareButton
                            url={
                              "https://www.ihavethepower.net/Loja/DetalheProduto/" +
                              product.ID_Pack +
                              "/" +
                              product.titleUrl +
                              "?refer=" +
                              userRefer +
                              "&tipo=pack"
                            }
                            quote={this.props.name}
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon size={22} round={true} />
                          </FacebookShareButton>
                        </div>
                      ) : null}
                      {product.ID_Produto ? (
                        <div class="bp3-md-grid-linkedin">
                          <LinkedinShareButton
                            url={
                              "https://www.ihavethepower.net/Loja/DetalheProduto/" +
                              product.ID_Produto +
                              "/" +
                              product.titleUrl +
                              "?refer=" +
                              userRefer +
                              "&tipo=produto"
                            }
                            quote={this.props.name}
                            className="Demo__some-network__share-button"
                          >
                            <LinkedinIcon size={22} round={true} />
                          </LinkedinShareButton>
                        </div>
                      ) : product.ID_Pack ? (
                        <div class="bp3-md-grid-linkedin">
                          <LinkedinShareButton
                            url={
                              "https://www.ihavethepower.net/Loja/DetalheProduto/" +
                              product.ID_Pack +
                              "/" +
                              product.titleUrl +
                              "?refer=" +
                              userRefer +
                              "&tipo=pack"
                            }
                            quote={this.props.name}
                            className="Demo__some-network__share-button"
                          >
                            <LinkedinIcon size={22} round={true} />
                          </LinkedinShareButton>
                        </div>
                      ) : null}
                      {product.ID_Produto ? (
                        <div class="bp3-md-grid-youtube">
                          <TwitterShareButton
                            url={
                              "https://www.ihavethepower.net/Loja/DetalheProduto/" +
                              product.ID_Produto +
                              "/" +
                              product.titleUrl +
                              "?refer=" +
                              userRefer +
                              "&tipo=produto"
                            }
                            quote={this.props.name}
                            className="Demo__some-network__share-button"
                          >
                            <TwitterIcon size={22} round={true} />
                          </TwitterShareButton>
                        </div>
                      ) : product.ID_Pack ? (
                        <div class="bp3-md-grid-youtube">
                          <TwitterShareButton
                            url={
                              "https://www.ihavethepower.net/Loja/DetalheProduto/" +
                              product.ID_Pack +
                              "/" +
                              product.titleUrl +
                              "?refer=" +
                              userRefer +
                              "&tipo=pack"
                            }
                            quote={this.props.name}
                            className="Demo__some-network__share-button"
                          >
                            <TwitterIcon size={22} round={true} />
                          </TwitterShareButton>
                        </div>
                      ) : null}
                      {product.ID_Produto ? (
                        <div class="bp3-md-grid-instagram">
                          <WhatsappShareButton
                            url={
                              "https://www.ihavethepower.net/Loja/DetalheProduto/" +
                              product.ID_Produto +
                              "/" +
                              product.titleUrl +
                              "?refer=" +
                              userRefer +
                              "&tipo=produto"
                            }
                            quote={this.props.name}
                            className="Demo__some-network__share-button"
                          >
                            <WhatsappIcon size={22} round={true} />
                          </WhatsappShareButton>
                        </div>
                      ) : product.ID_Pack ? (
                        <div class="bp3-md-grid-instagram">
                          <WhatsappShareButton
                            url={
                              "https://www.ihavethepower.net/Loja/DetalheProduto/" +
                              product.ID_Pack +
                              "/" +
                              product.titleUrl +
                              "?refer=" +
                              userRefer +
                              "&tipo=pack"
                            }
                            quote={this.props.name}
                            className="Demo__some-network__share-button"
                          >
                            <WhatsappIcon size={22} round={true} />
                          </WhatsappShareButton>
                        </div>
                      ) : null}
                    </Col>
                  </Row>

                  {/* Tratar da parte das datas */}
                  {typeof product.DataInicio !== 'undefined' && product.DataInicio != null && typeof product.DataFim !== 'undefined' && product.DataFim != null && (
                    <Row>
                      <Col>
                        <div className="productItemDates">
                          <div className="productItemStartDate">
                            {product.DataInicio ? `Início: ${dataInicio}` : null}
                          </div>
                          <div className="productItemDatesSeparator">|</div>
                          <div className="productItemEndDate">
                            {product.DataFim ? `Fim: ${dataFim} (${abrev})` : null}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}

                </Col>

                {/* Tratar da parte do botão de comprar*/}
                <Col xs="12" sm="12" md="12" lg="3" className="productItemRightSpace">
                  <Row>
                    {/* Campo do Valor da Encomenda */}
                    <Col xs="12" sm="12" md="12" lg="12">
                      <div>
                        {precoNormal !== null ? (
                          <span className="discounted-price"
                            style={{ marginRight: "10px" }}>
                            {"("}
                            <FormattedNumber value={precoNormal} style="currency" currency={currencies.selected}
                              minimumFractionDigits={2} maximumFractionDigits={2}
                            />
                            {")"}
                          </span>)
                          : null}

                        {precoAnterior !== null ? (
                          <span className="discounted-price"
                            style={{ marginRight: "10px", textDecoration: "line-through" }}>
                            <FormattedNumber value={precoAnterior} style="currency" currency={currencies.selected}
                              minimumFractionDigits={2} maximumFractionDigits={2}
                            />
                          </span>)
                          : null}

                        <span className={"productItemCurrentPrice " + (precoAnterior != null || precoNormal != null ? 'promotionPrice' : '')}>
                          <FormattedNumber value={precoAApresentar} style="currency" currency={currencies.selected}
                            minimumFractionDigits={2} maximumFractionDigits={2}
                          />
                        </span>
                      </div>
                    </Col>

                    {/* Campo para Adicionar ao carrinho */}
                    <Col xs="12" sm="12" md="12" lg="12" className="productGridAddToCartButton">
                      {this.props.product.Esgotado === true ? (

                        <div style={{ minWidth: "200px", height: "100%", maxWidth: "200px", fontFamily: "Montserrat" }}>
                          <ProductOutOfStock defaultEmail={this.props.defaultEmail}
                            notification={this.props.notification} notificationHash={this.props.notificationHash}
                            productId={this.props.product.ID_Produto ? this.props.product.ID_Produto : null}
                            packId={this.props.product.ID_Pack ? this.props.product.ID_Pack : null}
                            afterNotificationCreate={this.props.afterNotificationCreate}
                            afterNotificationRemoved={this.props.afterNotificationRemove}
                            isUserLoggedIn={isUserLoggedIn}
                          />
                        </div>

                      ) : this.props.product.ID_Pack ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          style={{ minWidth: "200px", height: "100%", maxWidth: "200px", fontFamily: "Montserrat" }}
                          onClick={this.addToCartPack(this.props.product.ID_Pack)}>
                          {this.props.inscrever ? ("Inscrever") : (<FormattedMessage id="productgrid.butaoComprar" />)}
                        </Button>
                      ) : (
                            <Button
                              variant="contained"
                              color="secondary"
                              style={{ minWidth: "200px", height: "100%", maxWidth: "200px", fontFamily: "Montserrat" }}
                              onClick={this.addToCart(this.props.id)}>
                              {this.props.inscrever ? ("Inscrever") : (<FormattedMessage id="productgrid.butaoComprar" />)}
                            </Button>
                          )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <hr width="90%" />
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart,
    currencies: state.currencies,
    user: state.user
  };
};

export default withCookies(connect(mapStateToProps)(Product));